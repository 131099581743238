import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import DashboardLayout from "../DashboardLayout";
import ShowForexHistoryData from "./ShowForexHistoryData";
import io from "socket.io-client";
import {
  getAllUserOrderHistory,
  getAllUserTradeHistory,
  getUserAccountByEmail,
} from "../../utils/data";

// const LIVE_URL = "https://forex.websocket.sypto.xyz";
// const DEV_URL = "http://localhost:3001";

function ForexTradeHistoryPage() {
  const user = localStorage.getItem("userDetails");
  const userData = user && JSON.parse(user);
  const userDeatils = userData && userData.response;
  const email = userDeatils && userDeatils[0]["email"];

  const [userId, setUserId] = useState("");
  const [accountId, setAccountId] = useState("");
  const [userTradeHistory, setUserTradeHistory] = useState([]);
  const [userOrdersHistory, setUserOrderHistory] = useState([]);
  const [filteredUserTradeHistory, setFilteredUserTradeHistory] =
    useState(userTradeHistory);
  const [filteredUserOrdersHistory, setFilteredUserOrderHistory] =
    useState(userOrdersHistory);
  const [sortOrder, setSortOrder] = useState("asc");
  const [isHistoryDataLoading,setIsHistoryDataLoading] = useState(false)

  useEffect(() => {
    setIsHistoryDataLoading(true)
    const fetchHistoryData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";

      setUserId(id);

      const userTradeHistoryData = await getAllUserTradeHistory(id);
      setUserTradeHistory(userTradeHistoryData?.data);
      setFilteredUserTradeHistory(userTradeHistoryData?.data);

      const userOrdersHistoryData = await getAllUserOrderHistory(id);
      setUserOrderHistory(userOrdersHistoryData?.data);
      setFilteredUserOrderHistory(userOrdersHistoryData?.data);
      setIsHistoryDataLoading(false);
    };
    fetchHistoryData();
    // fecthHistoryDataFromWebSocket();
  }, [userId]);

  // const fecthHistoryDataFromWebSocket = () => {
  //   const socket = io(`${LIVE_URL}`);

  //   // Event listener for when the connection is established
  //   socket.on("connect", () => {
  //     console.log("Connected to WebSocket server");

  //     // Emit an event with user information (name and age)
  //     socket.emit("sendUserInfo", { userId });
  //   });

  //   // Event listener for Live Positions data is received from the server
  //   socket.on("fetchOrdersHistory", (ordersHistory) => {
  //     console.log("New ordersHistory update:", ordersHistory);
  //   });

  //   // Event listener for Open Orders data is received from the server
  //   socket.on("fetchTradesHistory", (tradesHistory) => {
  //     console.log("New tradesHistory update:", tradesHistory);
  //   });
  // };

  const handleFilterByDays = (currentTab, days, startDate, endDate) => {
    if (currentTab === "trades-history") {
      if (days === 0) {
        setFilteredUserTradeHistory(userTradeHistory);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredData = userTradeHistory.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        console.log("filtered data -1", filteredData);

        setFilteredUserTradeHistory(filteredData.reverse());

        return;
      } else {
        let filteredTradeData = userTradeHistory;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredTradeData = filteredTradeData.filter(
          (trade) => new Date(trade?.time) >= fromDate
        );

        setFilteredUserTradeHistory(filteredTradeData.reverse());

        console.log("Time Trade History");
      }
    } else if (currentTab === "orders-history") {
      if (days === 0) {
        setFilteredUserOrderHistory(userOrdersHistory);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredOrdersHistoryData = userOrdersHistory.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        setFilteredUserOrderHistory(filteredOrdersHistoryData.reverse());

        return;
      } else {
        let filteredOrderData = userOrdersHistory;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredOrderData = filteredOrderData.filter(
          (trade) => new Date(trade?.time) >= fromDate
        );

        setFilteredUserOrderHistory(filteredOrderData.reverse());
        console.log("Time Orders History");
      }
    }

    // closeFilterDroprDownModal();
  };

  const handleResetFilterData = (currentTab) => {
    if (currentTab === "trades-history") {
      setFilteredUserTradeHistory(userTradeHistory);
      return;
    } else if (currentTab === "orders-history") {
      setFilteredUserOrderHistory(userOrdersHistory);
      return;
    }
    // closeFilterDroprDownModal();
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col relative  w-full max-h-screen">
        <div className="flex flex-col flex-1 ">
          <ShowForexHistoryData
            userId={userId}
            userTradeHistory={userTradeHistory}
            userOrdersHistory={userOrdersHistory}
            filteredUserTradeHistory={filteredUserTradeHistory}
            filteredUserOrdersHistory={filteredUserOrdersHistory}
            handleFilterByDays={handleFilterByDays}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setFilteredUserTradeHistory={setFilteredUserTradeHistory}
            setFilteredUserOrderHistory={setFilteredUserOrderHistory}
            handleResetFilterData={handleResetFilterData}
            isHistoryDataLoading={isHistoryDataLoading}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(ForexTradeHistoryPage));
