import React, { useEffect, useState } from "react";

//React Icons
import { RiMenu3Fill, RiCloseFill } from "react-icons/ri";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import Logo from "../../assests/ForexLogo.svg";

import { auth } from "../../Login/firebase.js";
import DropDown from "./dropdown.js";
import MobileNav from "./MobileNav";
import {
  getAllUserSubAccountBySubscriberId,
  getAllUsersTradeStrategy,
  getUserAccountByEmail,
} from "../../utils/data.js";

const Header = ({ userData, ...props }) => {
  const [user] = useAuthState(auth);
  const email = user && user.email;

  const [navOpen, setNavOpen] = React.useState(false);
  const [userSubscribedTradeStrategy, setUserSubscribedTradeStrategy] =
    useState({});

    const path = window.location.pathname;

  useEffect(() => {
    const fetchUserData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const subscriberUserId = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";

      const userSubAccountData = await getAllUserSubAccountBySubscriberId(subscriberUserId);

      const subscriberAccId = userSubAccountData?.data[0]
        ? userSubAccountData?.data[0]?.account_id
        : "";

      const tradeStrategyData = await getAllUsersTradeStrategy();
      const filterUserSubscribedStrategy = tradeStrategyData?.data?.find(
        (obj) => obj.subscriber_list?.includes(subscriberAccId)
      );
      setUserSubscribedTradeStrategy(filterUserSubscribedStrategy);
    };

    fetchUserData();
  }, []);

  return (
    <header className="">
      <nav
        className={`w-full flex flex-row items-center justify-between fixed top-0 shadow-sm bg-black backdrop-blur-md z-50 font-poppins h-[80px] border-[1px] border-[#ffffff]/10`}
      >
        <div>
          <span className="flex flex-row items-center justify-center py-3 ml-4 md:ml-16">
            <img src={Logo} alt="Logo" width={50} />
            <span className="ml-2 text-base text-[#ffffff]/80 font-semibold   px-3 py-0.5">
              FOREX INVESTMENTS
            </span>
          </span>
        </div>
        {user ? (
          <div className="lg:flex hidden flex-row items-center lg:space-x-4 font-medium text-sm text-gray-900 mr-3">
            

            <Link to="/strategy">
              <span className={`${path === '/strategy' ? 'bg-white/10' : ''}  my-2 px-3 py-2  text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer`}>
                View All Strategies
              </span>
            </Link>
            <div >
            <Link to="/user" className="flex items-center">
              <span className={`${path === '/user' ? 'bg-white/10' : ''} ml-3 my-2 px-3 py-2  text-neutral-300 rounded-lg hover:bg-white/10 hover:text-neutral-50 hover:scale-110 active:scale-95 ease-linear duration-150 relative hidden lg:flex cursor-pointer`}>
                My Portfolio
              </span>
            
            {userSubscribedTradeStrategy?.strategy_name?.length > 0 && (
              <button className="relative flex items-center justify-center text-sm text-white w-auto   px-4 py-2 rounded-md">
                ({userSubscribedTradeStrategy?.strategy_name})
                <span class="absolute right-1.5 top-2 flex h-2 w-2">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
                  <span class="relative inline-flex rounded-full h-2 w-2 bg-rose-500"></span>
                </span>
              </button>
            )}
            </Link>
            </div>
            <DropDown user={user} />
          </div>
        ) : null}

        <div
          onClick={() => setNavOpen(!navOpen)}
          className="text-white cursor-pointer mr-4
          flex lg:hidden"
        >
          {navOpen ? (
            <RiCloseFill size={25} color="#ffffff" />
          ) : (
            <RiMenu3Fill size={25} color="#ffffff" />
          )}
        </div>
      </nav>
      {navOpen && (
        <MobileNav
          data={props?.data}
          uid={props?.uid}
          user={user}
          userData={userData}
          setNavOpen={setNavOpen}
          setShow={props.setShow}
          userSubscribedTradeStrategy={userSubscribedTradeStrategy}
        />
      )}
    </header>
  );
};

export default Header;
