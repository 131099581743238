import React, { useEffect, useState } from "react";
import DashboardLayout from "../DashboardLayout";

import StrategyList from "./StrategyList";

import {
  getAllUserSubAccountByUserId,
  getTradeStrategyByUserId,
  getUserAccountByEmail,
  
} from "../../utils/data";

const AddStrategy = () => {
  const user = localStorage.getItem("userDetails");
  const userData = user && JSON.parse(user);
  const userDeatils = userData && userData.response;
  const email = userDeatils && userDeatils[0]["email"];

  const [userId, setUserId] = useState("");
  const [providerAccountId, setProviderAccountId] = useState("");
  const [tradeStrategyDataList, setTradeStrategyDataList] = useState([]);
  const [isStrategyDataChanged,setIsStrategyDataChanged] = useState(false);
  const [providerAccountList, setProviderAccountList] = useState("");
  const [isStrategyDataLoading,setIsStrategyDataLoading] =useState(false)

  useEffect(() => {
    setIsStrategyDataLoading(true);
    const fetchUserData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";
      setUserId(id);

      const subAccounts = await getAllUserSubAccountByUserId(id);

      const providerAccountListData = subAccounts.data?.filter(
        (acc) => acc.copy_factory_roles[0] === "PROVIDER"
      );

      setProviderAccountList(providerAccountListData)

      const providerAccId = providerAccountListData[0]?.account_id;
      setProviderAccountId(providerAccId);

      const tradeStrategyData = await getTradeStrategyByUserId(id);
      // console.log("Trade Strategy Data --->",tradeStrategyData?.data)
      setTradeStrategyDataList(tradeStrategyData?.data);
      setIsStrategyDataLoading(false);
    };

    fetchUserData();
  }, [isStrategyDataChanged]);

  return (
    <DashboardLayout>
      <div className="flex flex-col relative   w-full bg-black">
        <StrategyList
          userId={userId}
          providerAccountId={providerAccountId}
          providerAccountList={providerAccountList}
          tradeStrategyDataList={tradeStrategyDataList}
          isStrategyDataChanged={isStrategyDataChanged}
          setIsStrategyDataChanged={setIsStrategyDataChanged}
          isStrategyDataLoading={isStrategyDataLoading}
        />
      </div>
    </DashboardLayout>
  );
};

export default AddStrategy;
