export const livePositionsData = [
    {
      id: "452448522",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-15T10:19:39.212Z",
      broker_ime: "2023-11-15 10:19:39.212",
      update_time: "2023-11-15T10:19:39.212Z",
      open_price: 105.338,
      volume: 0.07,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -10.45,
      profit: -10.45,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448528",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-15T10:19:39.212Z",
      broker_ime: "2023-11-15 10:19:39.212",
      update_time: "2023-11-15T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.05,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: 30.57,
      profit: 30.57,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448530",
      platform: "mt5",
      position_type: "POSITION_TYPE_SELL",
      account_name: "Test-1",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-15T10:19:39.212Z",
      broker_ime: "2023-11-15 10:19:39.212",
      update_time: "2023-11-15T10:19:39.212Z",
      open_price: 108.338,
      volume: 0.03,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.133,
      profit: -0.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448531",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-1",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-14T10:19:39.212Z",
      broker_ime: "2023-11-14 10:19:39.212",
      update_time: "2023-11-14T10:19:39.212Z",
      open_price: 115.338,
      volume: 0.08,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.133,
      profit: -0.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448532",
      platform: "mt5",
      position_type: "POSITION_TYPE_SELL",
      account_name: "Test-6",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-12T10:19:39.212Z",
      broker_ime: "2023-11-12 10:19:39.212",
      update_time: "2023-11-12T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.01,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -9.133,
      profit: -9.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448533",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "AUDJPYm",
      magic: 0,
      time: "2023-11-12T10:19:39.212Z",
      broker_ime: "2023-11-12 10:19:39.212",
      update_time: "2023-11-12T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.01,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: 39.89,
      profit: 39.89,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448533",
      platform: "mt5",
      position_type: "POSITION_TYPE_SELL",
      account_name: "Test-1",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-08T10:19:39.212Z",
      broker_ime: "2023-11-08 10:19:39.212",
      update_time: "2023-11-08T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.09,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_SL",
      current_price: 112.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.133,
      profit: -0.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448534",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-10T10:19:39.212Z",
      broker_ime: "2023-11-10 10:19:39.212",
      update_time: "2023-11-10T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.01,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.133,
      profit: -0.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448536",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "AUDJPYm",
      magic: 0,
      time: "2023-11-10T10:19:39.212Z",
      broker_ime: "2023-11-10 10:19:39.212",
      update_time: "2023-11-10T10:19:39.212Z",
      open_price: 109.338,
      volume: 1,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_SL",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -5.63,
      profit: -5.63,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448537",
      platform: "mt5",
      position_type: "POSITION_TYPE_SELL",
      account_name: "Test-1",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-08T10:19:39.212Z",
      broker_ime: "2023-11-08 10:19:39.212",
      update_time: "2023-11-08T10:19:39.212Z",
      open_price: 109.338,
      volume: 2,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.193,
      profit: -0.193,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448538",
      platform: "mt5",
      position_type: "POSITION_TYPE_SELL",
      account_name: "Test-6",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-08T10:19:39.212Z",
      broker_ime: "2023-11-08 10:19:39.212",
      update_time: "2023-11-08T10:19:39.212Z",
      open_price: 109.338,
      volume: 1.5,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_UNKNOWN",
      current_price: 106.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.133,
      profit: -0.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448539",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "EURUSDm",
      magic: 0,
      time: "2023-11-08T10:19:39.212Z",
      broker_ime: "2023-11-08 10:19:39.212",
      update_time: "2023-11-08T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.01,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -0.133,
      profit: -0.13,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "452448540",
      platform: "mt5",
      position_type: "POSITION_TYPE_BUY",
      account_name: "Test-6",
      symbol: "EURUSDm",
      magic: 0,
      time: "2023-11-08T10:19:39.212Z",
      broker_ime: "2023-11-08 10:19:39.212",
      update_time: "2023-11-08T10:19:39.212Z",
      open_price: 107.338,
      volume: 0.06,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: -20.34,
      profit: -20.34,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    {
      id: "45244855",
      platform: "mt5",
      position_type: "POSITION_TYPE_SELL",
      account_name: "Test-1",
      symbol: "CADJPYm",
      magic: 0,
      time: "2023-11-08T10:19:39.212Z",
      broker_ime: "2023-11-08 10:19:39.212",
      update_time: "2023-11-08T10:19:39.212Z",
      open_price: 109.338,
      volume: 0.01,
      swap: 0,
      commission: 0,
      realized_swap: 0,
      realized_commission: 0,
      unrealized_swap: 0,
      unrealized_commission: 0,
      reason: "POSITION_REASON_EXPERT",
      current_price: 109.318,
      current_tick_value: 0.663354317441575,
      realized_profit: 0,
      unrealized_profit: 50.45,
      profit: 50.45,
      account_currency_exchange_rate: 1,
      update_sequence_number: 1699438779212011,
    },
    
  ];