import React, { useState, useEffect, useRef } from "react";
import FormatDateTime from "../../utils/formatDateTime";
import {
  ChevronsUpDownIcon,
  MoveRightIcon,
  MoveLeftIcon,
  FilterIcon,
  XIcon,
  RefreshCcwIcon,
} from "lucide-react";
import { handleSortTradeHistoryData } from "../../utils/sortFunctions";
import FilterTradeHistoryModal from "./FilterTradeHistoryModal";
import LoadingSpinner from "../../components/loading-spinner"

export default function ForexTradeHistoryDataTable({
  userOriginalTradeHistory,
  filteredUserTradeHistory,
  currentTab,
  sortOrder,
  setSortOrder,
  setFilteredUserTradeHistory,
  handleResetFilterData,
  isHistoryDataLoading
}) {
  // console.log("From Inside Trade History Table",filteredUserTradeHistory)

  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  let itemsPerPage = 20;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredUserTradeHistory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredUserTradeHistory.length / itemsPerPage);

  const displayPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 5; // You can adjust this value based on your preference

    let startPage;
    let endPage;

    if (totalPages <= maxPageButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxPageButtons / 2)) {
        startPage = 1;
        endPage = maxPageButtons;
      } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
        startPage = totalPages - maxPageButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxPageButtons / 2);
        endPage = currentPage + Math.floor(maxPageButtons / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`flex items-center justify-center text-xs  font-semibold rounded-full h-5 w-5 ${
            currentPage === i
              ? "bg-[#07F2C7] text-black"
              : "bg-white/10 text-white/40"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const handleChooseFilters = () => {};

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
    handleResetFilterData(currentTab);
  };

  const handleRefreshData = () => {
    console.log("Handle Refresh data");
    handleResetFilterData(currentTab);
  };
  return (
    <>
      {!filteredUserTradeHistory ? (
        <div className="z-0 w-full h-full flex flex-col items-center justify-center text-white">
          No Trade History Found!!
        </div>
      ) : (
        <>
          <div className="z-0 w-full h-[calc(100vh-240px)]  md:h-[calc(100vh-320px)]  lg:h-[calc(100vh-140px)] custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-500 ease-in">
            <table className="bg-black   text-sm w-full relative z-0 ">
              <thead className="bg-[#0F0F0F] text-sm text-left sticky top-0 box-border  ">
                <tr className=" text-white  ">
                  <th className="min-w-[180px] lg:min-w-full px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer "
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "account_name"
                        )
                      }
                    >
                      Account Name
                      <ChevronsUpDownIcon className=" h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  <th className="min-w-[210px] lg:min-w-full px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "strategy_name"
                        )
                      }
                    >
                      Subscribed Strategy
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  <th className="px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "symbol"
                        )
                      }
                    >
                      Symbol
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  <th className="min-w-[140px] lg:min-w-full px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "type"
                        )
                      }
                    >
                      Order Type
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  {/* <th className="min-w-[140px] lg:min-w-full px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "entry_type"
                        )
                      }
                    >
                      Entry Type
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th> */}
                  <th className="px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "volume"
                        )
                      }
                    >
                      Volume
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  <th className="px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "price"
                        )
                      }
                    >
                      Price
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  <th className="px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "profit"
                        )
                      }
                    >
                      PNL
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>
                  {/* <th className="px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer "
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "deal_id"
                        )
                      }
                    >
                      Deal Id
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th> */}
                  <th className="min-w-[140px] lg:min-w-full px-4 py-4">
                    <span
                      className="flex items-center cursor-pointer "
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "order_id"
                        )
                      }
                    >
                      Order Id
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                  </th>

                  <th className=" min-w-[190px] lg:min-w-full lg:flex lg:justify-between px-4 py-4">
                    <span
                      className="flex items-center  cursor-pointer"
                      onClick={() =>
                        handleSortTradeHistoryData(
                          filteredUserTradeHistory,
                          setFilteredUserTradeHistory,
                          setSortOrder,
                          currentTab,
                          sortOrder,
                          "time"
                        )
                      }
                    >
                      Time
                      <ChevronsUpDownIcon className="h-4 w-4 text-white ml-2" />
                    </span>
                    <div className="hidden lg:flex space-x-2">
                      <span
                        onClick={handleRefreshData}
                        className={`flex items-center cursor-pointer bg-white/10 active:bg-[#07F2C7]/20  rounded-md  h-8 w-8`}
                      >
                        <RefreshCcwIcon className="h-4 w-4 text-[#07F2C7] ml-2" />
                      </span>
                      <span
                        onClick={handleShowFilter}
                        className={`${showFilter ? " bg-[#07F2C7]/20":"bg-white/10 "} flex items-center cursor-pointer   rounded-md  h-8 w-8`}
                      >
                        <FilterIcon className="h-4 w-4 text-[#07F2C7] ml-2" />
                      </span>
                    </div>
                  </th>
                </tr>
                {showFilter && (
                  <FilterTradeHistoryModal
                    showFilter={showFilter}
                    handleCloseFilter={handleCloseFilter}
                    currentTab={currentTab}
                    filteredUserTradeHistory={filteredUserTradeHistory}
                    userOriginalTradeHistory={userOriginalTradeHistory}
                    setFilteredUserTradeHistory={setFilteredUserTradeHistory}
                  />
                )}
              </thead>

              <tbody>
              {currentItems.length === 0 && isHistoryDataLoading === false && (
                  <tr className="absolute  mx-auto w-[250px] ml-16 md:ml-28 lg:ml-0 md:w-[600px] lg:w-full  h-[200px] lg:h-[400px]   flex flex-col items-center justify-center text-white text-lg ">
                   <td>
                   No Trade History Found!!
                   </td>
                  </tr>
                )}
                {currentItems.length === 0 && isHistoryDataLoading === true && (
                  <tr className="absolute  mx-auto w-[250px] ml-16 md:ml-28 lg:ml-0 md:w-[600px] lg:w-full  h-[200px] lg:h-[400px]   flex flex-col items-center justify-center text-white text-lg ">
                    <td><LoadingSpinner /></td>
                  </tr>
                )}
                {filteredUserTradeHistory &&
                  currentItems?.map((data, index) => (
                    <tr
                      key={index}
                      className="bg-black z-0 border-b   border-gray-900 font-medium text-white hover:bg-black/90"
                    >
                      <td className="px-4 py-4 border-r border-gray-900 ">
                        {data.account_name}
                      </td>
                      <td className="px-4 py-4 border-r border-gray-900">
                        {data.strategy_name}
                      </td>
                      <td className="px-4 py-4 border-r border-gray-900">
                        {data.symbol}
                      </td>
                      <td
                        className={`px-4 py-4 border-r border-gray-900 font-medium  ${
                          data.type.includes("BUY")
                            ? "text-[#07F2C7]"
                            : data.type.includes("SELL")
                            ? "text-rose-500"
                            : "text-blue-500"
                        }`}
                      >
                        {data.type.replace("DEAL_TYPE_", "")}
                      </td>
                      {/* <td className="px-4 py-4 border-r border-gray-900">
                        {data.entry_type.replace("DEAL_ENTRY_", "")}
                      </td> */}
                      <td className="px-4 py-4 border-r border-gray-900">
                        {data.volume}
                      </td>
                      <td className="px-4 py-4 border-r border-gray-900">
                        {data.price}
                      </td>

                      <td
                        className={`px-4 py-4 border-r border-gray-900 font-medium ${
                          data.profit > 0
                            ? "text-[#07F2C7]"
                            : data.profit < 0
                            ? "text-rose-500"
                            : "text-white"
                        }`}
                      >
                        {data.profit}
                      </td>

                      {/* <td className="px-4 py-4 border-r border-gray-900">
                        {data.deal_id}
                      </td> */}
                      <td className="px-4 py-4 border-r border-gray-900">
                        {data.order_id}
                      </td>
                      <td className="px-4 py-4 border-r border-gray-900">
                        {FormatDateTime(data.time)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {filteredUserTradeHistory.length > 20 && (
            <div className="flex mb-4 lg:mb-0  mt-4 items-center justify-center lg:justify-end  px-10 ">
            <div className="flex items-center  space-x-3 md:space-x-6 justify-center lg:justify-end w-full">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className=" group disabled:text-white/30  disabled:cursor-not-allowed tracking-widest flex items-center justify-center px-2 py-1  text-sm font-medium text-white rounded-sm "
              >
                <MoveLeftIcon className="h-4 w-4 text-white group-disabled:text-white/30  mr-2" />
                Prev
              </button>

              <div className="flex  space-x-3 md:space-x-4 items-center justify-center ">
                {displayPageNumbers()}
              </div>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="group disabled:text-white/30 disabled:cursor-not-allowed tracking-widest flex items-center justify-center px-2 py-1  text-sm font-medium text-white rounded-sm "
              >
                Next
                <MoveRightIcon className="h-4 w-4 text-white group-disabled:text-white/30  ml-2" />
              </button>
            </div>
          </div>
          )}
        </>
      )}
    </>
  );
}
