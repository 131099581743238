import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import DashboardLayout from "../DashboardLayout";
import ShowForexOrdersData from "./ShowForexOrdersData";
import { livePositionsData } from "../../mockData/livePositionsData";
import { openOrdersData } from "../../mockData/openOrdersData";
import io from "socket.io-client";
import {
  getAllUserLivePositions,
  getAllUserOpenOrders,
  getUserAccountByEmail,
} from "../../utils/data";

// const LIVE_URL = "https://forex.websocket.sypto.xyz";
// const DEV_URL = "http://localhost:3001";

function DashboardLive({
  userId,
  userLivePositionData,
  userOpenOrdersData,
  filteredUserLivePositionData,
  setFilteredUserLivePositionData,
  filteredUserOpenOrdersData,
  setFilteredUserOpenOrdersData,
  isPositionsDataLoading,
}) {
  // const user = localStorage.getItem("userDetails");
  // const userData = user && JSON.parse(user);
  // const userDeatils = userData && userData.response;
  // const email = userDeatils && userDeatils[0]["email"];

  // const [userId, setUserId] = useState("");
  // const [userLivePositionData, setUserLivePositionsData] = useState([]);
  // const [userOpenOrdersData, setUserOpenOrdersData] = useState([]);
  // const [filteredUserLivePositionData, setFilteredUserLivePositionData] =
  //   useState(userLivePositionData);
  // const [filteredUserOpenOrdersData, setFilteredUserOpenOrdersData] =
  //   useState(userOpenOrdersData);
  const [sortOrder, setSortOrder] = useState("asc");
  // const [isPositionsDataLoading,setIsPositionsDataLoading] = useState(false)

  // useEffect(() => {
  //   setIsPositionsDataLoading(true);
  //   const fetchLiveData = async () => {

  //     const userAccountData = await getUserAccountByEmail(email);

  //     const id = userAccountData?.data[0]?.id
  //       ? userAccountData?.data[0]?.id
  //       : "";

  //     setUserId(id);
  //     // setUserOpenOrdersData(openOrdersData);
  //     // setFilteredUserOpenOrdersData(openOrdersData);
  //     // setUserLivePositionsData(livePositionsData);
  //     // setFilteredUserLivePositionData(livePositionsData);

  //     // const userLivePositions = await getAllUserLivePositions(id);
  //     // console.log("user Live Positions",userLivePositions?.data)

  //     // const userOpenOrders = await getAllUserOpenOrders(id);
  //     // console.log("user Open Orders",userOpenOrders?.data);

  //   };

  //   fetchLiveData();

  //   // fecthLiveDataFromWebSocket();

  // }, []);

  // const fecthLiveDataFromWebSocket = () => {

  //   const socket = io(`${LIVE_URL}`);

  //   // Event listener for when the connection is established
  //   socket.on("connect", () => {
  //     console.log("Connected to WebSocket server");

  //     // Emit an event with user information (name and age)
  //     socket.emit("sendUserInfo", { userId });
  //   });

  //   // Event listener for Live Positions data is received from the server
  //   socket.on("fetchLivePositions", (positionsData) => {
  //     console.log("Received positionsData update:", positionsData);
  //     setUserLivePositionsData(positionsData?.reverse());
  //     setFilteredUserLivePositionData(positionsData?.reverse());
  //     setIsPositionsDataLoading(false);
  //   });

  //   // Event listener for Open Orders data is received from the server
  //   socket.on("fetchOpenOrders", (openOrdersData) => {
  //     console.log("Received Open Orders update:", openOrdersData);
  //     setUserOpenOrdersData(openOrdersData?.reverse());
  //     setFilteredUserOpenOrdersData(openOrdersData?.reverse());
  //   });

  //   // Event listener for Live Positions data is received from the server
  //   socket.on("fetchOrdersHistory", (ordersHistory) => {
  //     console.log("New ordersHistory update:", ordersHistory);
  //   });

  //   // Event listener for Open Orders data is received from the server
  //   socket.on("fetchTradesHistory", (tradesHistory) => {
  //     console.log("New tradesHistory update:", tradesHistory);
  //   });

  // };

  const handleFilterByDays = (currentTab, days, startDate, endDate) => {
    if (currentTab === "positions") {
      if (days === 0) {
        setFilteredUserLivePositionData(userLivePositionData);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredData = userLivePositionData.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        console.log("filtered data -1", filteredData);

        setFilteredUserLivePositionData(filteredData);

        return;
      } else {
        let filteredPositionsData = userLivePositionData;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredPositionsData = filteredPositionsData.filter(
          (trade) => new Date(trade?.time) >= fromDate
        );

        setFilteredUserLivePositionData(filteredPositionsData);
      }
    } else if (currentTab === "open-orders") {
      if (days === 0) {
        setFilteredUserOpenOrdersData(userOpenOrdersData);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredOpenOrdersData = userOpenOrdersData.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        setFilteredUserOpenOrdersData(filteredOpenOrdersData);

        return;
      } else {
        let filteredOpenOrderData = userOpenOrdersData;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredOpenOrderData = filteredOpenOrderData.filter(
          (order) => new Date(order?.time) >= fromDate
        );

        setFilteredUserOpenOrdersData(filteredOpenOrderData);
      }
    }

    // closeFilterDroprDownModal();
  };

  const handleResetFilterData = (currentTab) => {
    if (currentTab === "positions") {
      setFilteredUserLivePositionData(userLivePositionData);
      return;
    } else if (currentTab === "open-orders") {
      setFilteredUserOpenOrdersData(userOpenOrdersData);
      return;
    }
  };

  return (
    <DashboardLayout>
      <div className="flex flex-col relative  w-full max-h-screen">
        <div className="flex flex-col flex-1 ">
          <ShowForexOrdersData
            userId={userId}
            userLivePositionData={userLivePositionData}
            userOpenOrdersData={userOpenOrdersData}
            filteredUserLivePositionData={filteredUserLivePositionData}
            filteredUserOpenOrdersData={filteredUserOpenOrdersData}
            handleFilterByDays={handleFilterByDays}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setFilteredUserLivePositionData={setFilteredUserLivePositionData}
            setFilteredUserOpenOrdersData={setFilteredUserOpenOrdersData}
            handleResetFilterData={handleResetFilterData}
            isPositionsDataLoading={isPositionsDataLoading}
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(DashboardLive));
