import React from "react";
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { logout } from "../store/actions/AuthActions";
import { connect, useDispatch } from "react-redux";
import { cn } from "../utils/utils";

import {
  LogOutIcon,
  PlusCircleIcon,
  UsersIcon,
  AlignHorizontalDistributeCenterIcon,
  CandlestickChartIcon,
  LayoutDashboardIcon,
  SettingsIcon,
  SheetIcon,
  XIcon,
} from "lucide-react";
import Logo from "./logo";

const Icons = {
  dashboard: <LayoutDashboardIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  live: <AlignHorizontalDistributeCenterIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  history: <SheetIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  add: <PlusCircleIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  settings: <SettingsIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  orders: <SheetIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  trade: <CandlestickChartIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
  users: <UsersIcon className="mr-2 h-4 w-4 md:h-6 md:w-6" />,
};

const mobileNavItems = [
  {
    title: "Dashboard",
    href: "/admin",
    path: "/admin",
    icon: "dashboard",
  },
  {
    title: "Live",
    href: "/admin/live",
    path: "live",
    icon: "live",
  },
  {
    title: "History",
    href: "/admin/history",
    path: "history",
    icon: "history",
  },
  {
    title: "Add Strategy",
    href: "/admin/add-strategy",
    path: "add-strategy",
    icon: "add",
  },
  {
    title: "Users",
    href: "/admin/users",
    path: "users",
    icon: "users",
  },
  {
    title: "Order Placement",
    href: "/admin/order-placement",
    path: "order-placement",
    icon: "trade",
  },
];

function MobileNavBar({ isOpen, onClose }, props) {
  const dispatch = useDispatch();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  const path = window.location.pathname;

  const image =
    "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg";
  const email = "test77@gmail.com";

  const userName = "admin";

  if (!mobileNavItems?.length) {
    return null;
  }
  return (
    <div
      className={`fixed inset-0 z-50 ${
        isOpen ? "block " : "hidden"
      } transition ease-in duration-300`}
    >
      {/* Background Overlay */}
      <div
        className={`fixed inset-0 bg-black opacity-40  ${
          isOpen ? "opacity-100" : "opacity-0"
        }`}
        onClick={onClose}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed inset-y-0 left-0 w-full bg-black z-50 transform transition ease-in duration-500   ${
          isOpen ? "translate-x-0 animate-slideInNav" : "-translate-x-full "
        }`}
      >
        <aside
          className={`flex flex-col  transform top-0 left-0 w-full bg-black fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30 pt-4  ${
            isOpen
              ? "translate-x-0 animate-slideInNav"
              : "-translate-x-full animate-slideOutNav"
          }`}
        >
          <div onClick={onClose} className={`absolute   z-100 top-6 right-2`}>
            <XIcon className="h-6 w-6 md:h-8 md:w-8 text-white" />
          </div>
          <div className="px-2 mb-8 md:mb-12">
            <Logo />
          </div>
          <div className="flex flex-col flex-1 gap-3">
            {mobileNavItems.map((item, index) => {
              const Icon = Icons[item.icon];

              return (
                item.href && (
                  <div key={index}>
                    <Link key={index} to={item.disabled ? "/admin" : item.href}>
                      <div
                        className={cn(
                          "group flex items-center  px-3 py-3 md:py-5 text-sm md:text-2xl border-l-4 text-gray-100 font-medium  hover:bg-[#07F2C7]/20 hover:text-gray-300",
                          path === item.href
                            ? "bg-[#07F2C7]/20 border-l-4 border-[#07F2C7]"
                            : "border-l-4 border-transparent bg-transparent",
                          item.disabled && "cursor-not-allowed opacity-80"
                        )}
                      >
                        {Icon}
                        <span>{item.title}</span>
                      </div>
                    </Link>
                  </div>
                )
              );
            })}
          </div>

          <div className="flex  justify-between items-center px-4 mb-6">
            <div className="flex  items-center space-x-4">
              <img
                src={
                  image
                    ? image
                    : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
                }
                alt="user"
                className="h-8 w-8 md:h-12 md:w-12 rounded-md cursor-pointer  hover:opacity-50 hover:scale-[0.98]  transition duration-200 ease-in-out"
              />
              <div className="flex flex-col ">
                <p className="text-xs md:text-lg   text-white font-bold font-inter">
                  {userName || userName?.length > 0
                    ? userName
                    : email?.split("@")[0]}
                </p>
                <p className="text-xs md:text-lg   text-white font-bold font-inter">
                  {email}
                </p>
              </div>
            </div>
            <button
              onClick={onLogout}
              className=" flex items-center justify-center border-[1px] text-white h-8 w-8 md:h-10 md:w-10  border-gray-600  rounded-md  "
            >
              <LogOutIcon className="h-5 w-5 md:h-8 md:w-8 text-white " />
            </button>
          </div>
        </aside>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(MobileNavBar));
