import Navbar from "./Navbar";
import HeroSection from "./HeroSection";
import Pricing from "./Pricing";
import DetailsSection from "./DetailsSection";
import Service from "./Service";
import CTASection from "./CTASection";
import Footer from "./Footer";
import DataFigureSection from "./DataFigureSection";
import ChooseAccounts from "./ChooseAccounts";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";


const ForexLandingPage = () => {

  const [user] = useAuthState(auth);
  console.log("User Is ----> ",user)
  return (
    <div className=" min-w-full">
     

      {/* Navbar */}
      <Navbar user={user} />
      {/* Header Section  */}

      <HeroSection user={user} />

      {/* Deposit and withdrawal  Section */}
      <Pricing />

      {/* Details  Section  */}
      <DetailsSection />

      {/*  Access And Trade Section */}
      <Service />

      {/*  Choose Accounts  Section */}
      <ChooseAccounts />
      {/*  Data Figures Section */}
      <DataFigureSection  />

      {/* CTA Section */}
      <CTASection user={user}/>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default ForexLandingPage;
