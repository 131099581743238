import React, { useEffect, useState, useId } from "react";
import Select from "react-select";
import toast from "react-hot-toast";
import { DEV_URL, LIVE_URL } from "../../utils/data";
import { customStyles } from "../../utils/dropDownCustomStyles";
import { fetchTradeSymbols } from "../../utils/data";

export default function OrderPlacementForm({providerAccountId, providerAccountList }) {
  const [activeTab, setActiveTab] = useState("MARKET");
  const [actionType, setAcionType] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState();
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();

  console.log("Provider Acc Id",providerAccountId)

  const [formData, setFormData] = useState({
    orderType: "",
    volume: 0,
    openPrice: 0,
    takeProfit: 0,
    stopLoss: 0,
    stopLimitPrice: "",
  });

  const handleSelectAccount = (accountId) => {
    setSelectedAccount(accountId);
  };

  useEffect(() => {
    const getTradeSymbols = async () => {
      const symbolsData = await fetchTradeSymbols(providerAccountId);
      setSymbolOptions(symbolsData);
    };

    getTradeSymbols();
  }, [providerAccountId]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSymbolSelect = (symbol) => {
    console.log("Selected Symbol:", symbol);
    setSelectedSymbol(symbol);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (actionType === "BUY") {
      if (activeTab === "LIMIT") {
        formData.orderType = "ORDER_TYPE_BUY_LIMIT";
      } else if (activeTab === "STOP") {
        formData.orderType = "ORDER_TYPE_BUY_STOP";
      } else if (activeTab === "STOP_LIMIT") {
        formData.orderType = "ORDER_TYPE_BUY_STOP_LIMIT";
      } else {
        formData.orderType = "ORDER_TYPE_BUY";
      }
    } else if (actionType === "SELL") {
      if (activeTab === "LIMIT") {
        formData.orderType = "ORDER_TYPE_SELL_LIMIT";
      } else if (activeTab === "STOP") {
        formData.orderType = "ORDER_TYPE_SELL_STOP";
      } else if (activeTab === "STOP_LIMIT") {
        formData.orderType = "ORDER_TYPE_SELL_STOP_LIMIT";
      } else {
        formData.orderType = "ORDER_TYPE_SELL";
      }
    }

    const requestBody = {
      accountId: selectedAccount && selectedAccount?.value,
      actionType: formData.orderType,
      symbol: selectedSymbol && selectedSymbol?.value,
      volume: parseFloat(formData.volume),
      openPrice: parseFloat(formData.openPrice),
      takeProfit: parseFloat(formData.takeProfit),
      stopLoss: parseFloat(formData.stopLoss),
      stopLimitPrice: formData.stopLimitPrice,
    };

    console.log("request body is", requestBody);
    setFormData({
      orderType: "",
      volume: 0,
      openPrice: 0,
      takeProfit: 0,
      stopLoss: 0,
      stopLimitPrice: "",
    });
    setSelectedAccount("");
    setSelectedSymbol("");

    try {
      const response = await fetch(`${LIVE_URL}/api/trade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        // Request was successful
        const responseData = await response.json();

        toast.success("Trade Placed Successfully !!", {
          duration: 5000,
        });

        // console.log("Trade request successful:", responseData);
      } else {
        // Request failed
        // console.error("Trade request failed:", response.statusText);
        toast.error("Trade Failed !!, some Error Ocuured", {
          duration: 5000,
        });
      }
    } catch (error) {
      // console.error("An error occurred:", error);
    }
  };

  return (
    <div className=" absolute top-0 md:top-0 md:relative flex flex-col  lg:items-center lg:justify-center w-full  lg:max-w-[785px] mx-auto  px-0 md:py-16 lg:py-12 bg-[#17181B] lg:bg-[#17181B] lg:rounded-md lg:shadow-lg">
      <div className="  flex items-center lg:justify-center w-full  lg:w-full border-b border-gray-800">
        <div className="grid grid-cols-4 gap-x-2 md:gap-x-4  min-w-full   px-6 md:px-16">
          <button
            className={` text-sm md:text-[16px] font-medium px-0 md:px-4 py-4   ${
              activeTab === "LIMIT"
                ? "border-b-2 border-[#07F2C7]  text-white"
                : " text-gray-500 border-b-2 border-transparent"
            }`}
            onClick={() => handleTabChange("LIMIT")}
          >
            LIMIT
          </button>
          <button
            className={`  text-sm md:text-[16px] font-medium px-0 md:px-4 py-4  ${
              activeTab === "MARKET"
                ? "border-b-2 border-[#07F2C7]  text-white"
                : " text-gray-500 border-b-2 border-transparent"
            }`}
            onClick={() => handleTabChange("MARKET")}
          >
            MARKET
          </button>
          <button
            className={`  text-sm md:text-[16px] font-medium px-0 md:px-4 py-4  ${
              activeTab === "STOP"
                ? "border-b-2 border-[#07F2C7]  text-white"
                : "text-gray-500 border-b-2 border-transparent"
            }`}
            onClick={() => handleTabChange("STOP")}
          >
            STOP
          </button>
          <button
            className={` text-sm md:text-[16px] font-medium px-0 md:px-4 py-4   ${
              activeTab === "STOP_LIMIT"
                ? "border-b-2 border-[#07F2C7]  text-white"
                : "text-gray-500 border-b-2 border-transparent"
            }`}
            onClick={() => handleTabChange("STOP_LIMIT")}
          >
            STOP LIMIT
          </button>
        </div>
      </div>

      <form
        onSubmit={handleSubmit}
        className=" md:mt-8 flex flex-col items-center justfiy-center w-full  "
      >
        <div className="animate-slideInArrowFromTop lg:animate-none py-6 md:py-0 flex flex-col items-center justfiy-center w-full h-[480px] md:h-full overflow-scroll md:overflow-visible  px-6 md:px-16">
          <div className="flex flex-col space-y-1 mb-4 w-full z-50">
            <label className="flex items-center label text-white">
              Select Provider Account
            </label>

            <Select
              instanceId={useId()}
              value={selectedAccount}
              onChange={(e) => handleSelectAccount(e)}
              options={
                providerAccountList &&
                providerAccountList?.map((account) => ({
                  value: account.account_id,
                  label: account.account_name,
                }))
              }
              isSearchable
              placeholder=" Select Provider Account"
              styles={customStyles}
              className="text-white "
            />
          </div>
          
          <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-5 w-full z-10">
            <div className="">
              <label className="flex items-center label  text-white mb-2">
                Choose Symbol
              </label>
              <Select
                instanceId={useId()}
                value={selectedSymbol}
                onChange={handleSymbolSelect}
                options={
                  symbolOptions &&
                  symbolOptions?.map((option) => ({
                    value: option,
                    label: option,
                  }))
                }
                isSearchable
                placeholder="Choose Trade Symbol"
                styles={customStyles}
                className="text-white z-50 "
              />
            </div>

            <div className="">
              <label className="flex items-center label  text-white mb-2">
                Volume
              </label>
              <input
                required
                type="number"
                min="0"
                step="0.01"
                name="volume"
                className="w-full py-3 px-3 text-sm placeholder:text-gray-600  col-span-2 bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                onChange={handleInputChange}
              />
            </div>

            {activeTab === "LIMIT" && (
              <>
                <div className="">
                  <label className="flex items-center label  text-white mb-2">
                    Open Price
                  </label>
                  <input
                    required
                    type="number"
                    min="0"
                    step="0.01"
                    name="openPrice"
                    className="w-full py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
            {activeTab === "STOP_LIMIT" && (
              <>
                <div className="">
                  <label className="flex items-center label  text-white mb-2">
                    Open Price
                  </label>
                  <input
                    required
                    type="number"
                    min="0"
                    step="0.01"
                    name="openPrice"
                    className="w-full py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="">
                  <label className="flex items-center label  text-white mb-2">
                    Stop Limit Price
                  </label>
                  <input
                    required
                    type="number"
                    min="0"
                    step="0.01"
                    name="stopLimitPrice"
                    className="w-full py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
            {activeTab === "STOP" && (
              <div className="">
                <label className="flex items-center label  text-white mb-2">
                  Open Price
                </label>
                <input
                  required
                  type="number"
                  min="0"
                  step="0.01"
                  name="openPrice"
                  className="w-full py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div className="">
              <label className="flex items-center label  text-white mb-2">
                Take Profit
              </label>
              <input
                type="number"
                min="0"
                step="0.01"
                name="takeProfit"
                className="w-full py-3 px-3 text-sm placeholder:text-gray-600  bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                onChange={handleInputChange}
              />
            </div>
            <div className="">
              <label className="flex items-center label  text-white mb-2">
                Stop Loss
              </label>
              <input
                type="number"
                min="0"
                step="0.01"
                name="stopLoss"
                className="w-full py-3 px-3 text-sm placeholder:text-gray-600  bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="fixed bottom-0 lg:relative py-3 px-4 md:py-6 lg:px-16 lg:py-0  flex lg:mt-10   space-x-4 lg:space-x-6 w-full lg:justify-center ">
          <button
            className="flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 md:py-4 lg:py-3 text-base md:text-xl lg:text-base font-semibold  text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            type="submit"
            onClick={() => setAcionType("BUY")}
          >
            Buy
          </button>
          <button
            className="flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-rose-500 px-3 py-3 md:py-4 lg:py-3 text-base md:text-xl lg:text-base font-semibold  text-black shadow-sm hover:bg-rose-500/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            type="submit"
            onClick={() => setAcionType("SELL")}
          >
            Sell
          </button>
        </div>
      </form>
    </div>
  );
}
