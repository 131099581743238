export const ReduceObject = (myObject) => {
// Get the keys of the object
const objectKeys = Object.keys(myObject);
  
// Display only the first 20 fields
const first20Fields = objectKeys.slice(0, 30);

// Create a new object with only the first 20 fields
const reducedObject = {};
first20Fields.forEach((key) => {
  reducedObject[key] = myObject[key];
});

return reducedObject;
};
  