import React from "react";
//Firebase
import { auth } from "../../Login/firebase";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
const MobileNav = (props) => {
  return (
    <motion.div
      initial={{ y: -1000 }}
      animate={{ y: 0 }}
      exit={{ y: -1000 }}
      transition={{ duration: 1 }}
      className="w-full x bg-black text-neutral-50 mt-12 pt-8 fixed top-0 z-40 font-Poppins"
    >
      {/*------------------------User Data--------------------------- */}
      {props.user && (
        <div className="flex flex-row bg-white/10 p-3 pt-3 mx-6 mt-3 rounded-lg">
          {/*----------------Avatar--------------- */}
          <div className="rounded-full overflow-hidden">
            <img src={props.user.photoURL} alt="" className="h-12" />
          </div>
          {/*---------------User Info---------------- */}
          <div className="ml-4 flex flex-col justify-start">
            {/*----------------Name------------------ */}
            <div className="text-lg">{props.user.displayName}</div>
            {/*----------------Email------------------ */}
            <div className="text-xs text-white/50">{props.user.email}</div>
          </div>
        </div>
      )}

      {/* <div className="w-full px-6 my-3">
        {props?.userSubscribedTradeStrategy?.strategy_name?.length > 0 && (
          <button className="relative flex items-center justify-center text-sm text-white w-auto   px-4 py-2 rounded-md">
            {props?.userSubscribedTradeStrategy?.strategy_name}
            <span class="absolute right-1.5 top-2 flex h-2 w-2">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-2 w-2 bg-rose-500"></span>
            </span>
          </button>
        )}
      </div> */}

      <Link to="/connect-account">
        <div className="text-neutral-50 hover:text-neutral-50">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            Connect Account
          </div>
        </div>
      </Link>
      <Link to="/strategy">
        <div className="text-neutral-50 hover:text-neutral-50">
          <div className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto">
            View All Strategies
          </div>
        </div>
      </Link>
      <Link to="/user">
        <div className="text-neutral-50 hover:text-neutral-50">
          <div className="flex items-center w-[85vw] px-2 py-2 border-b border-white/10 cursor-pointer mx-auto">
            My Portfolio
            {props?.userSubscribedTradeStrategy?.strategy_name?.length > 0 && (
          <button className="relative flex items-center justify-center text-sm text-white w-auto   px-4 py-2 rounded-md">
            ({props?.userSubscribedTradeStrategy?.strategy_name})
            <span class="absolute right-1.5 top-2 flex h-2 w-2">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-2 w-2 bg-rose-500"></span>
            </span>
          </button>
        )}
          </div>
          
        </div>
      </Link>
      {props.user && (
        <div
          onClick={() => {
            auth.signOut();
          }}
          className="w-[85vw] px-2 py-4 border-b border-white/10 cursor-pointer mx-auto"
        >
          Logout
        </div>
      )}
    </motion.div>
  );
};

export default MobileNav;
