import React, { useState } from "react";

import styles from "./Login.module.css";
import ForexLogo from "../assests/ForexLogo.svg";

import { FcGoogle } from "react-icons/fc";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import axios from "axios";

import { IoIosArrowBack } from "react-icons/io";
import firebase from "firebase/compat/app";
import { PiCaretDown, PiCaretUp } from "react-icons/pi";
import { motion } from "framer-motion";

import SignInEmail from "./signInEmail";
import { auth, provider } from "./firebase";
import { CountryCode } from "./CountryCode";
import Timer from "./Timer";
import SignUpEmail from "./SignUpEmail";
import ForgotPassword from "./forgotPassword";

const style = {
  selectDiv:
    "flex items-center px-2 py-2  rounded-lg mx-3 md-3 text-black-500  hover:bg-black/10 hover:text-black-500  ease-linear duration-150 cursor-pointer",
  loginBorder: "",
};

export default function LoginPage(props) {
  // login with gmail start
  const handleGmailLogin = async () => {
    auth
      .signInWithPopup(provider)
      .then(async (response) => {
        if (response) {
          const id = uuidv4();
          let data = JSON.stringify({
            id: id,
            email: response.additionalUserInfo.profile.email,
            username: response.additionalUserInfo.profile.name,
          });

          let config = {
            method: "post",
            url: "https://forex.server.sypto.xyz/api/create-user-account",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          axios
            .request(config)
            .then((response) => {})
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch(alert);
  };
  //login with gmail end

  //login with otp start
  const [mobileShow, setMobileShow] = useState(false);
  const [number, setNumber] = useState();
  const [otpEntered, setOtpEntered] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [dialogBoxVisibility, setDialogBoxVisibility] = useState(false);
  const [token, setToken] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  const [showCountryCode, setShowCountryCode] = useState(false);
  const [authenticating, setAuthenticating] = useState(false);

  const getOTPassword = () => {
    return new Promise((resolve, reject) => {
      if (otpEntered.length === 6) {
        resolve(otpEntered);
      } else {
        reject(0);
      }
    });
  };
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recptcha-container",
      {
        size: "invisible",
        callback: function (response) {
          onSignInSubmit();
          onSignInSubmitEnter();
        },
      }
    );
  };

  const onSignInSubmitEnter = () => {
    setUpRecaptcha();
    setOtpSent(true);
    if (number.length !== 10) {
      alert(number + " Invalid Number");
      setOtpSent(false);
    } else {
      runCaptcha();
    }
  };

  const onSignInSubmit = (event) => {
    event.preventDefault();
    setUpRecaptcha();
    setOtpSent(true);
    if (number.length !== 10) {
      alert(number + " Invalid Number");
      setOtpSent(false);
    } else {
      runCaptcha();
    }
  };
  const runCaptcha = () => {
    var phoneNumber = countryCode + number;
    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setDialogBoxVisibility(true);
        setToken(confirmationResult);
      });
  };

  const submitOTP = async () => {
    setAuthenticating(true);
    let confirmationResult = token;
    window.confirmationResult = confirmationResult;
    await getOTPassword()
      .then((code) => {
        confirmationResult
          .confirm(code)
          .then((result) => {
            const id = uuidv4();
            let data = JSON.stringify({
              id: id,
              mobile_number: number,
              countrycode: countryCode,
            });

            let config = {
              method: "post",
              url: "https://forex.server.sypto.xyz/api/create-user-account",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios
              .request(config)
              .then((response) => {})
              .catch((error) => {
                console.log(error);
              });
            props.setShow(false);
            setMobileShow(false);
            setDialogBoxVisibility(false);
            setOtpSent(false);
            // setNumber("");
          })
          .catch(function (error) {
            if (error.code === "auth/invalid-verification-code") {
              alert("Invalid OTP, Please check again or resend OTP");
            }
          });
      })
      .catch((err) => {
        alert("incorrect OTP Entered");
      });
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submitOTP();
    }
  };
  const handleKeyPressSendOtp = (event) => {
    if (event.key === "Enter") {
      onSignInSubmitEnter();
    }
  };
  //login with otp end

  const [signUpMethodOpen, setSignUpMethodOpen] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  return (
    <div
      className={` bg-hero bg-no-repeat min-h-screen bg-cover w-full flex items-center justify-center`}
    >
      <div className="flex w-full justify-center items-center">
        <div
          className={` backdrop-blur-md bg-white/10 rounded-lg w-[90%] md:w-[70%] lg:w-1/3 flex flex-col justify-center items-center px-4 md:px-12 py-8 md:py-16`}
        >
          <img src={ForexLogo} alt="Forex Logo" />{" "}
          {mobileShow === true ? (
            <div className="max-w-screen-xl w-full relative rounded-lg font-Poppins">
              <div
                onClick={() => {
                  setMobileShow(false);
                  setDialogBoxVisibility(false);
                  setOtpSent(false);
                  setNumber("");
                }}
                className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-white absolute top-0 -left-4  m-1 font-bold hover:text-[#07F2C7]  ease-linear duration-150 cursor-pointer"
              >
                <div>
                  <IoIosArrowBack size={25} />
                </div>
              </div>

              <div id="recptcha-container"></div>
              <div className="pt-8"></div>
              <div className="w-full bg-white/10 flex flex-row items-center px-6 mt-8 rounded-[8px] focus:outline-none focus:border-[1px] focus:border-white/20">
                <div
                  className={`w-[25%] text-[#000000]/80 flex flex-row items-center relative cursor-pointer`}
                  onClick={() => setShowCountryCode(!showCountryCode)}
                >
                  <div className="text-gray-100">{countryCode}</div>

                  <div
                    className={`pl-1 ease-linear duration-150 text-gray-100`}
                  >
                    {showCountryCode ? (
                      <PiCaretUp size={20} />
                    ) : (
                      <PiCaretDown size={20} />
                    )}
                  </div>
                </div>
                {showCountryCode && (
                  <motion.div
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className="absolute right-0 top-12 bg-white flex flex-col rounded-lg w-[100%] pt-2 pb-2 z-10 box-border border-2 border-solid border-[ rgba(185, 192, 187, 0.7)] "
                  >
                    {CountryCode.map((ele) => {
                      return (
                        <div
                          className={`pl-3 font-medium ${style.selectDiv}`}
                          onClick={() => {
                            setCountryCode(ele.value);
                            setShowCountryCode(false);
                          }}
                        >
                          {ele.value} {ele.label}
                        </div>
                      );
                    })}
                  </motion.div>
                )}
                <input
                  placeholder="Enter your phone number"
                  className="w-[75%] text-left  py-2 my-1 bg-transparent text-gray-100 px-3 text-base border-l-[1px] border-[#ffffff]/40"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  type="number"
                  onKeyPress={handleKeyPressSendOtp}
                />
              </div>

              {dialogBoxVisibility === true ? (
                <div onKeyPress={handleKeyPress} className="w-full">
                  <input
                    placeholder="Enter 6 digit code"
                    className="mt-4 w-full text-left  py-3 bg-white/10 text-gray-100 rounded-[6px] border-[1px] border-white/10 px-3 text-base focus:outline-none focus:border-[1px] focus:border-white/20"
                    value={otpEntered}
                    onChange={(e) => setOtpEntered(e.target.value)}
                    type="text"
                  />
                  <div
                    className="flex flex-row justify-center bg-[#07F2C7] text-black text-lg rounded-[8px] px-4 py-2 mt-4 cursor-pointer font-semibold"
                    onClick={submitOTP}
                  >
                    {authenticating === true
                      ? "Authenticating ......."
                      : "Submit otp"}
                  </div>
                  <Timer resend={runCaptcha} />
                </div>
              ) : (
                <div className="w-full">
                  {otpSent === true ? (
                    <div className="flex flex-row justify-center bg-[#07F2C7] text-black text-lg rounded-[8px] px-4 py-2 mt-4 cursor-pointer">
                      Sending Otp ...
                    </div>
                  ) : (
                    <div
                      className="w-full flex flex-row justify-center bg-[#07F2C7] text-black text-lg rounded-[8px] px-4 py-2.5 mt-4 cursor-pointer font-semibold"
                      onClick={onSignInSubmit}
                    >
                      Get otp
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : signUpMethodOpen === true ? (
            <SignUpEmail setSignUpMethodOpen={setSignUpMethodOpen} />
          ) : openForgotPassword === true ? (
            <ForgotPassword setOpenForgotPassword={setOpenForgotPassword} />
          ) : (
            <div className="md:max-w-screen-xl w-full relative rounded-lg font-Poppins">
              <div
                className="bg-white rounded-[8px] shadow-xl text-base flex flex-row justify-center py-2.5 w-full mt-8 cursor-pointer"
                onClick={handleGmailLogin}
              >
                <div>
                  <FcGoogle size={25} />
                </div>
                <div className="ml-4 text-lg font-semibold text-black">
                  Sign In with Google
                </div>
              </div>
              <div
                className="bg-white rounded-[8px] shadow-xl text-base flex flex-row justify-center py-2.5 w-full mt-4 cursor-pointer"
                onClick={() => setMobileShow(true)}
              >
                <div className="ml-4 text-lg font-semibold text-black">
                  Sign In with OTP
                </div>
              </div>
              <div className={`py-4 ${styles.centerseparatorMobile}`}>
                <div className={styles.centerText}>or</div>
              </div>
              <SignInEmail setOpenForgotPassword={setOpenForgotPassword} />
              <div className="text-[#ffffff] text-base w-full flex justify-start">
                Don’t have an account ?{" "}
                <div
                  className="ml-2 text-[#07F2C7] cursor-pointer"
                  onClick={() => setSignUpMethodOpen(true)}
                >
                  Sign up
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
