import React, { useEffect, useState } from "react";
import Header from "./Header/Header";
import {
  getUserAccountByEmail,
  getAllUsersTradeStrategy,
  getUserSubAccountByAccountId,
  getAllUserSubAccountBySubscriberId,
} from "../utils/data";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";
import SubscribeStrategyCard from "./SubscribeStrategyCard";
import LoadingSpinner from "../components/loading-spinner";

const SingleSubscriber = () => {
  const [user] = useAuthState(auth);

  const email = user && user.email;

  const [adminUserId, setAdminUserId] = useState("");
  const [subscriberAccountId, setSubscriberAccountId] = useState("");
  const [subscriberUserId, setSubscriberUserId] = useState("");
  const [tradeStrategyDataList, setTradeStrategyDataList] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setIsDataLoading(true);
    const fetchUserData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";
      setSubscriberUserId(id);

      const userSubAccountData = await getAllUserSubAccountBySubscriberId(id);

      const adminId = userSubAccountData?.data[0]
        ? userSubAccountData?.data[0]?.user_id
        : "";

      setAdminUserId(adminId);

      const subscriberAccId = userSubAccountData?.data[0]
        ? userSubAccountData?.data[0]?.account_id
        : "";

      setSubscriberAccountId(subscriberAccId);

      const tradeStrategyData = await getAllUsersTradeStrategy();

      // console.log("Trade Strategy data", tradeStrategyData);
      setTradeStrategyDataList(tradeStrategyData?.data);
      setIsDataLoading(false);
    };

    fetchUserData();
  }, []);
  return (
    <div className="bg-black min-h-screen ">
      <Header />
      <div className="px-4 md:px-16 lg:px-20 mt-20   ">
        <div className="text-xl md:text-2xl font-normal font-Montserrat text-white py-8 animate-slideInFromBottom transition duration-300 ease-in">
          To begin, select and subscribe to one of the below strategies:
        </div>

        {tradeStrategyDataList && isDataLoading && (
          <div className="flex flex-col mx-auto w-[250px] ml-16 md:ml-28 lg:ml-0 md:w-[600px] lg:w-full  h-[200px] lg:h-[400px] items-center justify-center text-white text-lg ">
            <LoadingSpinner />
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
          {tradeStrategyDataList &&
            !isDataLoading &&
            tradeStrategyDataList?.map((data, i) => (
              <div key={data.strategy_id} className="min-w-1/3 w-full ">
                <SubscribeStrategyCard
                  index={i * 2000}
                  subscriberAccountId={subscriberAccountId}
                  subscriberUserId={subscriberUserId}
                  adminUserId={adminUserId}
                  strategyCreatorId={data.user_id}
                  strategyId={data.strategy_id}
                  strategyName={data.strategy_name}
                  strategyDescription={data.strategy_description}
                  strategySubscriberList={data.subscriber_list}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SingleSubscriber;
