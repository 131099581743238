import React, { useEffect, useState, useId } from "react";
import { DEV_URL, LIVE_URL } from "../../utils/data";
import Select from "react-select";
import { customStyles } from "../../utils/dropDownCustomStyles";
import StrategyCard from "./StrategyCard";
import Modal from "../../components/modal";
import toast from "react-hot-toast";
import { XIcon, PlusIcon } from "lucide-react";
import LoadingSpinner from "../../components/loading-spinner"

export default function StrategyList({
  userId,
  providerAccountId,
  providerAccountList,
  tradeStrategyDataList,
  isStrategyDataChanged,
  setIsStrategyDataChanged,
  isStrategyDataLoading
}) {
  const [showModal, setShowModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [formData, setFormData] = useState({
    strategyName: "",
    strategyDescription: "",
  });

  useEffect(() => {
    setFormData({
      strategyName: "",
      strategyDescription: "",
    });
    setSelectedAccount("");
  }, [isStrategyDataChanged]);

  const handleSelectAccount = (accountId) => {
    setSelectedAccount(accountId);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleGenerateStrategy = async (e) => {
    e.preventDefault();

    if (
      !formData.strategyDescription ||
      !formData.strategyName ||
      !selectedAccount?.value
    ) {
      toast.error("Please Provide Complete Details", {
        duration: 4000,
      });
      return;
    }

    const strategyData = {
      userId: userId,
      accountId: selectedAccount?.value,
      strategyName: formData.strategyName,
      strategyDescription: formData.strategyDescription,
      createdAt: new Date(),
    };
    // console.log("Strategy Data ----> ", strategyData);

    const res = await fetch(`${LIVE_URL}/api/create-strategy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(strategyData),
    });

    const data = await res.json();

    if (data?.status === 200) {
      toast.success("New Strategy Generated !!", {
        duration: 4000,
      });
      // console.log("Strategy Generated Successfully!!!", data);
    } else {
      toast.error("Strategy Generation Failed !!", {
        duration: 4000,
      });
    }
    // console.log("Form Data ----> ", formData);
    setFormData({
      strategyName: "",
      strategyDescription: "",
    });
    handleCloseModal();
    setIsStrategyDataChanged(!isStrategyDataChanged);
  };

  return (
    <>
      <div className="fixed bottom-0 py-4 px-4  md:pb-6 md:pt-0 lg:relative  bg-black lg:bg-transparent lg:z-0 lg:flex w-full lg:p-4">
        <div className="z-50 w-full flex justify-center  lg:justify-end lg:space-x-8 ">
          {/* <button
            onClick={handleShowSubscriberModal}
            className={`flex  cursor-pointer justify-center rounded-md  px-3 py-3 text-base font-semibold leading-6 text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 bg-blue-600 hover:bg-blue-400 `}
          >
            Subscribe Strategy
          </button> */}
          <button
            onClick={handleShowModal}
            className={`flex w-full lg:w-[180px] cursor-pointer items-center justify-center rounded-md bg-[#07F2C7] px-4 py-2.5 md:px-4 md:py-4 lg:px-4  lg:py-2.5 text-base md:text-xl lg:text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
          >
            <PlusIcon strokeWidth={3} className="mr-2 w-[15px] h-[15px]" />
            New Strategy
          </button>
        </div>
      </div>

      {/* <div className="fixed bottom-8 right-4 flex z-50 md:z-0 md:hidden md:w-full md:p-4">
        <button
          onClick={handleShowModal}
          className={`flex w-[60px] h-[60px] cursor-pointer items-center justify-center rounded-full bg-[#07F2C7]  text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2  `}
        >
          <PlusIcon strokeWidth={3} className=" w-6 h-6" />
        </button>
      </div> */}

      {/* For Generating Strategy  */}

      {showModal && (
        <Modal>
          <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[400px] h-[500px] md:w-[785px] md:h-[500px] rounded-md py-4 px-8">
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>

            <div className="flex flex-col justify-center  space-y-8 w-full md:w-[510px] h-full">
              <h2 className=" text-center text-xl md:text-2xl font-medium  tracking-tight text-white">
                New Strategy
              </h2>
              <form
                onSubmit={handleGenerateStrategy}
                className="flex  flex-col space-y-12 "
              >
                <div className="grid gap-6">
                  <div className="flex flex-col space-y-1  md:grid md:grid-cols-3 md:gap-2">
                    <label
                      className="flex items-center label md:cols-span-1 text-white"
                      htmlFor="strategyName"
                    >
                      Strategy Name :
                    </label>
                    <input
                      required
                      id="strategyName"
                      name="strategyName"
                      placeholder="Strategy Name"
                      type="text"
                      value={formData.strategyName}
                      onChange={handleChange}
                      className="py-3 px-3 text-sm placeholder:text-gray-600  md:col-span-2 bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    />
                  </div>

                  <div className="flex flex-col space-y-1 md:grid md:grid-cols-3 md:gap-2">
                    <label
                      className="flex items-center label md:cols-span-1 text-white"
                      htmlFor="strategyDescription"
                    >
                      Strategy Description :
                    </label>
                    <input
                      required
                      id="strategyDescription"
                      name="strategyDescription"
                      placeholder="Strategy Description"
                      type="text"
                      value={formData.strategyDescription}
                      onChange={handleChange}
                      className="py-3 px-3 text-sm placeholder:text-gray-600  md:col-span-2 bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    />
                  </div>
                  <div className="flex flex-col space-y-1 md:grid md:grid-cols-3 md:gap-2">
                    <label
                      className="flex items-center label md:cols-span-1 text-white"
                      htmlFor="providerAccountId "
                    >
                      Provider Account Id :
                    </label>
                    <Select
              
                      value={selectedAccount}
                      onChange={(e) => handleSelectAccount(e)}
                      options={
                        providerAccountList &&
                        providerAccountList?.map((account) => ({
                          value: account.account_id,
                          label: account.account_name,
                        }))
                      }
                      isSearchable
                      placeholder=" Select Provider Account"
                      styles={customStyles}
                      className="text-white md:col-span-2"
                    />
                  </div>
                </div>
                <div className="flex  mt-6 md:mt-0 md:flex-row space-x-4 md:justify-between md:space-x-4">
                  <button
                    disabled={
                      !formData.strategyName ||
                      !formData.strategyDescription ||
                      !selectedAccount?.value
                    }
                    type="submit"
                    className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Create
                  </button>

                  <div
                    onClick={handleCloseModal}
                    className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Cancel
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}

      {tradeStrategyDataList?.length === 0 && !isStrategyDataLoading && (
        <div className="flex flex-col flex-1 items-center justify-center text-white">
          No Strategies Found!!
        </div>
      )}
      {tradeStrategyDataList?.length === 0 && isStrategyDataLoading && (
        <div className="flex flex-col flex-1 items-center justify-center text-white">
          <LoadingSpinner />
        </div>
      )}
      {tradeStrategyDataList?.length > 0 && (
        <div className="flex flex-col animate-reveal transition-all duration-200 ease-in">
          <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 px-4 md:px-4  place-items-center gap-4 mt-0 md:mt-0 w-full  py-8">
            {tradeStrategyDataList?.map((data, index) => (
              <div key={data.strategy_id} className={`min-w-1/3 w-full `}>
                <StrategyCard
                  index={index}
                  providerAccountId={providerAccountId}
                  strategyCreatedByAccountId={data.account_id}
                  strategyId={data.strategy_id}
                  strategyName={data.strategy_name}
                  strategyDescription={data.strategy_description}
                  isStrategyDataChanged={isStrategyDataChanged}
                  setIsStrategyDataChanged={setIsStrategyDataChanged}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
