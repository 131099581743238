export const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      padding: "3px",
      fontSize: "0.875rem", 
      placeholder: { color: "#ffffff" }, 
      backgroundColor: "transparent",
      color: "#ffffff !important", 
      border: "1px solid #ffffff33", 
      borderRadius: "6px",
      outline: "none",
      boxShadow:'none',
      caretColor:"#ffffff",
      '&:hover': {
        color: '#ffffff !important', // text color on hover
        border: "1px solid #ffffff33",
       
      },
      '&:active': {
        color: '#ffffff !important', // text color on hover
        border: "1px solid #ffffff33",
     
      },
      '&:focus': {
        color: '#ffffff !important', // text color on hover
        border: "1px solid #ffffff",
     
    
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#17181B",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "180px", 
      // overflowY: "auto",
      backgroundColor: '#111111', 
      zIndex:100
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : 'white', 
      backgroundColor: state.backgroundColor ? "#07f2c733" : null, // background color on focus
      '&:hover': {
        backgroundColor: '#07f2c733', 
        color: '#ffffff !important', // text color on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#ffffff !important", 
      '&:hover': {
        backgroundColor: '#07f2c733',
        border:'none',
        outline:'none', 
        color: '#ffffff !important', // text color on hover
      },
    }),
  };