import React, { useState, useEffect, useRef } from "react";
import { DEV_URL, LIVE_URL } from "../../utils/data";
import {
  Loader2,
  XIcon,
  UserCogIcon,
  Trash2Icon,
  PauseIcon,
  PlayIcon,
  EyeIcon,
  MoveLeftIcon,
  MoveRightIcon,
} from "lucide-react";
import UpdateAccountForm from "./UpdateAccountForm";
import toast from "react-hot-toast";
import UserTableSkeleton from "../../components/UserTableSkeleton";
import Modal from "../../components/modal";
import { ReduceObject } from "../../utils/reduceObject";

const allData = [
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45045",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45046",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
  {
    account_id: "8ef0504d-fe2e-415d-bd3d-4df435d1b546",
    user_id: "87f7392a-e6b2-4db0-a586-89e8dcf3e8bc",
    state: "DEPLOYED",
    password: "Kunal@123",
    account_type: "cloud-g2",
    region: "new-york",
    broker: "TradeUltra Limited",
    currency: "USD",
    server: "TradeUltra-Live",
    balance: 3283.79,
    equity: 3283.79,
    margin: 0,
    free_margin: 3283.79,
    leverage: 100,
    type: "ACCOUNT_TRADE_MODE_REAL",
    name: "Kunal Gobindram Kataria",
    login: "45047",
    credit: 0,
    platform: "mt5",
    trade_allowed: true,
    margin_mode: "ACCOUNT_MARGIN_MODE_RETAIL_HEDGING",
    investor_mode: false,
    account_currency_exchange_rate: 1,
    magic: 0,
    account_name: "Test-1",
    connection_status: "CONNECTED",
    quote_streaming_interval_in_seconds: 2.5,
    reliability: "high",
    tags: [],
    resource_slots: 1,
    copy_factory_resource_slots: 1,
    version: 5,
    hash: 11802,
    copy_factory_roles: ["PROVIDER"],
    application: "MetaApi",
    created_at: "2023-10-19T04:25:23.378Z",
    primary_replica: true,
    account_replicas: [],
    connections: [[Object], [Object], [Object], [Object]],
  },
];

export default function UserTable({ userId, userSubAccounts }) {
  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [editBoxPosition, setEditBoxPosition] = useState({ top: 0 });
  const [showAllDataModal, setShowAllDataModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showAllData, setShowAllData] = useState([]);
  const [deleteAccountData, setDeleteAccountData] = useState({
    index: "",
    accountId: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  let itemsPerPage = 10;
  const dropDownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropDownRef.current &&
        !dropDownRef.current.contains(event.target) &&
        dropDownRef
      ) {
        setOpenRowIndex(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropDownRef]);

  const [formData, setFormData] = useState({
    index: 0,
    accountData: userSubAccounts[0],
  });

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = (index, data) => {
    setShowModal(true);
    setOpenRowIndex(null);
    setFormData({
      index: index,
      accountData: data,
    });
  };

  // const toggleEdit = (e, index) => {
  //   if (openRowIndex === index) {
  //     setOpenRowIndex(null);
  //   } else {
  //     setOpenRowIndex(index);

  //     setOpenRowIndex(index); // Open the edit box for the clicked row
  //     // Calculate the position relative to the clicked row
  //     const row = e.target.closest("tr");
  //     const rowRect = row.getBoundingClientRect();
  //     setEditBoxPosition({
  //       top: rowRect.bottom - 8,
  //     });
  //   }
  // };

  const updateUserAccountBalance = async () => {
    const result = await fetch(`${LIVE_URL}/api/update-account-balance`);

    const data = await result.json();

    console.log("Upadted account balance data", data);
  };
  const updateUserInvestedAmount = async () => {
    const result = await fetch(`${LIVE_URL}/api/update-invested-amount`);

    const data = await result.json();

    console.log("Upadted Invested Amount data", data);
  };

  // To Stop the Server
  const handleUnDeployServer = async (index, accountId) => {
    setOpenRowIndex(null);
    const formRequestBodyUndeploy = {
      accountId: accountId,
      newState: "Undeployed",
      connection: "Disconnected",
    };

    const stopServerResponse = await fetch(
      `${LIVE_URL}/api/stop-account-server`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBodyUndeploy),
      }
    );
    const stopServerData = await stopServerResponse.json();

    if (stopServerData?.status === 200) {
      toast.success(`Server Stopped Successfully !!`, {
        style: {
          minWidth: "250px",
        },
        duration: 6000,
      });
    } else if (stopServerData?.status === 400) {
      toast.error(` Server Stopped Failed !!!!`, {
        style: {
          minWidth: "250px",
        },
        duration: 6000,
      });
    }

    // console.log("Response Data For Stopping Server", stopServerData);

    // console.log("Stopping Server acc with index", index);
    // console.log("account wit id Undeployed, server Stopped!!",accountId);
    setOpenRowIndex(null);
  };

  // To Restart The Server

  const handleReDeployServer = async (index, accountId) => {
    setOpenRowIndex(null);
    const formRequestBodyRedeploy = {
      accountId: accountId,
      newState: "Deployed",
      connection: "Connected",
    };

    const startServerResponse = await fetch(
      `${LIVE_URL}/api/start-account-server`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBodyRedeploy),
      }
    );
    const startServerData = await startServerResponse.json();
    // console.log("1st Response Data For Starting Server", startServerData);

    if (startServerData.status === 200) {
      toast.success(`Server Deployed SuccessFully!!`, {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 10000,
          icon: "🔥",
        },
      });
      await updateUserAccountBalance();
      await new Promise((resolve) => setTimeout(resolve, 10000));
      await updateUserInvestedAmount();
      return;
    }

    if (startServerData.status === 404) {
      // toast.loading(`Trying to connect to server it  will take some time.`, {
      //   style: {
      //     minWidth: "250px",
      //   },
      //   duration: 10000,
      // });
      toast.loading(`Waiting For Server Connection`, {
        style: {
          minWidth: "250px",
        },
        duration: 16000,
      });
      // Waiting for 3 mins for Server Connection

      await new Promise((resolve) => setTimeout(resolve, 180000));

      // console.log("3 min Completed 1st Time !!!");
      const startServerResponseTwo = await fetch(
        `${LIVE_URL}/api/start-account-server`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formRequestBodyRedeploy),
        }
      );
      const startServerDataTwo = await startServerResponseTwo.json();
      // console.log(
      //   "Response Data For Starting Server 2nd Time ",
      //   startServerResponseTwo
      // );

      if (startServerDataTwo.status === 200) {
        toast.success(`Server Deployed SuccessFully !!`, {
          style: {
            minWidth: "250px",
          },
          success: {
            duration: 10000,
            icon: "🔥",
          },
        });
        await updateUserAccountBalance();
        await new Promise((resolve) => setTimeout(resolve, 10000));
        await updateUserInvestedAmount();
        return;
      } else {
        toast.loading(`Trying to connect to server it  will take some time.`, {
          style: {
            minWidth: "250px",
          },
          duration: 10000,
        });
        // Waiting for 3 mins for Server Connection

        await new Promise((resolve) => setTimeout(resolve, 180000));
        // console.log("3 min Completed 2nd Time !!!");

        const startServerResponseThree = await fetch(
          `${LIVE_URL}/api/start-account-server`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formRequestBodyRedeploy),
          } // console.log(
          //   "Response Data For Stopping Server 3rd time",
          //   startServerDataThree
          // );
        );
        const startServerDataThree = await startServerResponseThree.json();

        if (startServerDataThree.status == 200) {
          toast.success(`Server Deployed Successfully !!`, {
            style: {
              minWidth: "250px",
            },
            success: {
              duration: 10000,
              icon: "🔥",
            },
          });
          await updateUserAccountBalance();
          await new Promise((resolve) => setTimeout(resolve, 10000));
          await updateUserInvestedAmount();
          return;
        } else {
          const formRequestBodyUndeploy = {
            accountId: accountId,
            newState: "Deployed",
            connection: "Disconnected",
          };

          const stopServerResponse = await fetch(
            `${LIVE_URL}/api/update-account-server`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formRequestBodyUndeploy),
            }
          );

          toast.error(`Server Restart Failed!!, please Try Again.`, {
            style: {
              minWidth: "250px",
            },
            duration: 10000,
          });
        }
      }
    }

    // console.log("Starting Server acc with index", index);
    // console.log("Account with id Redeployed, server Started!!",accountId);
    setOpenRowIndex(null);
  };

  const handleDeleteAccount = async () => {
    const formRequestBodyDelete = {
      accountId: deleteAccountData?.accountId,
    };

    // console.log("Delete Data ", formRequestBodyDelete);

    const deleteResponse = await fetch(`${LIVE_URL}/api/delete-sub-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formRequestBodyDelete),
    });
    const responseData = await deleteResponse.json();

    if (responseData.status === 200) {
      toast.success(`Account Deleted !!`, {
        style: {
          minWidth: "250px",
        },
        duration: 6000,
      });
    } else if (responseData.status === 400) {
      toast.error(`Account Deletion Failed !!`, {
        style: {
          minWidth: "250px",
        },
        duration: 6000,
      });
    }

    // console.log("Response Data After delete Account", responseData);

    // console.log("deleteing acc with index", index);
    // console.log("deleteing acc with accountId", accountId);

    setOpenRowIndex(null);
    handleCloseDeleteAccountModal();
  };

  const handleView = (allData) => {
    setShowAllDataModal(true);
    setShowAllData(allData);
    setOpenRowIndex(null);
  };

  const handleCloseDataModal = () => {
    setShowAllDataModal(false);
  };

  const handleShowDeleteAccountModal = (index, accountId) => {
    setShowDeleteAccountModal(true);
    setDeleteAccountData({
      index: index,
      accountId: accountId,
    });
  };

  const handleCloseDeleteAccountModal = () => {
    setShowDeleteAccountModal(false);
  };

  ///// Pagination for User Table ////////////////

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = userSubAccounts.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(userSubAccounts.length / itemsPerPage);

  const displayPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtons = 5; // You can adjust this value based on your preference

    let startPage;
    let endPage;

    if (totalPages <= maxPageButtons) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= Math.ceil(maxPageButtons / 2)) {
        startPage = 1;
        endPage = maxPageButtons;
      } else if (currentPage + Math.floor(maxPageButtons / 2) >= totalPages) {
        startPage = totalPages - maxPageButtons + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - Math.floor(maxPageButtons / 2);
        endPage = currentPage + Math.floor(maxPageButtons / 2);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`flex items-center justify-center text-xs  font-semibold rounded-full h-5 w-5 ${
            currentPage === i
              ? "bg-[#07F2C7] text-black"
              : "bg-white/10 text-white/40"
          }`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className=" z-0  relative w-full h-full  overflow-hidden">
        <div className=" w-full h-[calc(100vh-170px)] md:h-[calc(100vh-235px)] lg:h-[calc(100vh-140px)] custom-scroll overflow-x-auto  shadow-md animate-reveal transition-all duration-200 ease-in">
          <table className="z-0  text-sm w-full relative ">
            <thead className="z-10 text-sm text-left  sticky top-0">
              <tr className="   bg-[#0f0f0f]  text-white border-b  border-gray-800">
                <th className="min-w-[140px] md:min-w-full px-4 py-4 font-medium">
                  Account Name
                </th>
                <th className="px-4 py-4 font-medium">Login</th>
                <th className="px-4 py-4 font-medium">Server</th>
                <th className="px-4 py-4 font-medium">Invested Amount</th>
                <th className="px-4 py-4 font-medium">Current Balance</th>
                <th className="px-4 py-4 font-medium ">State</th>

                <th className="px-4 py-4 font-medium">Connection Status</th>

                <th className="px-4 py-4 font-medium">Copy Factory Role</th>

                <th className=" px-4 py-4 font-medium ">
                  <div className="flex flex-col items-center justify-center ">
                    <span>Servers</span>
                    <span className="text-[9px]">(Start/Stop)</span>
                  </div>
                </th>
                <th className=" px-4 py-4 font-medium ">
                  <div className="flex flex-col items-center justify-center ">
                    <span>Action</span>
                    <span className="text-[9px]">(View/Edit/Delete)</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {userSubAccounts?.map((data, index) => (
                <tr
                  key={data?.login}
                  className="h-[60px] bg-black z-0 border-b border-gray-800 font-normal text-white hover:bg-black/90"
                >
                  {/* <td className="px-4 py-4">{data.account_id}</td> */}
                  <td className=" px-4 py-4 border-r border-gray-900">
                    <div
                      className={`${
                        data?.account_name
                          ? "animate-none"
                          : "animate-pulse bg-gray-900 rounded-md  h-[30px]  "
                      }`}
                    >
                      {data?.account_name}
                    </div>
                  </td>
                  <td className="px-4 py-4 border-r border-gray-900">
                    <div
                      className={`${
                        data?.login
                          ? "animate-none"
                          : "animate-pulse bg-gray-900 rounded-md  h-[30px]  "
                      }`}
                    >
                      {data?.login}
                    </div>
                  </td>
                  <td className="px-4 py-4 border-r border-gray-900">
                    <div
                      className={`${
                        data?.server
                          ? "animate-none"
                          : "animate-pulse bg-gray-900 rounded-md  h-[30px]  "
                      }`}
                    >
                      {data?.server}
                    </div>
                  </td>
                  <td className="px-4 py-4 border-r border-gray-900">
                    <div
                      className={`${
                        data?.balance !== null
                          ? "animate-none"
                          : "animate-pulse bg-gray-900 rounded-md  h-[30px]  "
                      }`}
                    >
                      {data?.balance}
                    </div>
                  </td>
                  <td className="px-4 py-4 border-r border-gray-900">
                    <div
                      className={`${
                        data?.current_balance !== null
                          ? "animate-none"
                          : "animate-pulse bg-gray-900 rounded-md  h-[30px]  "
                      }`}
                    >
                      {data?.current_balance}
                    </div>
                  </td>

                  <td
                    className={`px-4 py-4 border-r border-gray-900  font-medium ${
                      data?.state !== null &&
                      data?.state.toLowerCase() === "deployed"
                        ? " text-[#07F2C7]"
                        : " text-red-500"
                    } py-1 px-2 rounded-md uppercase ${
                      data?.state !== null && data?.state
                        ? "animate-none"
                        : "animate-pulse bg-gray-900 rounded-md  h-[30px]"
                    }`}
                  >
                    {data?.state}
                  </td>

                  <td
                    className={` px-4 py-4 border-r border-gray-900 font-medium
                ${
                  data?.connection_status !== null &&
                  data?.connection_status.toLowerCase() === "connected"
                    ? " text-[#07F2C7]"
                    : data?.connection_status !== null &&
                      data.connection_status.toLowerCase() === "connecting"
                    ? " text-amber-400"
                    : " text-red-500"
                }   px-2 py-1 rounded-md uppercase ${
                      data?.connection_status
                        ? "animate-none"
                        : "animate-pulse bg-gray-900 rounded-md  h-[30px]"
                    }
                `}
                  >
                    <span className="flex items-center">
                      {data?.connection_status &&
                      data?.connection_status.toLowerCase() === "connecting" ? (
                        <>
                          <Loader2 className="text-amber-400 h-4 w-4 animate-spin mr-1" />
                          Connecting
                        </>
                      ) : (
                        data?.connection_status
                      )}
                    </span>
                  </td>

                  <td className={`px-4 py-4 border-r border-gray-900  `}>
                    <span
                      className={` text-[#07F2C7] text-sm   py-2 px-3 rounded-md  ${
                        data?.copy_factory_roles[0] !== null &&
                        data?.copy_factory_roles
                          ? "animate-none  "
                          : " animate-pulse bg-gray-900 rounded-md  h-[30px]"
                      } ${
                        data?.copy_factory_roles[0]?.toLowerCase() ===
                        "provider"
                          ? "bg-[#58A5FF]/30 text-white font-medium  "
                          : data?.copy_factory_roles[0]?.toLowerCase() ===
                            "subscriber"
                          ? "bg-[#738290]/40 text-white font-medium "
                          : "bg-[#738290]/40"
                      }`}
                    >
                      {data?.copy_factory_roles[0]}
                    </span>
                  </td>
                  <td className="border-r border-gray-900 min-w-[100px]">
                    <div className="relative flex items-center justify-evenly ">
                      {(data.connection_status?.toLowerCase() === "connected" ||
                        data.connection_status?.toLowerCase() ===
                          "connecting") && (
                        <div className="group">
                          <span
                            onClick={() =>
                              handleUnDeployServer(index, data.account_id)
                            }
                            className="flex items-center justify-center cursor-pointer h-8 w-8 hover:bg-[#333333] rounded-md group-hover:tooltip"
                          >
                            <PauseIcon className="w-5 h-5 text-white" />
                          </span>
                          <div className="hidden text-xs group-hover:block absolute z-10 -top-10 left-0 bg-[#222222] text-white py-1 px-2 rounded-md">
                            Stop Server
                          </div>
                        </div>
                      )}

                      {data.connection_status?.toLowerCase() ===
                        "disconnected" && (
                        <div className="group">
                          <span
                            onClick={() =>
                              handleReDeployServer(index, data.account_id)
                            }
                            className="flex items-center justify-center cursor-pointer h-8 w-8 hover:bg-[#222222] rounded-md group-hover:tooltip"
                          >
                            <PlayIcon className="w-5 h-5 text-white" />
                          </span>
                          <div className="hidden text-xs group-hover:block absolute z-10 -top-10 left-0 bg-[#222222] text-white py-1 px-2 rounded-md">
                            Restart Sever
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className=" min-w-[160px]">
                    <div className="relative flex items-center justify-evenly ">
                      <div className="group">
                        <span
                          onClick={() => handleView(data)}
                          className="flex items-center justify-center cursor-pointer h-8 w-8 hover:bg-[#333333] rounded-md group-hover:tooltip"
                        >
                          <EyeIcon className="w-5 h-5 text-white" />
                        </span>
                        <div className="hidden text-xs group-hover:block  absolute z-10 -top-10 -left-6 bg-[#222222] text-white p-2 rounded-md">
                          View Details
                        </div>
                      </div>

                      <div className="group">
                        <span
                          onClick={() => handleShowModal(index, data)}
                          className="flex items-center justify-center cursor-pointer h-8 w-8 hover:bg-[#333333] rounded-md group-hover:tooltip"
                        >
                          <UserCogIcon className="w-5 h-5 text-white" />
                        </span>
                        <div className="hidden text-xs group-hover:block absolute z-10 -top-10 left-8 bg-[#222222] text-white p-2 rounded-md ">
                          Update Account
                        </div>
                      </div>

                      <div className="group">
                        <span
                          // onClick={() => handleDelete(index, data.account_id)}
                          onClick={() =>
                            handleShowDeleteAccountModal(index, data.account_id)
                          }
                          className="flex items-center justify-center cursor-pointer h-8 w-8 hover:bg-[#333333] rounded-md group-hover:tooltip"
                        >
                          <Trash2Icon className="w-5 h-5 text-white" />
                        </span>
                        <div className="hidden text-xs group-hover:block absolute z-10 -top-10 right-0 bg-[#222222] text-white p-2 rounded-md ">
                          Delete Account
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* View All  Account Data Modal  */}
          {showAllDataModal && (
            <Modal>
              <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[350px]  h-[500px] px-4 overflow-scroll custom-scroll mt-10 md:mt-0 md:overflow-hidden  md:w-[685px] md:h-[720px] rounded-md md:py-4 md:px-8 ">
                <button
                  onClick={handleCloseDataModal}
                  className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
                >
                  <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
                </button>

                <div className="flex flex-col justify-center  w-full h-full md:w-[510px] md:h-full">
                  <pre className="text-[9px] md:text-sm text-gray-200">
                    {JSON.stringify(ReduceObject(showAllData), null, 2)}
                  </pre>
                </div>
              </div>
            </Modal>
          )}
          {/* Update Account Modal  */}
          {showModal && (
            <UpdateAccountForm
              userId={userId}
              index={formData?.index}
              accountData={formData?.accountData}
              showModal={showModal}
              handleCloseModal={handleCloseModal}
              handleReDeployServer={handleReDeployServer}
            />
          )}

          {/* Delete Account Modal  */}
          {showDeleteAccountModal && (
            <Modal>
              <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[400px] h-[270px] md:w-[620px] md:h-[250px] rounded-md  px-8">
                <button
                  onClick={handleCloseDeleteAccountModal}
                  className="absolute top-4 right-2 p-1 md:top-6 md:right-6 rounded-md md:p-2"
                >
                  <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
                </button>

                <div className="flex flex-col justify-center w-full md:w-[510px] h-full">
                  <div className="flex  flex-col space-y-10 md:space-y-6 ">
                    <div className="flex flex-col space-y-1">
                      <h3 className="text-white text-lg font-semibold">
                        Are you absolutely sure?
                      </h3>
                      <p className="text-[#858E9DB2] text-sm font-normal">
                        This action cannot be undone. This will permanently
                        delete your account and remove all Trade and Order
                        History associated with this account.
                      </p>
                    </div>
                    <div className="flex flex-row space-x-6 ">
                      <button
                        onClick={handleDeleteAccount}
                        type="button"
                        className={` w-full flex disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-rose-600 px-6 py-3 text-base font-semibold leading-6 text-rose-100 shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                      >
                        Delete
                      </button>
                      <button
                        onClick={handleCloseDeleteAccountModal}
                        type="button"
                        className={` flex w-full cursor-pointer justify-center rounded-md bg-transparent px-6 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>

        {userSubAccounts.length > 10 && (
          <div className="flex mb-4 md:mb-0 lg:mb-0  mt-3 md:mt-4 items-center justify-center lg:justify-end  px-10 ">
            <div className="flex items-center  space-x-3 md:space-x-6 justify-center lg:justify-end w-full">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className=" group disabled:text-white/30  disabled:cursor-not-allowed tracking-widest flex items-center justify-center px-2 py-1  text-sm font-medium text-white rounded-sm "
              >
                <MoveLeftIcon className="h-4 w-4 text-white group-disabled:text-white/30  mr-2" />
                Prev
              </button>

              <div className="flex  space-x-3 md:space-x-4 items-center justify-center ">
                {displayPageNumbers()}
              </div>

              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="group disabled:text-white/30 disabled:cursor-not-allowed tracking-widest flex items-center justify-center px-2 py-1  text-sm font-medium text-white rounded-sm "
              >
                Next
                <MoveRightIcon className="h-4 w-4 text-white group-disabled:text-white/30  ml-2" />
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
