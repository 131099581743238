import React, { useState } from "react";
import DashboardNav from "../components/dashboard-nav";
import Logo from "../components/logo";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";
import { Toaster } from "react-hot-toast";
import { MenuIcon } from "lucide-react";

import DashboardLive from "./Live/DashboardLive";
import MobileNavBar from "../components/mobile-nav";
export default function DashboardLayout({ children }) {
  const [user] = useAuthState(auth);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  const email = user && user.email;
  // console.log("user", email);

  return (
    <div className="relative flex flex-col lg:grid lg:grid-cols-16 min-h-screen w-full">
      {/* Dashboard Side Bar  */}
      <div className="sticky top-0 hidden lg:flex col-span-2 h-screen bg-black  ">
        <aside className=" max-h-screen  w-full flex-col lg:flex space-y-4 py-4 bg-black border-r-[1px] border-[#454646]/40">
          <div className=" px-4 w-full">
            <Logo />
          </div>
          <DashboardNav />
        </aside>
      </div>
      <div className="sticky top-0 z-50 lg:hidden flex justify-between h-[60px] md:h-[90px]  bg-black border-b-[1px] border-[#454646]/60 px-4 ">
        <div className=" ">
          <Logo />
        </div>
        <div
          onClick={toggleMobileNav}
          className=" flex items-center lg:hidden text-white  cursor-pointer"
        >
          <MenuIcon className="h-10 w-10 text-white" />
        </div>
        {isMobileNavOpen && (
          <MobileNavBar isOpen={isMobileNavOpen} onClose={toggleMobileNav} />
        )}
      </div>

      {/* Main Dashboard  */}
      <main className=" lg:col-span-14 flex flex-1 lg:flex  bg-black overflow-hidden  transition duration-500 ease-in">
        {children}
      </main>
      <Toaster position="bottom-right" reverseOrder={true} />
    </div>
  );
}
