export const LIVE_URL = "https://forex.server.sypto.xyz";
export const DEV_URL = "http://localhost:8000";

export async function getAllUserAccounts() {
  const res = await fetch(`${LIVE_URL}/api/get-user-account`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getUserAccountByEmail(email) {
  const res = await fetch(`${LIVE_URL}/api/get-user-account/${email}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getAllUserSubAccountByUserId(userId) {
  const res = await fetch(`${LIVE_URL}/api/get-sub-account/${userId}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getAllUserSubAccountBySubscriberId(subscriberId) {
  const res = await fetch(
    `${LIVE_URL}/api/get-sub-account-by-subscriberid/${subscriberId}`,
    {
      method: "GET",
      cache: "no-store",
    }
  );

  const data = await res.json();
  return data;
}

export async function getUserSubAccountByAccountId(accountId) {
  const res = await fetch(
    `${LIVE_URL}/api/get-sub-account-by-accountid/${accountId}`,
    {
      method: "GET",
      cache: "no-store",
    }
  );

  const data = await res.json();
  return data;
}

export async function getAllUserOrderHistory(userId) {
  const res = await fetch(`${LIVE_URL}/api/get-order-history/${userId}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getUserOrderHistoryByAccountId(accountId) {
  const res = await fetch(
    `${LIVE_URL}/api/get-order-history-by-accountid/${accountId}`,
    {
      method: "GET",
      cache: "no-store",
    }
  );

  const data = await res.json();
  return data;
}



export async function getAllUserTradeHistory(userId) {
  const res = await fetch(`${LIVE_URL}/api/get-trade-history/${userId}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getUserTradeHistoryByAccountId(accountId) {
  const res = await fetch(
    `${LIVE_URL}/api/get-trade-history-by-accountid/${accountId}`,
    {
      method: "GET",
      cache: "no-store",
    }
  );

  const data = await res.json();
  return data;
}

export async function getUserBalanceTableByAccountId(accountId) {
  const res = await fetch(
    `${LIVE_URL}/api/get-user-account-balance/${accountId}`,
    {
      method: "GET",
      cache: "no-store",
    }
  );

  const data = await res.json();
  return data;
}

export async function getAllUsersTradeStrategy() {
  const res = await fetch(`${LIVE_URL}/api/get-trade-strategy`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getTradeStrategyByUserId(userId) {
  const res = await fetch(`${LIVE_URL}/api/get-trade-strategy/${userId}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getAllUserLivePositions(userId) {
  const res = await fetch(`${LIVE_URL}/api/get-DEV-positions/${userId}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function getAllUserOpenOrders(userId) {
  const res = await fetch(`${LIVE_URL}/api/get-open-orders/${userId}`, {
    method: "GET",
    cache: "no-store",
  });

  const data = await res.json();
  return data;
}

export async function fetchTradeSymbols(accountId) {
  const res = await fetch(`${LIVE_URL}/api/fetch-trade-symbols/${accountId}`, {
    method: "GET",
    cache: "no-store",
  });
  const symbolsData = await res.json();

  if (symbolsData.status === 200) {
    return symbolsData?.data;
  } else {
    return [];
  }
}
