import React, { useState } from "react";
import { XIcon, CheckIcon } from "lucide-react";
import { filterOrderHistory } from "../../utils/filterFunctions";

export default function FilterOrderHistoryModal({
  showFilter,
  handleCloseFilter,
  currentTab,
  filteredUserOrdersHistory,
  userOriginalOrdersHistory,
  setFilteredUserOrderHistory,
}) {
  const [orderHistoryFilters, setOrderHistoryFilters] = useState({
    account_name: "",
    strategy_name:"",
    symbol: "",
    type: "",
    state: "",
    open_price: "",
    volume: "",
    reason: "",
    order_id: "",
    time: "",
  });

  const handleInputChange = (e, filterKey) => {
    setOrderHistoryFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: e.target.value,
    }));
  };

  const handleApplyOrdersFilters = () => {
    const filtersToApply = {};

    for (const [key, value] of Object.entries(orderHistoryFilters)) {
      if (value !== null && value !== "") {
        filtersToApply[key] = value;
      }
    }

    filterOrderHistory(
      filtersToApply,
      currentTab,
      userOriginalOrdersHistory,
      setFilteredUserOrderHistory
    );
  };

  return (
    <tr
      className={` h-[70px]  bg-[#07F2C7]/20 border-gray-800 
         ${
           showFilter
             ? "animate-slideDown transition duration-500 ease-in "
             : "animate-slideUp transition duration-500 ease-in"
         } `}
    >
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Account Name"
          value={orderHistoryFilters.account_name}
          className=" py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "account_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Strategy Name"
          value={orderHistoryFilters.strategy_name}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "strategy_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Symbol"
          value={orderHistoryFilters.symbol}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "symbol")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Order Type"
          value={orderHistoryFilters.type}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "type")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Order State"
          value={orderHistoryFilters.state}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "state")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Open Price"
          value={orderHistoryFilters.open_price}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "open_price")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Volume"
          value={orderHistoryFilters.volume}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "volume")}
        />
      </th>
      {/* <th className=" px-1  ">
        <input
          type="text"
          placeholder="Reason"
          value={orderHistoryFilters.reason}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "reason")}
        />
      </th> */}
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Order Id"
          value={orderHistoryFilters.order_id}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "order_id")}
        />
      </th>

      <th className=" px-1  ">
        <span className="flex items-center space-x-4">
          <input
            type="date"
            placeholder="Time"
            value={orderHistoryFilters.time}
            className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
            onChange={(e) => handleInputChange(e, "time")}
          />
          <span
            onClick={handleApplyOrdersFilters}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
            <CheckIcon className="h-5 w-5 text-[#07F2C7] " />
          </span>
          <span
            onClick={handleCloseFilter}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
            <XIcon className="h-5 w-5 text-rose-500 " />
          </span>
        </span>
      </th>
    </tr>
  );
}
