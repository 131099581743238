import React, { useState } from "react";
import { DEV_URL, LIVE_URL } from "../../utils/data";
import {
  AlignHorizontalDistributeCenterIcon,
  CopyIcon,
  FileEditIcon,
  TrashIcon,
  XIcon,
} from "lucide-react";

import Modal from "../../components/modal";
import toast from "react-hot-toast";

export default function StrategyCard({
  index,
  providerAccountId,
  strategyCreatedByAccountId,
  strategyId,
  strategyName,
  strategyDescription,
  isStrategyDataChanged,
  setIsStrategyDataChanged,
}) {
  const [showUpdateStrategyModal, setShowUpdateStrategyModal] = useState(false);
  const [showDeleteStrategyModal, setShowDeleteStrategyModal] = useState(false);
  const [formData, setFormData] = useState({
    strategyName: strategyName,
    strategyDescription: strategyDescription,
    providerAccountId: strategyCreatedByAccountId,
  });

  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberId, setSubscriberId] = useState("");
  const [showSubscriberModal, setShowSubscriberModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShowSubscriberModal = () => {
    setShowSubscriberModal(true);
  };
  const handleCloseSubscriberModal = () => {
    setShowSubscriberModal(false);
  };

  const handleOpenUpdateModal = () => {
    setShowUpdateStrategyModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateStrategyModal(false);
  };

  const handleOpenDeleteModal = () => {
    setShowDeleteStrategyModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteStrategyModal(false);
  };

  const handleUpdateStrategy = async (e, strategyId) => {
    e.preventDefault();

    if (
      !formData.strategyDescription ||
      !formData.strategyName ||
      !formData.providerAccountId
    ) {
      toast.error("Please Provide Complete Details", {
        duration: 4000,
      });
      return;
    }

    const strategyData = {
      strategyId: strategyId,
      strategyName: formData.strategyName,
      strategyDescription: formData.strategyDescription,
      accountId: formData.providerAccountId,
    };
    // console.log("Strategy Data ----> ", strategyData);

    const res = await fetch(`${LIVE_URL}/api/update-strategy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(strategyData),
    });

    const data = await res.json();

    if (data) {
      toast.success("Strategy  Updated !!", {
        duration: 4000,
      });
      // console.log("Strategy Updated Successfully!!!", data);
    }

    handleCloseUpdateModal();
    setIsStrategyDataChanged(!isStrategyDataChanged);

    // console.log("Strategy Updated !!!", strategyId);
  };

  const handleDeleteStrategy = async (strategyId) => {
    const deleteData = {
      strategyId: strategyId,
    };

    const res = await fetch(`${LIVE_URL}/api/delete-strategy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(deleteData),
    });

    const data = await res.json();

    // console.log("Strategy Deleted Successfully!!!", data);

    if (data.status === 200) {
      toast.success("Strategy  Deleted Successfully !!", {
        duration: 4000,
      });
      // console.log("Strategy Deleted Successfully!!!", data);
      // console.log("Strategy Deleted !!!", strategyId);
    } else {
      toast.error("Strategy  Deleted Failed !!", {
        duration: 4000,
      });
    }

    handleCloseDeleteModal();
    setIsStrategyDataChanged(!isStrategyDataChanged);
  };

  const handleSubscribeStrategy = async (strategyId) => {
    const subId = subscriberId;

    console.log("sub Id", subId);
    console.log("Strategy  Id", strategyId);

    const formRequestBody = {
      name: subscriberName,
      subscriptions: [
        {
          strategyId: strategyId,
          multiplier: 1,
          tradeSizeScaling: {
            mode: "none",
          },
        },
      ],
    };

    console.log(formRequestBody);

    const res = await fetch(
      `${LIVE_URL}/api/subscribe-strategy/${subscriberId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBody),
      }
    );
    const data = await res.json();

    if (data?.status === 200) {
      console.log("Strategy Subscribed  Successfully!!!", data);
      toast.success("Strategy Subscribed Successfully !!", {
        style: {
          minWidth: "250px",
        },
        duration: 5000,
      });
    } else {
      console.log("Strategy Subscribed  Failed!!!", data);
      toast.success("Strategy Subscription Failed !!", {
        style: {
          minWidth: "250px",
        },
        duration: 5000,
      });
    }

    setSubscriberId("");
    setSubscriberName("");
    handleCloseSubscriberModal();
    setIsStrategyDataChanged(!isStrategyDataChanged);
  };

  return (
    <>
      {showUpdateStrategyModal && (
        <Modal>
          <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[400px] h-[500px] md:w-[785px] md:h-[500px] rounded-md py-4 px-8">
            <button
              onClick={handleCloseUpdateModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>
            <div className="flex flex-col justify-center  space-y-8 w-full md:w-[510px] h-full">
              <h2 className="text-center text-xl md:text-2xl font-medium  tracking-tight text-white">
                Update Strategy
              </h2>
              <form
                onSubmit={(e) => handleUpdateStrategy(e, strategyId)}
                className="flex  flex-col md:space-y-12 "
              >
                <div className="grid gap-3 md:gap-6">
                  <div className="flex flex-col space-y-1 md:grid md:grid-cols-3 md:gap-2">
                    <label
                      className="flex items-center label md:cols-span-1 text-white"
                      htmlFor="strategyName"
                    >
                      Strategy Name
                    </label>
                    <input
                      required
                      id="strategyName"
                      name="strategyName"
                      placeholder="Strategy Name"
                      type="text"
                      value={formData.strategyName}
                      onChange={handleChange}
                      className="py-3 px-3 text-sm placeholder:text-gray-600  md:col-span-2 bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    />
                  </div>

                  <div className="flex flex-col space-y-1 md:grid md:grid-cols-3 md:gap-2">
                    <label
                      className="flex items-center label md:cols-span-1 text-white"
                      htmlFor="strategyDescription"
                    >
                      Strategy Description
                    </label>
                    <input
                      required
                      id="strategyDescription"
                      name="strategyDescription"
                      placeholder="Strategy Description"
                      type="text"
                      value={formData.strategyDescription}
                      onChange={handleChange}
                      className="py-3 px-3 text-sm placeholder:text-gray-600  md:col-span-2 bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    />
                  </div>
                  <div className="flex flex-col space-y-1 md:grid md:grid-cols-3 md:gap-2">
                    <label
                      className="flex items-center label md:cols-span-1 text-white"
                      htmlFor="providerAccountId"
                    >
                      Provider Account Id
                    </label>
                    <input
                      required
                      id="providerAccountId"
                      name="providerAccountId"
                      placeholder="Provider Account Id"
                      type="text"
                      value={formData.providerAccountId}
                      onChange={handleChange}
                      className="py-3 px-3 text-sm placeholder:text-gray-600  md:col-span-2 bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                    />
                  </div>

                  <div className="mt-8 flex flex-row   justify-between space-x-4">
                    <button
                      disabled={
                        !formData.strategyName ||
                        !formData.strategyDescription ||
                        !formData.providerAccountId
                      }
                      type="submit"
                      className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-medium leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                    >
                      Update 
                    </button>

                    <div
                      onClick={handleCloseUpdateModal}
                      className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      )}

      {showDeleteStrategyModal && (
        <Modal>
          <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[400px] h-[250px] md:w-[620px] md:h-[320px] rounded-md  px-8">
            <button
              onClick={handleCloseDeleteModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-6 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>

            <div className="flex flex-col justify-center w-full md:w-[510px] h-full">
              <div className="flex  flex-col space-y-10 md:space-y-10 ">
                <div className="flex flex-col space-y-4">
                  <h3 className="text-white text-3xl font-semibold">
                    Are you absolutely sure?
                  </h3>
                  <p className="text-white/60 text-base font-normal">
                    This action cannot be undone. This will permanently delete
                    your strategy and remove all subscribers.
                  </p>
                </div>
                <div className="flex flex-row space-x-6 ">
                  <button
                    onClick={() => handleDeleteStrategy(strategyId)}
                    type="button"
                    className={` w-full flex disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-rose-600 px-6 py-3 text-base md:text-xl font-semibold leading-6 text-rose-100 shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Delete 
                  </button>
                  <button
                    onClick={handleCloseDeleteModal}
                    type="button"
                    className={` flex w-full cursor-pointer justify-center rounded-md bg-transparent px-6 py-3 text-base md:text-xl font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {/* For Subscribe Strategy */}

      {/* {showSubscriberModal && (
        <Modal>
          <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[620px] h-[380px] rounded-md  px-8">
            <button
              onClick={handleCloseSubscriberModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>
            <div className="flex flex-col justify-center  space-y-8 w-[510px] h-full">
              <div className="grid gap-6">
                <div className="grid grid-cols-1 gap-3">
                  <label
                    className="flex items-center label  text-white"
                    htmlFor="subscriberName"
                  >
                    Subscriber Name
                  </label>
                  <input
                    required
                    id="subscriberName"
                    name="subscriberName"
                    placeholder="Subscriber Name"
                    type="text"
                    value={subscriberName}
                    onChange={(e) => setSubscriberName(e.target.value)}
                    className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  />
                </div>
                <div className="grid grid-cols-1 gap-3">
                  <label
                    className="flex items-center label  text-white"
                    htmlFor="accountId"
                  >
                    Subscriber AccountId
                  </label>
                  <input
                    required
                    id="accountId"
                    name="accountId"
                    placeholder="Subscriber AccountId"
                    type="text"
                    value={subscriberId}
                    onChange={(e) => setSubscriberId(e.target.value)}
                    className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <button
                    disabled={!subscriberId}
                    onClick={() => handleSubscribeStrategy(strategyId)}
                    className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Subscribe Strategy
                  </button>

                  <div
                    onClick={handleCloseSubscriberModal}
                    className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )} */}

      <div className={`relative bg-mesh bg-no-repeat group rounded-md  flex flex-col min-h-[160px] lg:min-h-[160px]  justify-center   bg-[#0F0F0F] shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:shadow-[0_3px_10px_rgb(7,242,199,0.3)] animate-slideInFromBottom transition duration-300 ease-in `}>
        <div className="flex flex-col  space-y-4">
          <div className="flex items-center border-l-4 border-transparent group-hover:border-[#07F2C7] transition duration-300 ease-in">
            <p className="text-[26px] font-semibold capitalize text-transparent bg-clip-text bg-gradient-to-b from-white to-white/20 px-6">
              {strategyName}
            </p>
          </div>

          <div className="flex  ">
            <p className="text-md font-medium capitalize text-[#858E9D] px-8">
              {strategyDescription}
            </p>
          </div>

          {/* <div className="px-8">
            <button
              onClick={handleShowSubscriberModal}
              className={`mt-2 flex cursor-pointer justify-center rounded-md  px-6 py-2 text-lg font-medium leading-6 text-[#07F2C7]/80 border-2 border-[#07F2C7]/80 group-hover:text-black shadow-sm  bg-transparent group-hover:bg-[#07F2C7] `}
            >
              Subscribe Now
            </button>
          </div> */}

          {/* Edit Delete Button  */}
          <div className="absolute top-2 right-2  flex  space-x-2">
            <button onClick={handleOpenUpdateModal} className="cursor-pointer">
              <FileEditIcon className="h-5 w-5  text-[#858E9DB2] hover:text-[#07F2C7]" />
            </button>
            <button onClick={handleOpenDeleteModal} className="cursor-pointer">
              <TrashIcon className="h-5 w-5  text-[#858E9DB2] hover:text-[#07F2C7]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

{
  /* <div className="w-full sm:w-1/2 lg:w-1/3 h-[200px] px-4 mb-4 ">
      <div className="bg-gray-200 w-full h-full rounded-md p-2"></div>
</div> */
}
