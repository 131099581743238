import React, { useState, useEffect } from "react";
import Header from "../Header/Header";

import axios from "axios";
import { AiOutlineFilter } from "react-icons/ai";
import { CalendarIcon, XIcon } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";
import io from "socket.io-client";
import { auth } from "../../Login/firebase";
import PortfolioPlot from "./PortfolioPlot";
import {
  getUserAccountByEmail,
  getUserTradeHistoryByAccountId,
  getUserOrderHistoryByAccountId,
  getAllUserSubAccountBySubscriberId,
  getUserBalanceTableByAccountId,
} from "../../utils/data";
import SingleSubscriberForm from "../SingleSubscriberForm";

import ShowTradeHistoryData from "./ShowTradeHIstoryData";

import LoadingSpinner from "../../components/loading-spinner";
import ShowPnlDataComponent from "./ShowPnlDataComponent";
import Modal from "../../components/modal";
import ShowPnlDataMobileComponent from "./ShowPnlDataMoblieComponent";

const LIVE_URL = "https://forex.websocket.sypto.xyz";
const DEV_URL = "http://localhost:3001";

const openOrderData = [
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
  {
    coin: "AUDUSD",
    quantity: "39%",
    amount: "2300",
  },
];
const style = {
  HeadingSubSmall: "text-xs md:text-xl font-bold leading-4 ml-2  mt-3 md:mt-4",
};
const UserPortfolio = () => {
  const [user] = useAuthState(auth);

  const email = user && user.email;
  const [tradeHistoryData, setTradeHistoryData] = useState();

  const allTradeHistoryData = async () => {
    try {
      const response = await axios.get(
        `https://server.sypto.xyz/dashboard/get_trades/pkucheria@gmail.com/binance/Athena Top 5`
      );
      if (response) {
        setTradeHistoryData(response.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [userAccountsData, setUserAccountsData] = useState();
  const [userSubAcconnts, setUserSubAccounts] = useState([]);

  const [userId, setAdminUserId] = useState("");
  const [userSubscriberId, setUserSubscriberId] = useState("");
  const [userSubAccountId, setUserSubAccountId] = useState("");
  const [userLivePositionData, setUserLivePositionsData] = useState([]);
  const [userOpenOrdersData, setUserOpenOrdersData] = useState([]);
  const [filteredUserLivePositionData, setFilteredUserLivePositionData] =
    useState(userLivePositionData);
  const [filteredUserOpenOrdersData, setFilteredUserOpenOrdersData] =
    useState(userOpenOrdersData);
  const [userTradeHistory, setUserTradeHistory] = useState([]);
  const [userOrdersHistory, setUserOrderHistory] = useState([]);
  const [filteredUserTradeHistory, setFilteredUserTradeHistory] =
    useState(userTradeHistory);
  const [filteredUserOrdersHistory, setFilteredUserOrderHistory] =
    useState(userOrdersHistory);
  const [sortOrder, setSortOrder] = useState("asc");
  const [isHistoryDataLoading, setIsHistoryDataLoading] = useState(false);
  const [isPositionsDataLoading,setIsPositionsDataLoading] = useState(false)
  const [balanceSheet, setBalanceSheet] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPnlTabs, setShowPnlTabs] = useState("30days");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCustomPnlModal, setShowCustomPnlModal] = useState(false);

  const handleShowCustomPnlModal = () => {
    setShowPnlTabs("custom");
    setShowCustomPnlModal(true);
  };
  const handleCloseCustomPnlModal = () => {
    setShowCustomPnlModal(false);
  };

  useEffect(() => {
    setIsHistoryDataLoading(true);
    setIsPositionsDataLoading(true);
    const fetchUserData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      setUserAccountsData(userAccountData?.data);
      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";

      setUserSubscriberId(id);

      const userSubAccountData = await getAllUserSubAccountBySubscriberId(id);
      setUserSubAccounts(userSubAccountData?.data);

      const subscriberAccId = userSubAccountData?.data[0]
        ? userSubAccountData?.data[0]?.account_id
        : "";

      setUserSubAccountId(subscriberAccId);

      const adminId = userSubAccountData?.data[0]
        ? userSubAccountData?.data[0]?.user_id
        : "";

      setAdminUserId(adminId)

      const userTradeHistoryData = await getUserTradeHistoryByAccountId(
        subscriberAccId
      );

      setUserTradeHistory(userTradeHistoryData?.data);
      setFilteredUserTradeHistory(userTradeHistoryData?.data);

      const userOrdersHistoryData = await getUserOrderHistoryByAccountId(
        subscriberAccId
      );
      setUserOrderHistory(userOrdersHistoryData?.data);
      setFilteredUserOrderHistory(userOrdersHistoryData?.data);

      const userBalanceSheet = await getUserBalanceTableByAccountId(
        subscriberAccId
      );
      setBalanceSheet(userBalanceSheet?.data);
      setIsHistoryDataLoading(false)
    };

    fetchUserData();
    fecthLiveDataFromWebSocket();
  }, [userId]);

  const fecthLiveDataFromWebSocket = () => {
    const socket = io(`${LIVE_URL}`);

    // Event listener for when the connection is established
    socket.on("connect", () => {
      console.log("Connected to WebSocket server");

      // Emit an event with user information (name and age)
      socket.emit("sendUserInfo", { userId });
    });

    // Event listener for Live Positions data is received from the server
    socket.on("fetchLivePositions", (positionsData) => {
      console.log("Received positionsData update:", positionsData);

      const filteredLivePosition = positionsData && positionsData.filter(
        (item) => item.account_id === userSubAccountId
      );

      if(filteredLivePosition?.length > 0){
        setUserLivePositionsData(filteredLivePosition?.reverse());
        setFilteredUserLivePositionData(filteredLivePosition?.reverse());
      }
      setIsPositionsDataLoading(false)
    });

    // Event listener for Open Orders data is received from the server
    socket.on("fetchOpenOrders", (openOrdersData) => {
      console.log("Received Open Orders update:", openOrdersData);
      const filteredOpenOrders = openOrdersData && openOrdersData.filter(
        (item) => item.account_id === userSubAccountId
      );

      if(filteredOpenOrders?.length > 0){
        setUserOpenOrdersData(filteredOpenOrders?.reverse());
        setFilteredUserOpenOrdersData(filteredOpenOrders?.reverse());
      }
     
    });
  };

  useEffect(() => {
    allTradeHistoryData();
  }, []);

  const handleFilterByDays = (currentTab, days, startDate, endDate) => {
    if (currentTab === "positions") {
      if (days === 0) {
        setFilteredUserLivePositionData(userLivePositionData);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredData = userLivePositionData.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        console.log("filtered data -1", filteredData);

        setFilteredUserLivePositionData(filteredData);

        return;
      } else {
        let filteredPositionsData = userLivePositionData;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredPositionsData = filteredPositionsData.filter(
          (trade) => new Date(trade?.time) >= fromDate
        );

        setFilteredUserLivePositionData(filteredPositionsData);
      }
    } else if (currentTab === "open-orders") {
      if (days === 0) {
        setFilteredUserOpenOrdersData(userOpenOrdersData);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredOpenOrdersData = userOpenOrdersData.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        setFilteredUserOpenOrdersData(filteredOpenOrdersData);

        return;
      } else {
        let filteredOpenOrderData = userOpenOrdersData;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredOpenOrderData = filteredOpenOrderData.filter(
          (order) => new Date(order?.time) >= fromDate
        );

        setFilteredUserOpenOrdersData(filteredOpenOrderData);
      }
    } else if (currentTab === "trades-history") {
      if (days === 0) {
        setFilteredUserTradeHistory(userTradeHistory);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredData = userTradeHistory.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        console.log("filtered data -1", filteredData);

        setFilteredUserTradeHistory(filteredData.reverse());

        return;
      } else {
        let filteredTradeData = userTradeHistory;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredTradeData = filteredTradeData.filter(
          (trade) => new Date(trade?.time) >= fromDate
        );

        setFilteredUserTradeHistory(filteredTradeData.reverse());

        console.log("Time Trade History");
      }
    } else if (currentTab === "orders-history") {
      if (days === 0) {
        setFilteredUserOrderHistory(userOrdersHistory);
        return;
      }

      if (days === -1) {
        const stDate = new Date(startDate);
        const enDate = new Date(endDate);

        // Filter data based on date range
        const filteredOrdersHistoryData = userOrdersHistory.filter((item) => {
          const itemDate = new Date(item.time);
          return stDate <= itemDate && itemDate <= enDate;
        });

        setFilteredUserOrderHistory(filteredOrdersHistoryData.reverse());

        return;
      } else {
        let filteredOrderData = userOrdersHistory;

        const currentDate = new Date();
        const fromDate = new Date(currentDate);
        fromDate.setDate(currentDate.getDate() - days);

        filteredOrderData = filteredOrderData.filter(
          (trade) => new Date(trade?.time) >= fromDate
        );

        setFilteredUserOrderHistory(filteredOrderData.reverse());
        console.log("Time Orders History");
      }
    }

    // closeFilterDroprDownModal();
  };

  const handleResetFilterData = (currentTab) => {
    if (currentTab === "positions") {
      setFilteredUserLivePositionData(userLivePositionData);
      return;
    } else if (currentTab === "open-orders") {
      setFilteredUserOpenOrdersData(userOpenOrdersData);
      return;
    } else if (currentTab === "trades-history") {
      setFilteredUserTradeHistory(userTradeHistory);
      return;
    } else if (currentTab === "orders-history") {
      setFilteredUserOrderHistory(userOrdersHistory);
      return;
    }
    // closeFilterDroprDownModal();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const totalTrades =
    userTradeHistory &&
    userTradeHistory.filter((item) => item.entry_type === "DEAL_ENTRY_OUT");

  function filterYesterdayPnlData() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 7 days ago
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1);

    // Use filter to get objects within the last 7 days
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= oneDayAgo && itemDate <= currentDate;
    });

    return filteredData;
  }

  function filterLast15DaysPnlData() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 7 days ago
    const fifteenDaysAgo = new Date(currentDate);
    fifteenDaysAgo.setDate(currentDate.getDate() - 15);

    // Use filter to get objects within the last 7 days
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= fifteenDaysAgo && itemDate <= currentDate;
    });

    return filteredData;
  }

  function filterLast30DaysPnlData() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 7 days ago
    const thirtyDayAgo = new Date(currentDate);
    thirtyDayAgo.setDate(currentDate.getDate() - 30);

    // Use filter to get objects within the last 7 days
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= thirtyDayAgo && itemDate <= currentDate;
    });

    return filteredData;
  }

  function filterLast3MonthsPnlData() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 3 months ago
    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

    // Use filter to get objects within the last 3 months
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= threeMonthsAgo && itemDate <= currentDate;
    });

    return filteredData;
  }

  function filterLast6MonthsPnlData() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 6 months ago
    const sixMonthsAgo = new Date(currentDate);
    sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

    // Use filter to get objects within the last 6 months
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= sixMonthsAgo && itemDate <= currentDate;
    });

    return filteredData;
  }

  const yesterdayPnlData = filterYesterdayPnlData();
  const last30DaysPnlData = filterLast30DaysPnlData();
  const last15DaysPnlData = filterLast15DaysPnlData();
  const last3MonthsPnlData = filterLast3MonthsPnlData();
  const last6MonthsPnlData = filterLast6MonthsPnlData();
  const [customRangePnlData, setCustomRangePnlData] = useState();

  const handleFilterCustomDateRangePnlData = (startDate, endDate) => {
    console.log("Applying Filter");

    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    // Use filter to get objects within the custom date range
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= startDateObject && itemDate <= endDateObject;
    });

    setCustomRangePnlData(filteredData);
    setStartDate("");
    setEndDate("");
    setShowCustomPnlModal(false);
  };

  const handleResetCustomPnlData = () => {
    setCustomRangePnlData();
    setShowCustomPnlModal(false);
  };

  // yesterday pnl & pnl percentage

  const yesterdayPnl = yesterdayPnlData.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.profit;
  }, 0);

  const yesterdayTotalVolume = yesterdayPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.valume;
    },
    0
  );

  const yesterdayTotalProfitPercentage =
    (yesterdayPnl / yesterdayTotalVolume) * 100 || 0;

  // 15 days pnl and percentage

  const lastFifteenDaysPnl = last15DaysPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    },
    0
  );

  const totalVolume15Days = last15DaysPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.volume;
    },
    0
  );
  const profitPercentageFifteenDays =
    (lastFifteenDaysPnl / totalVolume15Days) * 100 || 0;

  // 30 days pnl and percentage

  const lastThirtyDaysPnl = last30DaysPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    },
    0
  );

  const totalVolume30Days = last30DaysPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.volume;
    },
    0
  );
  const overallProfitPercentage =
    (lastThirtyDaysPnl / totalVolume30Days) * 100 || 0;

  // Last 3 Months pnl and percentage

  const lastThreeMonthsPnl = last3MonthsPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    },
    0
  );

  const totalVolume3Months = last3MonthsPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.volume;
    },
    0
  );
  const profitPercentageThreeMonths =
    (lastThreeMonthsPnl / totalVolume3Months) * 100 || 0;

  // Last 6 Months pnl and percentage

  const lastSixMonthsPnl = last6MonthsPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    },
    0
  );

  const totalVolume6Months = last6MonthsPnlData.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.volume;
    },
    0
  );
  const profitPercentageSixMonths =
    (lastSixMonthsPnl / totalVolume6Months) * 100 || 0;

  // Custom Days pnl and percentage

  const customPnlData = customRangePnlData?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    },
    0
  );

  const totalVolumeCustomRange = customRangePnlData?.reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.volume;
    },
    0
  );
  const profitPercentageCustom =
    (customPnlData / totalVolumeCustomRange) * 100 || 0;

  return (
    <div className="bg-black">
      <Header />
      {userAccountsData && userAccountsData[0]["subscriber"] === false ? (
        <SingleSubscriberForm />
      ) : (
        <>
          {loading ? (
            <div className="flex flex-col  min-h-screen  mx-auto w-[250px] ml-16 md:ml-28 lg:ml-0 md:w-[600px] lg:w-full  h-[200px] lg:h-[400px] items-center justify-center text-white text-lg ">
              <LoadingSpinner />
            </div>
          ) : (
            <div className="mt-[116px] mb-6">
              <div className="md:flex flex-row">
                <div
                  className={`w-full md:w-[100%] animate-slideInArrowFromTop transition duration-500 ease-in `}
                >
                  <div className="md:flex  hidden flex-row  w-[100%] mx-auto justify-between px-8 lg:px-12">
                    <div className="flex flex-col space-y-1.5  lg:w-1/3">
                      <div className="text-base lg:text-[18px] text-[#ffffff]/80 font-Poppins">
                        Invested Amount
                      </div>
                      <div className="text-2xl lg:text-[34px] text-white font-bold font-Poppins">
                        {userSubAcconnts.length !== 0
                          ? `$ ${userSubAcconnts[0]["current_balance"]}`
                          : "N/A"}
                      </div>
                    </div>

                    <div className="flex flex-col space-y-1.5  lg:w-1/3">
                      <div className="text-base lg:text-[18px] text-[#ffffff]/80 font-Poppins">
                        Yesterday’s PNL
                      </div>

                      {yesterdayPnl && yesterdayPnl > 0 ? (
                        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
                          <span className="text-3xl font-bold text-[#07F2C7]">
                            +$ {yesterdayPnl}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  text-3xl font-bold text-[#07F2C7] `}
                          >
                            {yesterdayTotalProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      ) : yesterdayPnl && yesterdayPnl < 0 ? (
                        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
                          <span className="text-3xl font-bold text-rose-500">
                            -$ {Math.abs(yesterdayPnl)}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  text-3xl font-bold text-rose-500 `}
                          >
                            {yesterdayTotalProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      ) : (
                        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
                          <span className="text-3xl font-bold text-white">
                            0.00
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  text-3xl font-bold text-white `}
                          >
                            0.00%
                          </span>
                        </div>
                      )}
                    </div>

                    {/* New 30 days, 3months, 6 months, 12 Months and Custom PNL For PC/Laptop Screen  */}

                    <div className="flex flex-col space-y-1.5 items-end  lg:w-1/3">
                      <div className="relative flex items-center text-base lg:text-[18px] text-[#ffffff]/80 font-Poppins ">
                        <span
                          onClick={() => setShowPnlTabs("15days")}
                          className={`${
                            showPnlTabs === "15days"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mx-2`}
                        >
                          15D
                        </span>
                        |
                        <span
                          onClick={() => setShowPnlTabs("30days")}
                          className={`${
                            showPnlTabs === "30days"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mx-2`}
                        >
                          30D
                        </span>
                        |
                        <span
                          onClick={() => setShowPnlTabs("3months")}
                          className={`${
                            showPnlTabs === "3months"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mx-2`}
                        >
                          3M
                        </span>
                        |
                        <span
                          onClick={() => setShowPnlTabs("6months")}
                          className={`${
                            showPnlTabs === "6months"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mx-2`}
                        >
                          6M
                        </span>
                        |
                        <span
                          onClick={handleShowCustomPnlModal}
                          className=" flex group mx-2"
                        >
                          <span className="flex items-center justify-center cursor-pointer h-6 w-6 hover:bg-[#222222] rounded-md group-hover:tooltip">
                            <CalendarIcon
                              className={`w-4 h-4 ${
                                showPnlTabs === "custom"
                                  ? "text-[#07F2C7] "
                                  : "text-white"
                              }`}
                            />
                          </span>
                          <div className="hidden text-xs group-hover:block absolute z-10 -top-[28px] right-0 bg-[#222222] text-white py-0.5 px-3 rounded-sm">
                            Custom PNL
                          </div>
                        </span>
                      </div>

                      {/* Lats 15 Days PNL  */}
                      {showPnlTabs === "15days" && (
                        <ShowPnlDataComponent
                          inputPnlValue={lastFifteenDaysPnl}
                          profitPercentage={profitPercentageFifteenDays}
                        />
                      )}

                      {/* Lats 30 Days PNL  */}
                      {showPnlTabs === "30days" && (
                        <ShowPnlDataComponent
                          inputPnlValue={lastThirtyDaysPnl}
                          profitPercentage={overallProfitPercentage}
                        />
                      )}

                      {/* Lats 3 Months PNL  */}
                      {showPnlTabs === "3months" && (
                        <ShowPnlDataComponent
                          inputPnlValue={lastThreeMonthsPnl}
                          profitPercentage={profitPercentageThreeMonths}
                        />
                      )}
                      {/* Lats 3 Months PNL  */}
                      {showPnlTabs === "6months" && (
                        <ShowPnlDataComponent
                          inputPnlValue={lastSixMonthsPnl}
                          profitPercentage={profitPercentageSixMonths}
                        />
                      )}
                      {/* Lats 3 Months PNL  */}
                      {showPnlTabs === "custom" && (
                        <ShowPnlDataComponent
                          inputPnlValue={customPnlData}
                          profitPercentage={profitPercentageCustom}
                        />
                      )}
                    </div>

                    {/* Old 30 days PNL For PC/Laptop Screen  */}

                    {/* <div className="flex flex-col space-y-1.5">
                      <div className="text-base lg:text-[18px] text-[#ffffff]/80 font-Poppins">
                        30 Days PNL
                      </div>
                      {lastThirtyDaysPnl && lastThirtyDaysPnl > 0 ? (
                        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
                          <span className="text-3xl font-bold text-[#07F2C7]">
                            +$ {lastThirtyDaysPnl}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  text-3xl font-bold text-[#07F2C7] `}
                          >
                            {overallProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      ) : lastThirtyDaysPnl && lastThirtyDaysPnl < 0 ? (
                        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
                          <span className="text-3xl font-bold text-rose-500">
                            -$ {Math.abs(lastThirtyDaysPnl)}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  text-3xl font-bold text-rose-500 `}
                          >
                            {overallProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      ) : (
                        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
                          <span className="text-3xl font-bold text-white">
                            0.00
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  text-3xl font-bold text-white `}
                          >
                            0.00%
                          </span>
                        </div>
                      )}
                    </div> */}
                  </div>

                  <div className="md:hidden w-[100%] mx-auto justify-between px-8 py-3 border-[1px] border-[#ffffff]/10">
                    <div className="flex flex-row">
                      <div className="w-[60%] text-2sm text-[#ffffff]/80 font-Poppins">
                        Invested Amount :{" "}
                      </div>
                      <div className="w-[40%] text-2sm text-white font-bold font-Poppins text-right">
                        {userSubAcconnts.length !== 0
                          ? `$ ${userSubAcconnts[0]["current_balance"]}`
                          : "N/A"}
                      </div>
                    </div>

                    {/* <div className="flex flex-row mt-2">
                      <div className="w-[60%] text-2sm text-[#ffffff]/80 font-Poppins">
                        Yesterday’s PNL :{" "}
                      </div>
                      <div className="w-[40%] text-2sm text-white font-bold font-Poppins">
                        {yesterdayPnl > 0 ? "+" : "-"}${yesterdayPnl.toFixed(2)}
                        <span
                          className={`${style.HeadingSubSmall}  text-[#07F2C7] `}
                        >
                          {yesterdayTotalProfitPercentage.toFixed(2)}%
                        </span>
                      </div>
                    </div> */}

                    {yesterdayPnl && yesterdayPnl > 0 ? (
                      <div className="flex flex-row mt-2">
                        <div className="w-[60%] text-sm text-[#ffffff]/80 font-Poppins">
                          Yesterday’s PNL :{" "}
                        </div>
                        <div className="w-[40%] text-sm text-white font-bold font-Poppins text-right">
                          <span className="text-sm font-bold text-[#07F2C7]">
                            +${yesterdayPnl}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  font-bold text-[#07F2C7] `}
                          >
                            {yesterdayTotalProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      </div>
                    ) : yesterdayPnl && yesterdayPnl < 0 ? (
                      <div className="flex flex-row mt-2">
                        <div className="w-[60%] text-sm text-[#ffffff]/80 font-Poppins ">
                          Yesterday’s PNL :{" "}
                        </div>
                        <div className=" w-[40%] text-sm text-white font-bold font-Poppins text-right">
                          <span className="text-sm font-bold text-rose-500">
                            -${Math.abs(yesterdayPnl)}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  font-bold text-rose-500 `}
                          >
                            {yesterdayTotalProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row mt-2">
                        <div className="w-[60%] text-sm text-[#ffffff]/80 font-Poppins">
                          Yesterday’s PNL :{" "}
                        </div>
                        <div className=" w-[40%] text-sm text-white font-bold font-Poppins text-right">
                          <span className="text-sm font-bold text-white">
                            0.00
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}   font-bold text-white `}
                          >
                            0.00%
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="flex  mt-2">
                      <div className="relative w-[35%] flex flex-wrap items-center text-xs  text-[#ffffff]/80 font-Poppins ">
                        <span
                          onClick={() => setShowPnlTabs("15days")}
                          className={`${
                            showPnlTabs === "15days"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mr-1.5`}
                        >
                          15D
                        </span>
                        |
                        <span
                          onClick={() => setShowPnlTabs("30days")}
                          className={`${
                            showPnlTabs === "30days"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mx-1.5`}
                        >
                          30D
                        </span>
                        |
                        <span
                          onClick={() => setShowPnlTabs("3months")}
                          className={`${
                            showPnlTabs === "3months"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mx-1.5`}
                        >
                          3M
                        </span>
                        |
                        <span
                          onClick={() => setShowPnlTabs("6months")}
                          className={`${
                            showPnlTabs === "6months"
                              ? "text-[#07F2C7] font-medium"
                              : "text-[#ffffff]/30"
                          } cursor-pointer mr-1.5`}
                        >
                          6M
                        </span>
                        |
                        <span
                          onClick={handleShowCustomPnlModal}
                          className=" flex group ml-0.5"
                        >
                          <span className="flex items-center justify-center cursor-pointer h-6 w-6 hover:bg-[#222222] rounded-md group-hover:tooltip">
                            <CalendarIcon
                              className={`w-4 h-4 ${
                                showPnlTabs === "custom"
                                  ? "text-[#07F2C7] "
                                  : "text-white"
                              }`}
                            />
                          </span>
                          <div className="hidden text-xs group-hover:block absolute z-10 -top-[28px] right-6 bg-[#222222] text-white py-0.5 px-3 rounded-sm">
                            Custom PNL
                          </div>
                        </span>
                      </div>

                      {/* Lats 15 Days PNL  */}
                      {showPnlTabs === "15days" && (
                        <ShowPnlDataMobileComponent
                          inputPnlValue={lastFifteenDaysPnl}
                          profitPercentage={profitPercentageFifteenDays}
                        />
                      )}

                      {/* Lats 30 Days PNL  */}
                      {showPnlTabs === "30days" && (
                        <ShowPnlDataMobileComponent
                          inputPnlValue={lastThirtyDaysPnl}
                          profitPercentage={overallProfitPercentage}
                        />
                      )}

                      {/* Lats 3 Months PNL  */}
                      {showPnlTabs === "3months" && (
                        <ShowPnlDataMobileComponent
                          inputPnlValue={lastThreeMonthsPnl}
                          profitPercentage={profitPercentageThreeMonths}
                        />
                      )}
                      {/* Lats 3 Months PNL  */}
                      {showPnlTabs === "6months" && (
                        <ShowPnlDataMobileComponent
                          inputPnlValue={lastSixMonthsPnl}
                          profitPercentage={profitPercentageSixMonths}
                        />
                      )}
                      {/* Lats 3 Months PNL  */}
                      {showPnlTabs === "custom" && (
                        <ShowPnlDataMobileComponent
                          inputPnlValue={customPnlData}
                          profitPercentage={profitPercentageCustom}
                        />
                      )}
                    </div>

                    {/* Old 30 days PNL For Mobile Screen  */}

                    {/* {lastThirtyDaysPnl && lastThirtyDaysPnl > 0 ? (
                      <div className="flex flex-row mt-2">
                        <div className="w-[60%] text-sm text-[#ffffff]/80 font-Poppins">
                          30 Days PNL :{" "}
                        </div>
                        <div className="w-[40%] text-sm text-white font-bold font-Poppins">
                          <span className="text-sm font-bold text-[#07F2C7]">
                            +${lastThirtyDaysPnl}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  font-bold text-[#07F2C7] `}
                          >
                            {overallProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      </div>
                    ) : lastThirtyDaysPnl && lastThirtyDaysPnl < 0 ? (
                      <div className="flex flex-row mt-2">
                        <div className="w-[60%] text-sm text-[#ffffff]/80 font-Poppins">
                          30 Days PNL :{" "}
                        </div>
                        <div className=" w-[40%] text-sm text-white font-bold font-Poppins">
                          <span className="text-sm font-bold text-rose-500">
                            -${Math.abs(lastThirtyDaysPnl)}
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}  font-bold text-rose-500 `}
                          >
                            {overallProfitPercentage.toFixed(2)}%
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row mt-2">
                        <div className="w-[60%] text-sm text-[#ffffff]/80 font-Poppins">
                          30 Days PNL :{" "}
                        </div>
                        <div className=" w-[40%] text-sm text-white font-bold font-Poppins">
                          <span className="text-sm font-bold text-white">
                            0.00
                          </span>
                          <span
                            className={`${style.HeadingSubSmall}   font-bold text-white `}
                          >
                            0.00%
                          </span>
                        </div>
                      </div>
                    )} */}
                  </div>
                  <PortfolioPlot
                    userBalanceSheet={balanceSheet && balanceSheet}
                  />
                </div>

                {/* <div className={`w-full md:w-[30%] animate-slideInArrowFromTop transition duration-500 ease-in delay-[2000ms]`}
                >
                  <div className="flex flex-row justify-between px-12 py-5 border-b-[1px] border-[#ffffff]/10">
                    <div className="w-[10%] font-Montserrat text-sm font-semibold text-white ">
                      Symbol
                    </div>
                    <div className="w-[10%] font-Montserrat text-sm font-semibold text-white ">
                      Quantity
                    </div>
                    <div className="w-[10%] font-Montserrat text-sm font-semibold text-white ">
                      Amount
                    </div>
                  </div>
                  {openOrderData
                    ? openOrderData.map((ele, i) => {
                        return (
                          <div
                            key={i}
                            className="flex flex-row justify-between font-Montserrat text-sm font-semibold text-white px-12 py-3 border-b-[1px] border-[#ffffff]/10"
                          >
                            <div className="w-[10%]">{ele.coin}</div>
                            <div className="w-[10%]">{ele.coin}</div>
                            <div className="w-[10%]">{ele.coin}</div>
                          </div>
                        );
                      })
                    : null}
                </div> */}
              </div>

              {showPnlTabs === "custom" && showCustomPnlModal && (
                <Modal>
                  <div className="z-0 relative flex items-center justify-center bg-[#17181B] w-[350px] h-[400px] md:w-[620px] md:h-[250px] rounded-md  px-8">
                    <button
                      onClick={handleCloseCustomPnlModal}
                      className="absolute top-4 right-2 p-1 md:top-6 md:right-2 rounded-md md:p-2"
                    >
                      <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
                    </button>
                    <div className="flex flex-col justify-center  space-y-8 w-full h-full">
                      <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:gap-x-6">
                        <div className="flex flex-col space-y-1 mb-4 w-full">
                          <label
                            className="flex items-center label  text-white"
                            htmlFor="startDate"
                          >
                            From
                          </label>
                          <input
                            required
                            name="startDate"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                          />
                        </div>

                        <div className="flex flex-col space-y-1 mb-4 w-full">
                          <label
                            className="flex items-center label  text-white"
                            htmlFor="endDate"
                          >
                            To
                          </label>
                          <input
                            required
                            name="endDate"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between space-x-4">
                        <button
                          disabled={!startDate || !endDate}
                          onClick={() =>
                            handleFilterCustomDateRangePnlData(
                              startDate,
                              endDate
                            )
                          }
                          className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-medium leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                        >
                          Show PNL
                        </button>
                        <div
                          onClick={handleCloseCustomPnlModal}
                          className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                        >
                          Cancel
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal>
              )}

              <ShowTradeHistoryData
                userTradeHistory={userTradeHistory}
                userOrdersHistory={userOrdersHistory}
                filteredUserTradeHistory={filteredUserTradeHistory}
                filteredUserOrdersHistory={filteredUserOrdersHistory}
                handleFilterByDays={handleFilterByDays}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
                setFilteredUserTradeHistory={setFilteredUserTradeHistory}
                setFilteredUserOrderHistory={setFilteredUserOrderHistory}
                handleResetFilterData={handleResetFilterData}
                isHistoryDataLoading={isHistoryDataLoading}
                isPositionsDataLoading={isPositionsDataLoading}
                userLivePositionData={userLivePositionData}
                userOpenOrdersData={userOpenOrdersData}
                filteredUserLivePositionData={filteredUserLivePositionData}
                filteredUserOpenOrdersData={filteredUserOpenOrdersData}
                setFilteredUserLivePositionData={
                  setFilteredUserLivePositionData
                }
                setFilteredUserOpenOrdersData={setFilteredUserOpenOrdersData}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default UserPortfolio;
