import React, { useState } from "react";
import { XIcon, FilterIcon } from "lucide-react";
import { filterOrderHistory } from "../../utils/filterFunctions";

export default function FilterOrderHistoryMobileModal({
  currentTab,
  userOriginalOrdersHistory,
  setFilteredUserOrderHistory,
  handleCloseMobileFilterModal,
}) {
  const [filters, setFilters] = useState({
    account_name: "",
    strategy_name:"",
    symbol: "",
    type: "",
    state: "",
    open_price: "",
    volume: "",
    reason: "",
    order_id: "",
    time: "",
  });

  const handleInputChange = (e, filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: e.target.value,
    }));
  };

  const handleApplyFilters = () => {
    const filtersToApply = {};

    for (const [key, value] of Object.entries(filters)) {
      if (value !== null && value !== "") {
        filtersToApply[key] = value;
      }
    }

    filterOrderHistory(
      filtersToApply,
      currentTab,
      userOriginalOrdersHistory,
      setFilteredUserOrderHistory
    );

    handleCloseMobileFilterModal();
  };

  return (
    <div className="flex flex-col justify-between w-full h-full pt-[25px] md:pt-[40px] pb-6 md:pb-12">
      
      <div className="text-lg md:text-3xl text-center font-semibold text-white">
         Filter Order History 
      </div>
      
      <div className={` grid grid-cols-2 gap-4`}>
        <div className=" px-1  ">
          <input
            type="text"
            placeholder="Account Name"
            value={filters.account_name}
            className=" py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "account_name")}
          />
        </div>
        <div className=" px-1  ">
          <input
            type="text"
            placeholder="Strategy Name"
            value={filters.strategy_name}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "strategy_name")}
          />
        </div>
        <div className=" px-1  ">
          <input
            type="text"
            placeholder="Symbol"
            value={filters.symbol}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "symbol")}
          />
        </div>
        <div className=" px-1  ">
          <input
            type="text"
            placeholder="Order Type"
            value={filters.type}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "type")}
          />
        </div>
        <div className=" px-1  ">
          <input
            type="text"
            placeholder="Order State"
            value={filters.state}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "state")}
          />
        </div>
        <div className=" px-1  ">
          <input
            type="number"
            placeholder="Open Price"
            value={filters.open_price}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "open_price")}
          />
        </div>
        <div className=" px-1  ">
          <input
            type="number"
            placeholder="Volume"
            value={filters.volume}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "volume")}
          />
        </div>
        {/* <div className=" px-1  ">
          <input
            type="text"
            placeholder="Reason"
            value={filters.reason}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "reason")}
          />
        </div> */}
        <div className=" px-1  ">
          <input
            type="number"
            placeholder="Order Id"
            value={filters.order_id}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20 text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "order_id")}
          />
        </div>
        
        <div className=" px-1  ">
          <input
            type="date"
            placeholder="Time"
            value={filters.time}
            className="py-2 px-2 w-full font-normal text-white/80 placeholder-white/20  text-sm focus:outline none border-[1px] border-white/10 bg-transparent md:py-3 md:text-lg rounded"
            onChange={(e) => handleInputChange(e, "time")}
          />
        </div>
      </div>

      <div className="  flex justify-between space-x-4">
        <button
          onClick={handleApplyFilters}
          className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-medium leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
        >
          Apply Filter
        </button>

        <div
          onClick={handleCloseMobileFilterModal}
          className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
        >
          Cancel
        </div>
      </div>
    </div>
  );
}
