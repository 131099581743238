import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { FileSpreadsheetIcon } from "lucide-react";
import toast from "react-hot-toast";

export default function JsonToCSV({
  data,
  headers,
  filenamePrefix,
  buttonTitle,
}) {
  const [csvData, setCsvData] = useState([]);
  const [fileName, setFileName] = useState("");

  const handleDownloadFile = () => {
  
    setCsvData(data);

    // Generate a filename with the current date and time
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace(/[-T:/]/g, "");
    const newFilename = `${filenamePrefix}_${formattedDate}.csv`;
    setFileName(newFilename);

    if (!data || !filenamePrefix) {
      toast.error("Error In File Downloading !!", {
        duration: 4000,
      });
    }

    toast.success("File Downloaded Successfully !!");
  };

  return (
    <>
      <button className="hidden lg:flex items-center space-x-2 bg-transparent border-[2px] border-[#07F2C7] px-6 py-2 rounded-md">
        <FileSpreadsheetIcon className="h-5 w-5 text-[#07F2C7]" />
        <CSVLink
          className="hidden lg:flex text-[#07F2C7] text-xs lg:text-base"
          data={csvData}
          headers={headers}
          filename={fileName}
          onClick={handleDownloadFile}
        >
          {buttonTitle}
        </CSVLink>
      </button>
      <button className="flex lg:hidden items-center lg:space-x-2 bg-[#07F2C7]/20 lg:bg-transparent lg:border-[2px] lg:border-[#07F2C7] py-1.5 px-1.5 lg:px-6 lg:py-2 rounded-md">
        <CSVLink
          className="flex lg:hidden text-[#07F2C7] text-xs lg:text-base"
          data={csvData}
          headers={headers}
          filename={fileName}
          onClick={handleDownloadFile}
        >
          <FileSpreadsheetIcon className="h-5 w-5 text-[#07F2C7]" />
        </CSVLink>
      </button>
    </>
  );
}
