import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { AiOutlineMail } from "react-icons/ai";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

const SignInEmail = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorShow, setErrorShow] = useState(false);
  const [error, setError] = useState();

  const signInWithEmail = () => {
    if (!email || !password) {
      setErrorShow(true);
      setError("Email and Password are required.");
    } else {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, email, password)
        .then((response) => {
          if (response) {
            const id = uuidv4();
            let data = JSON.stringify({
              id: id,
              email: response.user.email,
              username: response.user.displayName,
            });

            let config = {
              method: "post",
              url: "https://forex.server.sypto.xyz/api/create-user-account",
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            axios
              .request(config)
              .then((response) => {
                console.log("res", response);
              })
              .catch((error) => {
                console.log(error);
              });
            toast.success("Login Successful !!", {
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          setErrorShow(true);
          setError("Invalid Credentials. Please Try again");
        });
    }
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 3000);
    }
  }, [errorShow]);
  return (
    <div className="max-w-screen-xl w-full relative rounded-lg font-Poppins">
      <div className={"w-[100%] md:w-[100%]  text-left py-2 text-gray-100"}>
        <input
          type="email"
          className={
            "w-[100%] py-3 text-left bg-white/10  text-gray-100  rounded-[6px] border-[1px] border-white/10 px-3 text-base focus:outline-none focus:border-[1px] focus:border-white/20 "
          }
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div
        className={"w-[100%] md:w-[100%] mt-1  text-left py-2 text-gray-100"}
      >
        <input
          type="password"
          className={
            "w-[100%] text-left  py-3 bg-white/10 text-gray-100 rounded-[6px] border-[1px] border-white/10 px-3 text-base focus:outline-none focus:border-[1px] focus:border-white/20 "
          }
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="w-full flex justify-start pb-2 mt-8">
        <button
          onClick={() => props.setOpenForgotPassword(true)}
          className="flex flex-row items-center justify-center rounded-lg  text-white"
        >
          <div className="ml-1 select-none">Forgot your password?</div>
        </button>
      </div>
      <div>
        {" "}
        <button
          onClick={signInWithEmail}
          disabled={!email || !password}
          className="flex flex-row items-center justify-center py-2.5 w-full mb-3 rounded-[8px] bg-[#07F2C7] text-white font-semibold hover:bg-[#07F2C7]/90 ease-linear duration-150"
        >
          <div className=" select-none text-black">Login</div>
        </button>
      </div>
      {errorShow === true ? (
        <div className="w-96 bg-white flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-bold -mt-0.5">
            {error ? error : ""}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SignInEmail;
