import React from "react";

const style = {
  HeadingSubSmall: "text-xs md:text-xl font-bold leading-4 ml-2  mt-3 md:mt-4",
};

export default function ShowPnlDataMobileComponent({
  inputPnlValue,
  profitPercentage,
}) {
  return (
    <>
      {inputPnlValue && inputPnlValue > 0 ? (
        <div className="w-[65%] flex flex-row ">
          <div className="w-full text-sm text-white font-bold font-Poppins text-right">
            <span className="text-sm font-bold text-[#07F2C7]">
              + ${Number(inputPnlValue).toFixed(2)}
            </span>
            <span
              className={`${style.HeadingSubSmall}  font-bold text-[#07F2C7] `}
            >
              {Number(profitPercentage).toFixed(2)}%
            </span>
          </div>
        </div>
      ) : inputPnlValue && inputPnlValue < 0 ? (
        <div className="w-[65%] flex flex-row ">
          
          <div className="w-full text-sm text-white font-bold font-Poppins text-right">
            <span className="text-sm font-bold text-rose-500">
              - ${Math.abs(Number(inputPnlValue).toFixed(2))}
            </span>
            <span
              className={`${style.HeadingSubSmall}  font-bold text-rose-500  `}
            >
              {Number(profitPercentage).toFixed(2)}%
            </span>
          </div>
        </div>
      ) : (
        <div className="w-[65%] flex flex-row ">
          
          <div className="w-full text-sm text-white font-bold font-Poppins text-right">
            <span className="text-sm font-bold text-white">0.00</span>
            <span
              className={`${style.HeadingSubSmall}   font-bold text-white `}
            >
              0.00%
            </span>
          </div>
        </div>
      )}
    </>
  );
}
