// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDSDVQm3dxzHizYD_c_Bp-FOZMJjGf43Lo",
  authDomain: "monitor-dashboards-57bc0.firebaseapp.com",
  projectId: "monitor-dashboards-57bc0",
  storageBucket: "monitor-dashboards-57bc0.appspot.com",
  messagingSenderId: "811691127657",
  appId: "1:811691127657:web:d5b7e411eab2466010c3f8",
  measurementId: "G-Z9RJ24LVQH",
};

// Initialize Firebase
const app = !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();

const db = app.firebase.firestore();
const auth = app.firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export { db, auth, provider };
