export const tradeHistoryHeader = [
  { label: "User ID", key: "user_id" },
  { label: "Account ID", key: "account_id" },
  { label: "Account Name", key: "account_name" },
  { label: "Deal ID", key: "deal_id" },
  { label: "Platform", key: "platform" },
  { label: "Type", key: "type" },
  { label: "Time", key: "time" },
  { label: "Broker Time", key: "broker_time" },
  { label: "Commission", key: "commission" },
  { label: "Swap", key: "swap" },
  { label: "Profit", key: "profit" },
  { label: "Symbol", key: "symbol" },
  { label: "Magic", key: "magic" },
  { label: "Order ID", key: "order_id" },
  { label: "Position ID", key: "position_id" },
  { label: "Volume", key: "volume" },
  { label: "Price", key: "price" },
  { label: "Entry Type", key: "entry_type" },
  { label: "Reason", key: "reason" },
  {
    label: "Account Currency Exchange Rate",
    key: "account_currency_exchange_rate",
  },
];

export const orderHistoryHeader = [
  { label: "User Id", key: "user_id" },
  { label: "Account Id", key: "account_id" },
  { label: "Account Name", key: "account_name" },
  { label: "Order Id", key: "order_id" },
  { label: "Platform", key: "platform" },
  { label: "Type", key: "type" },
  { label: "State", key: "state" },
  { label: "Symbol", key: "symbol" },
  { label: "Magic", key: "magic" },
  { label: "Time", key: "time" },
  { label: "Broker Time", key: "broker_time" },
  { label: "Open Price", key: "open_price" },
  { label: "Volume", key: "volume" },
  { label: "Current Volume", key: "current_volume" },
  { label: "Position Id", key: "position_id" },
  { label: "Done Time", key: "done_time" },
  { label: "Done Broker Time", key: "done_broker_time" },
  { label: "Reason", key: "reason" },
  { label: "Filling Mode", key: "filling_mode" },
  { label: "Expiration Type", key: "expiration_type" },
  {
    label: "Account Currency Exchange Rate",
    key: "account_currency_exchange_rate",
  },
];
