import React, { useEffect, useState, useId } from "react";
import { DEV_URL, LIVE_URL } from "../../utils/data";
import Select from "react-select";
import {
  getUserAccountByEmail,
  getUserSubAccountByAccountId,
} from "../../utils/data";
import Modal from "../../components/modal";
import { PlusIcon, ServerIcon, ServerOffIcon, XIcon } from "lucide-react";
import toast from "react-hot-toast";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Login/firebase";
import { customStyles } from "../../utils/dropDownCustomStyles";

export default function CreateAccountForm({
  userId,
  userSubAccounts,
  tradeStrategyDataList,
}) {
  useEffect(() => {}, [userSubAccounts]);

  const [showModal, setShowModal] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [isServerConnected, setIsServerConnected] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedStrategy, setSelectedStrategy] = useState("");

  const [formData, setFormData] = useState({
    login: "",
    password: "",
    name: "",
    email: "",
    server: "",
    region: "new-york",
    platform: "mt5",
    type: "cloud-g2",
    magic: 0,
  });

  const isAllDeployed = userSubAccounts.every(
    (item) =>
      item.state.toLowerCase() === "deployed" &&
      item.connection_status.toLowerCase() === "connected"
  );

  const buttonLabel = isAllDeployed ? "Undeploy" : "Deploy";

  const updateUserAccountBalance = async () => {
    const result = await fetch(`${LIVE_URL}/api/update-account-balance`);

    const data = await result.json();

    console.log("Upadted account balance data", data);
  };

  const updateUserInvestedAmount = async () => {
    const result = await fetch(`${LIVE_URL}/api/update-invested-amount`);

    const data = await result.json();

    console.log("Upadted Invested Amount data", data);
  };

  const handleRestartServer = async () => {
    const accountsData = userSubAccounts.map((data) => {
      return {
        accountId: data.account_id,
        state: data.state,
        connectionStatus: data.connection_status,
      };
    });

    const formRequestBodyRedeploy = {
      userId: userId,
      action: isAllDeployed ? "UNDEPLOY" : "DEPLOY",
      accountsData: accountsData,
    };

    // console.log("form Request Body --- >", formRequestBodyRedeploy);
    // console.log("Status is --- >", isAllDeployed);

    const stopServerResponse = await fetch(
      `${LIVE_URL}/api/start-stop-server`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBodyRedeploy),
      }
    );
    const stopServerData = await stopServerResponse.json();

    // console.log("Response Data For Stopping Server 1st Time", stopServerData);

    if (stopServerData.status === 200) {
      toast.success(`Server ${buttonLabel} !!`, {
        style: {
          minWidth: "250px",
        },
        success: {
          duration: 5000,
          icon: "🔥",
        },
      });

      await updateUserAccountBalance();
      await new Promise((resolve) => setTimeout(resolve, 10000));
      await updateUserInvestedAmount();
      return;
    }

    if (stopServerData.status === 404) {
      toast.loading(`Trying to connect to server it  will take some time.`, {
        style: {
          minWidth: "250px",
        },
        duration: 10000,
      });
      toast.loading(`Waiting For Server Connection`, {
        style: {
          minWidth: "250px",
        },
        duration: 10000,
      });
      // Waiting for 3 mins for Server Connection

      await new Promise((resolve) => setTimeout(resolve, 180000));

      // console.log("3 min Completed 1st Time !!!");

      const stopServerResponseTwo = await fetch(
        `${LIVE_URL}/api/start-stop-server`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formRequestBodyRedeploy),
        }
      );
      const stopServerDataTwo = await stopServerResponseTwo.json();

      // console.log(
      //   "Response Data For Stopping Server 2nd Time",
      //   stopServerDataTwo
      // );

      if (stopServerDataTwo.status === 200) {
        toast.success(`Server ${buttonLabel} !!`, {
          style: {
            minWidth: "250px",
          },
          success: {
            duration: 5000,
            icon: "🔥",
          },
        });
        await updateUserAccountBalance();
        await new Promise((resolve) => setTimeout(resolve, 10000));
        await updateUserInvestedAmount();
        return;
      } else {
        toast.loading(`Trying to connect to server it  will take some time.`, {
          style: {
            minWidth: "250px",
          },
          duration: 10000,
        });
        // Waiting for 3 mins for Server Connection

        await new Promise((resolve) => setTimeout(resolve, 180000));
        // console.log("3 min Completed 2nd Time !!!");

        const stopServerResponseThree = await fetch(
          `${LIVE_URL}/api/start-stop-server`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formRequestBodyRedeploy),
          }
        );
        const stopServerDataThree = await stopServerResponseThree.json();
        // console.log(
        //   "Response Data For Stopping Server 3rd time",
        //   stopServerDataThree
        // );

        if (stopServerDataThree.status === 200) {
          toast.success(`Server Deployed Successfully !!`, {
            style: {
              minWidth: "250px",
            },
            success: {
              duration: 5000,
              icon: "🔥",
            },
          });

          await updateUserAccountBalance();
          await new Promise((resolve) => setTimeout(resolve, 10000));
          await updateUserInvestedAmount();

          return;
        } else {
          const formRequestBodyUndeployAllAccounts = {
            userId: userId,
            action: "UNDEPLOY",
            accountsData: accountsData,
          };

          const stopAllServerResponse = await fetch(
            `${LIVE_URL}/api/update-all-account-server`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formRequestBodyUndeployAllAccounts),
            }
          );
          toast.error(`Server Deploy Failed!!, Please Try Again.`, {
            style: {
              minWidth: "250px",
            },
            duration: 4000,
          });
        }
      }
    }

    if (stopServerData.status === 500) {
      toast.error(`Error!! Connecting to Server`, {
        style: {
          minWidth: "250px",
        },
      });
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectedRole = (newRole) => {
    setSelectedRole(newRole);
  };
  const handleSelectedStrategy = (strategy) => {
    setSelectedStrategy(strategy);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "roles") {
      setFormData({
        ...formData,
        [name]: [value],
      });
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdateUserIdAfterStrategySubscription = async (
    strategyId,
    strategyCreatorId,
    subscriberUserId,
    subscriberAccountId
  ) => {
    const requestBody = {
      userId: strategyCreatorId,
      strategyId: strategyId,
      subscriberId: subscriberUserId,
      subscriberList: [subscriberAccountId],
    };
    const updateUserIdData = await fetch(`${LIVE_URL}/api/update-user-id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const newUserData = await updateUserIdData.json();
    return newUserData;
  };

  const handleSubscribeStrategy = async (
    strategyId,
    subscriberName,
    strategyCreatorId,
    subscriberUserId,
    subscriberAccountId
  ) => {
    const subscriberId = subscriberAccountId;

    console.log("subscriber Acc Id", subscriberId);
    console.log("Strategy Creator Id", strategyCreatorId);
    console.log("Strategy  Id", strategyId);
    console.log("Strategy Subscriber Id is same as User Id", subscriberUserId);

    const formRequestBody = {
      name: subscriberName,
      subscriptions: [
        {
          strategyId: strategyId,
          multiplier: 1,
          tradeSizeScaling: {
            mode: "none",
          },
        },
      ],
    };

    // console.log(formRequestBody);

    const res = await fetch(
      `${LIVE_URL}/api/subscribe-strategy/${subscriberId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBody),
      }
    );
    const data = await res.json();

    if (data?.status === 200) {
      console.log("Strategy Subscribed  Successfully!!!", data);

      const updatedUserId = await handleUpdateUserIdAfterStrategySubscription(
        strategyId,
        strategyCreatorId,
        subscriberUserId,
        subscriberAccountId
      );

      console.log("after Subscribe Data", updatedUserId);

      if (updatedUserId?.status === 200) {
        toast.success("Strategy Subscribed Successfully !!", {
          style: {
            minWidth: "250px",
          },
          duration: 5000,
        });
      }
    } else {
      console.log("Strategy Subscribed  Failed!!!", data);
      toast.success("Strategy Subscription Failed !!", {
        style: {
          minWidth: "250px",
        },
        duration: 5000,
      });
    }
  };

  const delayFunction = async (accountId, state, userAccountData) => {
    console.log("Delayed function Called !!");
    toast.loading(
      "Waiting For Sever Connection !!",

      {
        style: {
          minWidth: "250px",
        },
        duration: 15000,
      }
    );

    console.log("Starting First 3 min Timer !!");
    await new Promise((resolve) => setTimeout(resolve, 180000));

    console.log("Ending First 3 min Timer !!");
    // console.log("Async function called after 180 seconds");
    const newUserData = await fetch(
      `${LIVE_URL}/api/fetch-sub-account/${accountId}`
    );
    const resultUserData = await newUserData.json();

    if (resultUserData?.status === 400) {
      console.log("Fetch Sub data Response Not Ok !!");
      toast.loading(
        "Server Connection will take some more time !!",

        {
          style: {
            minWidth: "250px",
          },
          duration: 10000,
        }
      );

      console.log("Starting Second 3 min Timer !!");
      await new Promise((resolve) => setTimeout(resolve, 180000));

      console.log("Ending Second 3 min Timer !!");

      // const newUserDataTwo = await getUserSubAccountByAccountId(accountId);
      const newUserDataTwo = await fetch(
        `${LIVE_URL}/api/fetch-sub-account/${accountId}`
      );
      const resultUserDataTwo = await newUserDataTwo.json();

      console.log("Result User Data Two", resultUserDataTwo);

      if (resultUserDataTwo?.status === 200) {
        console.log("Fetch Sub data second time Response Ok !!");

        const newUserSubAccountDB = {
          accountId: accountId,
          userId: userId,
          subscriberId: userId,
          state: state,
          ...userAccountData,
          ...resultUserDataTwo?.data,
        };
        console.log(
          "New User Sub Account Data with two APIs -->",
          newUserSubAccountDB
        );

        const resultAddSubAccountTwo = await fetch(
          `${LIVE_URL}/api/add-sub-account`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newUserSubAccountDB),
          }
        );
        const addSubAccountFirst = await resultAddSubAccountTwo.json();

        if (addSubAccountFirst?.status === 200) {
          console.log("Sub Account Data Added Successfully!!");

          toast.success("Server Connection Successfully !!", {
            style: {
              minWidth: "250px",
            },
            duration: 6000,
          });
        } else {
          console.log("Sub Account Data Added Failed!!");
          toast.error("Server Connection Failed !!", {
            style: {
              minWidth: "250px",
            },
            duration: 6000,
          });
        }

        return;
      }
    } else {
      const newUserSubAccountDB = {
        accountId: accountId,
        userId: userId,
        subscriberId: userId,
        state: state,
        ...userAccountData,
        ...resultUserData?.data,
      };

      console.log(
        "New User Sub Account Data with two APIs -->",
        newUserSubAccountDB
      );

      const resultAddSubAccountTwo = await fetch(
        `${LIVE_URL}/api/add-sub-account`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newUserSubAccountDB),
        }
      );

      const addSubAccountTwo = await resultAddSubAccountTwo.json();

      if (addSubAccountTwo?.status === 200) {
        console.log("Sub Account Data Two Added Successfully!!");
        toast.success("Server Connection Successfully !!", {
          style: {
            minWidth: "250px",
          },
          duration: 6000,
        });
      } else {
        console.log("Sub Account Data Two Added Failed!!");
        toast.error("Server Connection Failed !!", {
          style: {
            minWidth: "250px",
          },
          duration: 6000,
        });
      }

      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    toast.loading(
      "Creating Account !!",

      {
        style: {
          minWidth: "250px",
        },
        duration: 4000,
      }
    );

    const formRequestBody = {
      userId: userId,
      subscriberId: userId,
      connectionStatus: "CONNECTING",
      email: formData.email,
      login: formData.login,
      password: formData.password,
      name: formData.name,
      server: formData.server,
      region: formData.region.length > 0 ? formData.region : "new-york",
      platform: formData.platform.length > 0 ? formData.platform : "mt5",
      type: formData.type.length > 0 ? formData.type : "cloud-g2",
      magic: 0,
      copyFactoryRoles:
        selectedRole?.value.length > 0 ? [selectedRole?.value] : [""],
    };

    handleCloseModal();
    // console.log("Form Request Body:", formRequestBody);

    const response = await fetch(`${LIVE_URL}/api/create-sub-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formRequestBody),
    });

    if (response?.status === 200) {
      // Request was successful

      console.log("Sub Account Created Successfully!!");

      const accountData = await response.json();

      const newUserAccountData = {
        password: formRequestBody.password,
        accountType: formRequestBody.type,
        region: formRequestBody.region,
      };

      // console.log(
      //   "Complete Data After Creating and Fetching Account with Copy Factory Role:",
      //   accountData
      // );

      toast.success("Account Created Sucessfully !!", {
        style: {
          minWidth: "250px",
        },
        duration: 4000,
      });

      delayFunction(
        accountData.accountId,
        accountData.state,
        newUserAccountData
      );

      // After account Creation, Subscriber to  Strategy if chosen
      console.log("Starting Small 10s Timer for Strategy Subscription !!");
      await new Promise((resolve) => setTimeout(resolve, 10000));

      if (selectedStrategy && selectedStrategy?.value.length > 0) {
        handleSubscribeStrategy(
          selectedStrategy?.value,
          formRequestBody.name,
          userId,
          userId,
          accountData.accountId
        );
      }
      setFormData({
        login: "",
        password: "",
        name: "",
        email: "",
        server: "",
        region: "new-york",
        platform: "mt5",
        type: "cloud-g2",
        magic: 0,
      });
      setSelectedRole("");
      setSelectedStrategy("");
    } else {
      // Request failed
      console.error("Create Account request failed:", response.statusText);
    }
  };

  return (
    <div className="  w-full h-full lg:p-4 ">
      <div className="fixed  bottom-0 z-50  space-x-2 md:space-x-10 lg:z-0 lg:relative px-2 py-3  lg:py-0 lg:px-0 flex w-full   lg:space-y-0 lg:flex-row lg:justify-end lg:space-x-6">
        <button
          onClick={() => {
            handleRestartServer();
            if (buttonLabel === "Undeploy") {
              toast.promise(
                handleRestartServer(),
                {
                  loading: `${buttonLabel}ing...`,
                  success: `Server ${buttonLabel}ed Successfully !! `,
                  error: `Server ${buttonLabel} Failed !!`,
                },
                {
                  style: {
                    minWidth: "250px",
                  },
                  success: {
                    duration: 5000,
                    icon: "🔥",
                  },
                }
              );
            } else {
              toast(`Waiting For Server Connection....`, {
                style: {
                  minWidth: "250px",
                },
                success: {
                  duration: 10000,
                },
              });
            }
          }}
          className={` ${
            buttonLabel === "Deploy"
              ? " text-[#07F2C7] hover:bg-[#07F2C7]/20 border-[2px] border-[#07F2C7]/20"
              : " text-rose-500 hover:bg-rose-500/20 border-[2px] border-rose-500"
          } flex w-full md:w-full lg:w-[190px] bg-transparent items-center cursor-pointer justify-center rounded-md   px-3 py-2.5 md:py-4 lg:py-2.5 text-base md:text-xl lg:text-base font-normal   shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-md `}
        >
          {buttonLabel === "Deploy" ? (
            <ServerIcon strokeWidth={3} className="mr-2 w-4 h-4" />
          ) : (
            <ServerOffIcon strokeWidth={3} className="mr-2 w-4 h-4" />
          )}
          {buttonLabel} Servers
        </button>
        <button
          onClick={handleShowModal}
          className="flex w-full md:w-full lg:w-[190px] items-center cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-2.5 md:py-4 lg:py-2.5 text-base md:text-xl lg:text-base font-semibold  text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500  "
        >
          <PlusIcon strokeWidth={3} className="mr-2 w-4 h-4 md:w-4 md:h-4" />
          New Account
        </button>
      </div>

      {showModal && (
        <Modal>
          {/* <div className="w-full  overflow-auto sm:overflow-hidden"> */}
          <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[350px]  h-[550px] px-4 overflow-scroll custom-scroll mt-10 md:mt-0 md:overflow-hidden  md:w-[785px] md:h-[600px] rounded-md md:py-4 md:px-8 ">
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>
            <div className="mt-52 md:mt-0 flex flex-col justify-center  space-y-8 w-full h-full md:w-[610px] md:h-full">
              <h2 className=" text-center text-xl md:text-2xl font-bold  text-white">
                Add New Account
              </h2>

              <form onSubmit={handleSubmit} className="flex  flex-col   mt-6 ">
                <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-x-6">
                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Login <sup className="text-rose-600">*</sup>
                    </label>
                    <input
                      required
                      className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                      type="text"
                      name="login"
                      placeholder="Your Login Id"
                      value={formData.login}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Password <sup className="text-rose-600">*</sup>
                    </label>
                    <input
                      required
                      className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                      type="password"
                      name="password"
                      placeholder="Your Password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Name <sup className="text-rose-600">*</sup>
                    </label>
                    <input
                      required
                      className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                      type="text"
                      name="name"
                      placeholder="Account Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Server <sup className="text-rose-600">*</sup>
                    </label>
                    <input
                      className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                      type="text"
                      name="server"
                      placeholder="Server Name"
                      value={formData.server}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Email <sup className="text-rose-600">*</sup>
                    </label>
                    <input
                      className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Select CopyFactory Role
                    </label>

                    <Select
                      value={selectedRole}
                      onChange={handleSelectedRole}
                      options={["SUBSCRIBER", "PROVIDER"]?.map((option) => ({
                        value: option,
                        label: option,
                      }))}
                      placeholder="Select CopyFactory Role"
                      styles={customStyles}
                      className="text-white "
                    />
                  </div>
                  <div className="flex flex-col space-y-1 mb-4 w-full">
                    <label className="flex items-center label  text-white">
                      Select Strategy
                    </label>

                    <Select
                      value={selectedStrategy}
                      onChange={handleSelectedStrategy}
                      options={tradeStrategyDataList?.map((option) => ({
                        value: option.strategy_id,
                        label: option.strategy_name,
                      }))}
                      placeholder="Select Strategy"
                      styles={customStyles}
                      className="text-white "
                    />
                  </div>
                </div>

                <div className=" mt-6 pb-6 lg:pb-0 flex space-x-2 md:flex-row md:space-y-0 w-full  md:gap-x-6">
                  <button
                    type="submit"
                    className="flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                  >
                    Create
                  </button>
                  <div
                    onClick={handleCloseModal}
                    className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-semibold leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Cancel
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* </div> */}
        </Modal>
      )}
    </div>
  );
}
