import React, { useState } from "react";
import { XIcon, CheckIcon } from "lucide-react";
import { filterOpenOrders } from "../../utils/filterFunctions";

export default function FilterOpenOrdersModal({
  showFilter,
  handleCloseFilter,
  currentTab,
  filteredUserOpenOrdersData,
  userOriginalOpenOrders,
  setFilteredUserOpenOrdersData,
}) {
  const [openOrdersFilters, setOpenOrdersFilters] = useState({
    account_name: "",
    strategy_name:"",
    symbol: "",
    type: "",
    state: "",
    volume: "",
    current_volume: "",
    open_price: "",
    current_price: "",
    reason: "",
    time: "",
  });

  const handleInputChange = (e, filterKey) => {
    setOpenOrdersFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: e.target.value,
    }));
  };

  const handleApplyOpenOrdersFilters = () => {
    const filtersToApply = {};

    for (const [key, value] of Object.entries(openOrdersFilters)) {
      if (value !== null && value !== "") {
        filtersToApply[key] = value;
      }
    }

    filterOpenOrders(
      filtersToApply,
      currentTab,
      userOriginalOpenOrders,
      setFilteredUserOpenOrdersData
    );
  };

  return (
    <tr
      className={` h-[70px]  bg-[#07F2C7]/20 border-gray-800 
         ${
           showFilter
             ? "animate-slideDown transition duration-500 ease-in"
             : "animate-slideUp transition duration-500 ease-in"
         } `}
    >
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Account Name"
          value={openOrdersFilters.account_name}
          className=" py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "account_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Strategy Name"
          value={openOrdersFilters.strategy_name}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "strategy_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Symbol"
          value={openOrdersFilters.symbol}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "symbol")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Order Type"
          value={openOrdersFilters.type}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "type")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Order State"
          value={openOrdersFilters.state}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "state")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Volume"
          value={openOrdersFilters.volume}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "volume")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Current Volume"
          value={openOrdersFilters.current_volume}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "current_volume")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Open Price"
          value={openOrdersFilters.open_price}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "open_price")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Current Price"
          value={openOrdersFilters.current_price}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "current_price")}
        />
      </th>

      {/* <th className=" px-1  ">
        <input
          type="text"
          placeholder="Reason"
          value={openOrdersFilters.reason}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "reason")}
        />
      </th> */}

      <th className=" px-1  ">
        <span className="flex items-center space-x-4">
          <input
            type="date"
            placeholder="Time"
            value={openOrdersFilters.time}
            className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
            onChange={(e) => handleInputChange(e, "time")}
          />
          <span
            onClick={handleApplyOpenOrdersFilters}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
             <CheckIcon className="h-5 w-5 text-[#07F2C7] " />
          </span>
          <span
            onClick={handleCloseFilter}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
             <XIcon className="h-5 w-5 text-rose-500 " />
          </span>
        </span>
      </th>
    </tr>
  );
}
