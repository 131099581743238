import React, { useState } from "react";
import { DEV_URL, LIVE_URL } from "../../utils/data";
import Modal from "../../components/modal";
import { XIcon } from "lucide-react";
import toast from "react-hot-toast";
import Select from "react-select";
import { customStyles } from "../../utils/dropDownCustomStyles";

export default function UpdateAccountForm({
  userId,
  index,
  accountData,
  showModal,
  handleCloseModal,
  handleReDeployServer,
}) {
  const [formData, setFormData] = useState({
    name: accountData?.account_name,
    server: accountData?.server,
    region: accountData?.region,
    platform: accountData?.platform,
    type: accountData?.account_type,
    magic: accountData?.magic,
    roles: accountData?.copy_factory_roles[0],
    balance: accountData?.balance,
    equity: accountData?.equity,
    freeMargin: accountData?.free_margin,
  });

  const [selectedRole, setSelectedRole] = useState("");

  const handleSelectedRole = (newRole) => {
    setSelectedRole(newRole);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "roles") {
      setFormData({
        ...formData,
        [name]: [value],
      });
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formRequestBody = {
      // login: formData.login,
      password: accountData?.password,
      accountId: accountData?.account_id,
      name: formData.name,
      server: formData.server,
      region: formData.region.length > 0 ? formData.region : "new-york",
      platform: formData.platform.length > 0 ? formData.platform : "mt5",
      type: formData.type.length > 0 ? formData.type : "cloud-g2",
      magic: 0,
      copyFactoryRoles:
        selectedRole?.value.length > 0 ? [selectedRole?.value] : [""],
      balance: formData.balance,
      equity: formData.equity,
      freeMargin: formData.freeMargin,
    };

    // console.log("Form Request Body:", formRequestBody);

    const response = await fetch(`${LIVE_URL}/api/update-sub-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formRequestBody),
    });

    const data = await response.json();

    if (data?.status === 200) {
      toast.success("Account Updated Sucessfully !!", {
        style: {
          minWidth: "250px",
        },
        duration: 4000,
      });
    } else if (data?.status === 400) {
      toast.error("Account Updation Failed !!", {
        style: {
          minWidth: "250px",
        },
        duration: 4000,
      });
    }

    handleCloseModal();
    handleReDeployServer(index, accountData?.account_id);
  };

  return (
    <>
      {showModal && (
    
          <Modal>
            <div className="z-50 relative flex items-center justify-center bg-[#17181B] w-[350px]  h-[500px] px-4 overflow-scroll custom-scroll mt-10 md:mt-0 md:overflow-hidden  md:w-[785px] md:h-[600px] rounded-md md:py-4 md:px-8 ">
              <button
                onClick={handleCloseModal}
                className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
              >
                <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
              </button>

              <div className="mt-80 md:mt-0 flex flex-col justify-center  space-y-8 w-full h-full md:w-[610px] md:h-full  ">
                <h2 className=" text-center text-xl md:text-2xl font-bold  text-white">
                  Update Account
                </h2>
                <form onSubmit={handleSubmit} className="flex flex-col mt-6">
                  <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-x-6">
                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Name: <sup className="text-rose-600 font-bold">*</sup>
                      </label>
                      <input
                        required
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Server: <sup className="text-rose-600">*</sup>
                      </label>
                      <input
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        type="text"
                        name="server"
                        value={formData.server}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Platform: <sup className="text-rose-600">*</sup>
                      </label>
                      <select
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        name="platform"
                        value={formData.platform}
                        onChange={handleInputChange}
                      >
                        <option
                          className="bg-[#17181b] text-white"
                          defaultValue=""
                        >
                          Choose Platform
                        </option>
                        <option className="bg-[#17181b] text-white" value="mt4">
                          mt4
                        </option>
                        <option className="bg-[#17181b] text-white" value="mt5">
                          mt5
                        </option>
                      </select>
                    </div>
                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Magic: <sup className="text-rose-600">*</sup>
                      </label>
                      <input
                        required
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        type="number"
                        name="magic"
                        value={formData.magic}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Account Type: <sup className="text-rose-600">*</sup>
                      </label>
                      <select
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        name="type"
                        value={formData.type}
                        onChange={handleInputChange}
                      >
                        <option
                          className="bg-[#17181b] text-white"
                          defaultValue=""
                        >
                          Choose Account Type
                        </option>
                        <option
                          className="bg-[#17181b] text-white"
                          value="cloud-g1"
                        >
                          cloud-g1 (General Use)
                        </option>
                        <option
                          className="bg-[#17181b] text-white"
                          value="cloud-g2"
                        >
                          cloud-g2 (Cost Optimized)
                        </option>
                      </select>
                    </div>
                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Select Region:
                      </label>
                      <select
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        name="region"
                        value={formData.region}
                        onChange={handleInputChange}
                      >
                        <option
                          className="bg-[#17181b] text-white"
                          defaultValue=""
                        >
                          Select Region
                        </option>
                        <option
                          className="bg-[#17181b] text-white"
                          value="new-york"
                        >
                          new-york
                        </option>
                        <option
                          className="bg-[#17181b] text-white"
                          value="london"
                        >
                          london
                        </option>
                        <option
                          className="bg-[#17181b] text-white"
                          value="singapore"
                        >
                          singapore
                        </option>
                        <option
                          className="bg-[#17181b] text-white"
                          value="tokyo"
                        >
                          tokyo
                        </option>
                      </select>
                    </div>

                    <div className="flex flex-col space-y-1 mb-4 w-full">
                      <label className="flex items-center label  text-white">
                        Select CopyFactory Role
                      </label>
                      <Select
                        value={selectedRole}
                        onChange={handleSelectedRole}
                        options={["SUBSCRIBER", "PROVIDER"]?.map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        isSearchable
                        placeholder="Select CopyFactory Role"
                        styles={customStyles}
                        className="text-white "
                      />
                      {/* <select
                        className="py-3 px-3 text-sm placeholder:text-gray-600 w-full bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                        name="roles"
                        value={formData.roles}
                        onChange={handleInputChange}
                      >
                        <option className="bg-[#17181b] text-white" defaultValue="">Select CopyFactory Role</option>
                        <option className="bg-[#17181b] text-white" value="PROVIDER">PROVIDER</option>
                        <option className="bg-[#17181b] text-white" value="SUBSCRIBER">SUBSCRIBER</option>
                      </select> */}
                    </div>
                  </div>

                  <div className="mt-6 mb-4 md:mb-0 flex flex-row  w-full gap-x-6">
                    <button
                      type="submit"
                      className="flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                    >
                      Update 
                    </button>
                    <div
                      onClick={handleCloseModal}
                      className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-semibold leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                    >
                      Cancel
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        
      )}
    </>
  );
}
