import React, { useEffect, useState } from "react";

const Timer = (props) => {
  const [seconds1, setSeconds1] = useState(30);

  const [isActive, setIsActive] = useState(false);

  function reset() {
    setSeconds1(30);
    setIsActive(false);
  }

  useEffect(() => {
    if (!isActive && seconds1 > 0) {
      setTimeout(() => setSeconds1(seconds1 - 1), 1000);
    } else {
      setSeconds1("");
    }
  }, [isActive, seconds1]);

  return (
    <div>
      <div className="textResend mt-4 text-center">
        {seconds1 > 0 ? (
          <div>
            <span className="text-white ">Resend code in</span>&nbsp;
            <span style={{ color: "tomato" }}>00:{seconds1} sec</span>
          </div>
        ) : (
          <div className="text-white">
            <>
              Didn’t receive the code?{" "}
              <div
                onClick={reset}
                style={{
                  display: "inline-block",
                  color: "#000000",
                  cursor: "pointer",
                }}
              >
                <div
                  onClick={props.resend}
                  className="cursor-pointer text-[#07F2C7]"
                >
                  {" "}
                  Resend
                </div>
              </div>
            </>
          </div>
        )}
      </div>
    </div>
  );
};
export default Timer;
