import React, { useState, useEffect } from "react";
import DashboardLayout from "./DashboardLayout";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import moment from "moment";
import {
  getAllUserOrderHistory,
  getAllUserTradeHistory,
  getUserAccountByEmail,
  getAllUserSubAccountByUserId,
} from "../utils/data";
import AccuracyChart from "./AllCharts/AccuracyCharts"; 
import PnlChart from "./AllCharts/PnlCharts"; 
import PnlChartMonthly from "./AllCharts/PnlChartMonthly";
import io from "socket.io-client";

import Moment from "moment";
import LoadingSpinner from "../components/loading-spinner";

const LIVE_URL = "https://forex.websocket.sypto.xyz";
const DEV_URL = "http://localhost:3001";

function Dashboard({ userLivePositionData }) {
  const user = localStorage.getItem("userDetails");
  const userData = user && JSON.parse(user);
  const userDeatils = userData && userData.response;
  const email = userDeatils && userDeatils[0]["email"];

  const [userId, setUserId] = useState("");
  const [userTradeHistory, setUserTradeHistory] = useState([]);
  const [userSubAcconnts, setUserSubAccounts] = useState([]);
  const [pnlTab, setPnlTab] = useState("allTime");
  const [isLoadingData, setIsLoadingData] = useState(false);

  useEffect(() => {
    setIsLoadingData(true);
    const fetchHistoryData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";

      setUserId(id);

      const userTradeHistoryData = await getAllUserTradeHistory(id);
      setUserTradeHistory(userTradeHistoryData?.data);

      const subAccounts = await getAllUserSubAccountByUserId(id);

      setUserSubAccounts(subAccounts?.data);
      setIsLoadingData(false);
    };
    fetchHistoryData();

    // updateAccountBalanceWebSocket();
  }, [userId]);


  const totalTrades =
    userTradeHistory &&
    userTradeHistory.filter((item) => item.entry_type === "DEAL_ENTRY_OUT");

  const profitTrades =
    totalTrades && totalTrades.filter((item) => item.profit > 0);
  const lossTrades =
    totalTrades && totalTrades.filter((item) => item.profit < 0);

  const totalProfitTradeSum =
    profitTrades &&
    profitTrades.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    }, 0);

  const profitAverage = totalProfitTradeSum / profitTrades.length;

  const lossTradeSum =
    lossTrades &&
    lossTrades.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.profit;
    }, 0);

  const lossTradeAverage = lossTradeSum / lossTrades.length;

  const totalBalance =
    userSubAcconnts &&
    userSubAcconnts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.balance;
    }, 0);

  const currentBalance =
    userSubAcconnts &&
    userSubAcconnts.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.current_balance;
    }, 0);

  const livePNLData =
    userLivePositionData && userLivePositionData.length > 0
      ? userLivePositionData.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.profit;
        }, 0)
      : 0;

  const Profit = "profit";

  function createAggregateMap(userPnlData) {
    const aggregatedDataMap = new Map();

    // Iterate over the original data and aggregate profit values
    for (const [date, profit] of userPnlData) {
      if (aggregatedDataMap.has(date)) {
        // If the date is already in the map, aggregate the profit value
        aggregatedDataMap.set(date, aggregatedDataMap.get(date) + profit);
      } else {
        // If the date is not in the map, add a new entry
        aggregatedDataMap.set(date, profit);
      }
    }

    // Convert the map back to an array
    const aggregatedDataArray = [...aggregatedDataMap.entries()].map(
      ([date, totalProfit]) => [date, totalProfit]
    );

    aggregatedDataArray.unshift(["date", "Profit"]);

    // console.log(aggregatedDataArray);
    return aggregatedDataArray;
  }

  function filterWeeklyPnlData() {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 7 days ago
    const oneWeekAgo = new Date(currentDate);
    oneWeekAgo.setDate(currentDate.getDate() - 7);

    // Use filter to get objects within the last 7 days
    const filteredData = totalTrades.filter((item) => {
      const itemDate = new Date(item.time);
      return itemDate >= oneWeekAgo && itemDate <= currentDate;
    });

    const filteredDataInArray = filteredData.map((item) => [
      moment(item.time).format("DD-MM-YYYY"),
      item[Profit],
    ]);

    const weeklyPnlDataInArray = createAggregateMap(filteredDataInArray);

    return weeklyPnlDataInArray;
  }

  function filterMonthlyPnlData() {
    const allMonths = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Grouping trades by month
    const tradesByMonth = {};

    totalTrades.forEach((trade) => {
      const tradeDate = new Date(trade.time);
      const month = tradeDate.toLocaleString("default", { month: "long" });

      if (!tradesByMonth[month]) {
        tradesByMonth[month] = {
          month: month,
          totalProfit: 0,
          profits: [],
        };
      }

      if (trade.entry_type === "DEAL_ENTRY_OUT") {
        tradesByMonth[month].totalProfit += trade.profit || 0;
        tradesByMonth[month].profits.push(trade.profit || 0);
      }
    });

    // Create an array with data for each month (including those with no trades)
    const monthlySummary = allMonths.map(
      (month) => tradesByMonth[month] || { month, totalProfit: 0, profits: [] }
    );

    return monthlySummary;
  }

  function filterAllTimePnlData() {
    const pnlData = totalTrades.map((item) => [
      moment(item.time).format("DD-MM-YYYY"),
      item[Profit],
    ]);

    const pnlDataAllTimeInArray = createAggregateMap(pnlData);
    return pnlDataAllTimeInArray;
  }

  const pnlDataWeekly = filterWeeklyPnlData();
  const pnlDataMonthly = filterMonthlyPnlData();
  const pnlDataAllTime = filterAllTimePnlData();

  const tradesByDate = {};

  userTradeHistory &&
    userTradeHistory.forEach((trade) => {
      const date = trade.time.split("T")[0];
      if (!tradesByDate[date]) {
        tradesByDate[date] = [];
      }
      tradesByDate[date].push(trade);
    });

  // Calculate total trade, profit, and profit trade count for each day
  const dailySummary = [];

  for (const date in tradesByDate) {
    const trades = tradesByDate[date];
    let totalTrade = 0;
    let totalProfit = 0;
    let profitTradeCount = 0;

    trades.forEach((trade) => {
      if (trade.entry_type === "DEAL_ENTRY_OUT") {
        totalTrade++;
        if (trade.profit > 0) {
          totalProfit += trade.profit;
          profitTradeCount++;
        }
      }
    });

    dailySummary.push({
      date: Moment(date, "YYYY-MM-DD HH:mm:ss A").format("DD-MM-YYYY"),
      totalTrade: totalTrade,
      totalProfit: totalProfit,
      profitTradeCount: profitTradeCount,
      accuracy: ((profitTradeCount / totalTrade) * 100).toFixed(2),
    });
  }

  const date = "date";
  const accuracy = "accuracy";
  const accuracyChartData =
    dailySummary && dailySummary.map((item) => [item[date], item[accuracy]]);
  accuracyChartData.unshift(["date", "accuracy"]);

  // console.log("pnlDataMonthly --->", accuracyChartData);

  const month = "month";
  const allMonthProfit = "totalProfit";

  const pnlDataMonthlyChartData =
    pnlDataMonthly &&
    pnlDataMonthly.map((item) => [item[month], item[allMonthProfit]]);
  pnlDataMonthlyChartData.unshift(["month", "totalProfit"]);

  return (
    <DashboardLayout>
      <div className="flex flex-col  relative  w-full min-h-screen  px-10 py-2">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div className="flex flex-col items-center justify-center  w-full bg-[#0F0F0F] rounded-md h-[100px]">
            <h3 className="text-base font-normal text-white/80">
              Invested Amount
            </h3>
            {isLoadingData ? (
              <LoadingSpinner />
            ) : (
              <p className="text-3xl font-bold text-white">
                ${totalBalance.toFixed(2)}
              </p>
            )}
          </div>

          <div className="flex flex-col items-center justify-center  w-full bg-[#0F0F0F] rounded-md h-[100px]">
            <h3 className="text-base font-normal text-white/80">
              Current Balance
            </h3>
            {isLoadingData ? (
              <LoadingSpinner />
            ) : (
              <p className="text-3xl font-bold text-white">
                ${currentBalance.toFixed(2)}
              </p>
            )}
          </div>
          <div className="flex flex-col items-center justify-center  w-full bg-[#0F0F0F] rounded-md h-[100px]">
            <h3 className="text-base font-normal text-white/80">Live PNL</h3>
            {isLoadingData ? (
              <LoadingSpinner />
            ) : (
              <span>
                {livePNLData && livePNLData > 0 ? (
                  <p className="text-3xl font-bold text-[#07F2C7]">
                    + ${livePNLData}
                  </p>
                ) : livePNLData && livePNLData < 0 ? (
                  <p className="text-3xl font-bold text-rose-500">
                    - ${Math.abs(livePNLData)}
                  </p>
                ) : (
                  <p className="text-3xl font-bold text-white">$0</p>
                )}
              </span>
            )}
          </div>

          <div className="flex flex-col items-center justify-center  w-full bg-[#0F0F0F] rounded-md h-[100px]">
            <h3 className="text-base font-normal text-white/80">Accuracy</h3>
            <p className="text-3xl font-bold text-[#07F2C7]">
              {profitTrades.length > 0
                ? ((profitTrades.length / totalTrades.length) * 100).toFixed(2)
                : 0}
              %
            </p>
          </div>

          <div className="flex flex-col items-center justify-center  w-full bg-[#0F0F0F] rounded-md h-[100px]">
            <h3 className="text-base font-normal text-white/80">
              Win/Loss ratio
            </h3>
            <p className="text-3xl font-bold text-[#07F2C7]">
              {(profitAverage / Math.abs(lossTradeAverage)).toFixed(2) === "NaN"
                ? 0
                : (profitAverage / Math.abs(lossTradeAverage)).toFixed(2)}
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 mt-8 w-full">
          <div className="flex  space-x-2 md:space-x-10   h-[50px] px-3 ">
            <div
              onClick={() => setPnlTab("weekly")}
              className={` ${
                pnlTab === "weekly"
                  ? "text-[#07F2C7]  border-[#07F2C7]"
                  : "border-transparent"
              } flex items-center border-b-[3px] text-sm md:text-[16px] font-medium text-gray-400  cursor-pointer transition  duration-300 ease-in `}
            >
              Weekly PNL
            </div>
            <div
              onClick={() => setPnlTab("monthly")}
              className={` ${
                pnlTab === "monthly"
                  ? "text-[#07F2C7]  border-[#07F2C7]"
                  : "border-transparent"
              } flex items-center border-b-[3px] text-sm md:text-[16px] font-medium text-gray-400  cursor-pointer transition  duration-300 ease-in `}
            >
              Monthly PNL
            </div>
            <div
              onClick={() => setPnlTab("allTime")}
              className={` ${
                pnlTab === "allTime"
                  ? "text-[#07F2C7]  border-[#07F2C7]"
                  : "border-transparent"
              } flex items-center border-b-[3px] text-sm md:text-[16px] font-medium text-gray-400  cursor-pointer transition  duration-300 ease-in `}
            >
              All Time PNL
            </div>
          </div>
          {pnlTab === "weekly" && (
            <PnlChart data={pnlDataWeekly && pnlDataWeekly} label="Pnl" />
          )}
          {pnlTab === "monthly" && (
            <PnlChartMonthly
              data={pnlDataMonthlyChartData && pnlDataMonthlyChartData}
              label="Pnl"
            />
          )}
          {pnlTab === "allTime" && (
            <PnlChart
              data={pnlDataAllTime && pnlDataAllTime}
              label="Pnl"
            />
          )}
        </div>

        <div className="grid grid-cols-1 gap-4 mt-4 w-full ">
          <AccuracyChart
            data={accuracyChartData && accuracyChartData}
            label="Accuracy"
          />
        </div>
      </div>
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(Dashboard));
