import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { auth } from "./firebase";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordReset = (e) => {
    e.preventDefault();

    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setSuccess(true);
        setError(null);
      })
      .catch((error) => {
        setError(error.message);
        setSuccess(false);
      });
  };

  return (
    <div className="max-w-screen-xl w-full  relative rounded-lg font-Poppins">
      <button
        onClick={() => props.setOpenForgotPassword(false)}
        className="h-10 w-10 flex flex-row justify-center items-center rounded-full   text-white absolute top-0 left-0 m-1 font-bold hover:text-[#07F2C7]  ease-linear duration-150 cursor-pointer"
      >
        <div>
          <IoIosArrowBack size={16} className="mr-1" />
        </div>
        <div className="hidden md:flex text-base ">Back</div>
      </button>

      <div className="py-8">
        <div className="mt-8 font-Poppins md:text-2xl text-xl font-semibold pb-3 text-white">
          Forgot Password
        </div>
        {success && (
          <p className="font-Poppins text-xl">
            Password reset email sent successfully.
          </p>
        )}
        {error && <p>{error}</p>}
        <div
          className={
            "w-[100%] md:w-[100%] rounded-[8px]  text-left py-3 text-gray-500"
          }
        >
          <input
            type="email"
            className={
              "w-[100%] text-left py-3 bg-white/10 text-gray-100  rounded-[6px] border-[1px] border-white/10 px-3 text-base focus:outline-none focus:border-[1px] focus:border-white/20"
            }
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div>
          <button
            onClick={handlePasswordReset}
            className={`mt-4 flex flex-row items-center justify-center py-2.5 w-full mb-3 rounded-[8px] bg-[#07F2C7] text-black font-semibold hover:bg-[#07F2C7]/90 ease-linear duration-150`}
          >
            Reset Password
          </button>
        </div>
      </div>
      {error && (
        <div className="bg-white flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-bold ml-2 -mt-0.5">
            {error}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
