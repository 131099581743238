import React, { useState, useEffect } from "react";
import ForexLogo from "../assests/ForexLogo.svg";
import { connect, useDispatch } from "react-redux";
import { loginAction, loginFailedAction } from "../store/actions/AuthActions";

const AdminLogin = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.errorMessage != null) {
      setError(props.errorMessage);
      loginFailedAction(dispatch);
    }
  }, [props.errorMessage, dispatch]);

  const signInWithEmail = async () => {
    setLoading(true);
    if (!email || !password) {
      setError("Email and Password are required.");
      setLoading(false);
    } else {
      setError(null);
      await dispatch(loginAction(email, password));
      setLoading(true);
    }
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
      const toRef = setTimeout(() => {
        setLoading(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [loading]);
  return (
    <div
      className={` bg-hero min-h-screen w-full flex items-center justify-center`}
    >
      <div className="flex w-full justify-center items-center px-4 md:px-20 lg:px-0">
        <div
          className={` backdrop-blur-md bg-white/10 rounded-lg w-full lg:w-1/3 flex flex-col justify-center items-center px-4 py-10 md:px-12 md:py-16`}
        >
          <img src={ForexLogo} alt="Forex Logo" />{" "}
          <div className="text-2xl font-semibold text-white py-4">
            Admin Login
          </div>
          <div
            className={
              "w-[100%] md:w-[100%]  text-left py-2 text-gray-100 mt-8"
            }
          >
            <input
              type="email"
              className={
                "w-[100%] py-3 text-left bg-white/10  text-gray-100  rounded-[6px] border-[1px] border-white/10 px-3 text-base focus:outline-none focus:border-[1px] focus:border-white/20  "
              }
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div
            className={
              "w-[100%] md:w-[100%] mt-1  text-left py-2 text-gray-100"
            }
          >
            <input
              type="password"
              className={
                "w-[100%] text-left  py-3 bg-white/10 text-gray-100 rounded-[6px] border-[1px] border-white/10 px-3 text-base focus:outline-none focus:border-[1px] focus:border-white/20  "
              }
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>{" "}
          <button
            onClick={signInWithEmail}
            disabled={!email || !password}
            className="mt-8 flex flex-row items-center justify-center py-2.5 w-full mb-3 rounded-[8px] bg-[#07F2C7] text-white font-semibold hover:bg-[#07F2C7]/90 ease-linear duration-150"
          >
            <div className=" select-none text-black">
              {loading === true ? "Authenticating ......" : "Login"}
            </div>
          </button>
          {error ? (
            <div className="w-96 bg-white flex p-2 mt-2">
              <div className="text-[#D1433A] text-base font-bold -mt-0.5">
                {error ? error : ""}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    errorMessage: state.auth.errorMessage,
  };
};

export default connect(mapStateToProps)(AdminLogin);
