import React, { useState, useEffect } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const SignUpEmail = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const [error, setError] = useState("");

  const signUpWithEmail = () => {
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (response) => {
        if (response) {
          const id = uuidv4();
          const formRequestUserAccount = {
            id: id,
            username: response.additionalUserInfo?.profile?.name
              ? response.additionalUserInfo.profile.name
              : null,
            email: email ? email : "",
          };

          const accountResponse = await fetch(
            "https://forex.server.sypto.xyz/api/create-user-account",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(formRequestUserAccount),
            }
          );
          const userAccountData = await accountResponse.json();

          if (userAccountData?.status === 200) {
            toast.success("Signup Successful !!", {
              duration: 3000,
            });
          } else {
            toast.success("Signup Failed !!", {
              duration: 3000,
            });
          }
          props.setSignUpMethodOpen(false);
        }
      })
      .catch((err) => {
        setErrorShow(true);
        setError("The email address is already in use by another account");
      });
  };

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 8000);
    }
  }, [errorShow]);

  return (
    <div className="max-w-screen-xl w-full relative rounded-lg font-Poppins">
      <button
        onClick={() => props.setSignUpMethodOpen(false)}
        className="h-10 w-10 flex flex-row justify-center items-center rounded-full  text-white absolute top-0 left-0  m-1 font-bold hover:text-[#07F2C7]  ease-linear duration-150 cursor-pointer"
      >
        <div>
          <IoIosArrowBack size={16} className=" mr-1" />
        </div>
        <div className="hidden md:flex text-base ">Back</div>
      </button>

      <div className="py-8">
        <div className={"w-[100%] md:w-[100%] mt-4 text-left py-3"}>
          <input
            type="email"
            className={
              "w-[100%] py-3 bg-white/10 text-gray-100  rounded-[6px] px-3 border-[1px] border-white/10 text-base focus:outline-none focus:border-[1px] focus:border-white/20"
            }
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className={"w-[100%] md:w-[100%] text-left py-3"}>
          <input
            type="password"
            className={
              "w-[100%] py-3 bg-white/10 text-gray-100  rounded-[6px] px-3 border-[1px] border-white/10 text-base focus:outline-none focus:border-[1px] focus:border-white/20"
            }
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={signUpWithEmail}
            className={`mt-8 flex flex-row items-center justify-center py-2.5 w-full mb-3 rounded-[8px] bg-[#07F2C7] text-black font-semibold hover:bg-[#07F2C7]/90 ease-linear duration-150`}
          >
            SignUp with Email
          </button>
        </div>
      </div>
      {errorShow === true ? (
        <div className="flex p-2 mt-2">
          <div className="text-[#D1433A] text-base font-bold ml-2 -mt-0.5">
            {error}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SignUpEmail;
