import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Bar } from "react-chartjs-2";
import Moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  BarController,
  Title,
  Tooltip,
  Legend
);

const options = {
  // responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: true,
        drawBorder: true, // Display X axis border
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
    y: {
      grid: {
        display: true,
        drawBorder: true, // Display Y axis border
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
  },
};
const divStyle = {
  heading: "ext-xl text-lg font-bold leading-4 text-[#333333] ",
};
const PnlChartMonthly = ({ data, label }) => {
  const DateLable = (data) => {
    let n = data.length;
    let m = 3;
    let Date = [];
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < m; j++) {
        if (i > 0 && j === 0) {
          const one = data[i][j];
          Date.push(one);
        }
      }
    }
    return Date;
  };

  const sycrioData = (data) => {
    let n = data.length;
    let m = 3;
    let syp = [];
    for (let i = 0; i < n; i++) {
      for (let j = 0; j < m; j++) {
        if (i > 0 && j === 1) {
          syp.push(data[i][j]);
        }
      }
    }
    return syp;
  };

  const data1 = {
    labels: DateLable(data),
    datasets: [
      {
        label: ` ${label} `,
        data: sycrioData(data),
        borderColor: data
          .slice(1)
          .map((entry) =>
            entry[1] > 0 ? "#07f2c7" : entry[1] < 0 ? "#fda4af" : "#e5e5e5"
          ),
        backgroundColor: data
          .slice(1)
          .map((entry) =>
            entry[1] > 0 ? "#07f2c733" : entry[1] < 0 ? "#dc2626" : "#737373"
          ),
        tension: 0.3,
        borderWidth: 3,
        radius: 0,
        fill: true,
      },
    ],
  };

  return (
    <section style={{ height: "100%" }}>
      <section className={`flex justify-between mx-3`}>
        <div className={`d-flex ${divStyle.heading}`}>
          <a
            data-for="main"
            data-tip="Sypto’s vs bitcoin Cumulative performance chart"
            data-iscapture="true"
          >
            <AiOutlineInfoCircle style={{ marginLeft: 5 }} />
          </a>
        </div>
      </section>
      <section className="m-2">
        {data === "undefined" && (
          <div
            className="d-flex  justify-content-center align-items-center"
            style={{ height: "10vh" }}
          >
            <div
              style={{ fontSize: "30px" }}
              className="d-block fw-bold text-center color-black"
            ></div>
          </div>
        )}
        <div style={{ position: "relative", height: "42vh", width: "100%" }}>
          <Bar data={data1} options={options} />
        </div>
      </section>
    </section>
  );
};

export default PnlChartMonthly;
