import React, { useState, useEffect } from "react";
import ForexLogo from "../assests/ForexLogo.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../Login/firebase";
import { getUserAccountByEmail } from "../utils/data";
import toast from "react-hot-toast";
import { DEV_URL, LIVE_URL } from "../utils/data";
import { Link } from "react-router-dom";

const SingleSubscriberForm = ({ userAccountsData }) => {
  const [user] = useAuthState(auth);

  const [userSubscriberId, setUserSubscriberId] = useState("");

  const [loginCode, setLoginCode] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [serverName, setServerName] = useState("");

  const email = user && user.email;

  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    const fetchUserSubscriberId = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";
      setUserSubscriberId(id);
    };

    fetchUserSubscriberId();
  }, [email]);

  const delayFunction = async (accountId, state, userAccountData) => {
    console.log("Delayed function Called !!");
    toast.loading(
      "Waiting For Sever Connection !!",

      {
        style: {
          minWidth: "250px",
        },
        duration: 15000,
      }
    );
    toast.loading(
      "It will take couple of minutes !!",

      {
        style: {
          minWidth: "250px",
        },
        duration: 20000,
      }
    );

    // console.log("Starting First 3 min Timer !!");
    await new Promise((resolve) => setTimeout(resolve, 180000));

    // console.log("Ending First 3 min Timer !!");
    // console.log("Async function called after 180 seconds");
    const newUserData = await fetch(
      `${LIVE_URL}/api/fetch-sub-account/${accountId}`
    );
    const resultUserData = await newUserData.json();

    if (resultUserData?.status === 400) {
      console.log("Fetch Sub data Response Not Ok !!");
      toast.loading(
        "Server Connection will take some more time !!",

        {
          style: {
            minWidth: "250px",
          },
          duration: 10000,
        }
      );

      // console.log("Starting Second 3 min Timer !!");
      await new Promise((resolve) => setTimeout(resolve, 180000));

      // console.log("Ending Second 3 min Timer !!");

      // const newUserDataTwo = await getUserSubAccountByAccountId(accountId);
      const newUserDataTwo = await fetch(
        `${LIVE_URL}/api/fetch-sub-account/${accountId}`
      );
      const resultUserDataTwo = await newUserDataTwo.json();

      // console.log("Result User Data Two", resultUserDataTwo);

      if (resultUserDataTwo?.status === 200) {
        // console.log("Fetch Sub data second time Response Ok !!");

        const newUserSubAccountDB = {
          accountId: accountId,
          userId: userSubscriberId,
          subscriberId: userSubscriberId,
          state: state,
          ...userAccountData,
          ...resultUserDataTwo?.data,
        };
        // console.log(
        //   "New User Sub Account Data with two APIs -->",
        //   newUserSubAccountDB
        // );

        const resultAddSubAccountTwo = await fetch(
          `${LIVE_URL}/api/add-sub-account`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newUserSubAccountDB),
          }
        );
        const addSubAccountFirst = await resultAddSubAccountTwo.json();

        if (addSubAccountFirst?.status === 200) {
          // console.log("Sub Account Data Added Successfully!!");

          toast.success("Server Connection Successfully !!", {
            style: {
              minWidth: "250px",
            },
            duration: 6000,
          });
        } else {
          // console.log("Sub Account Data Added Failed!!");
          toast.error("Server Connection Failed !!", {
            style: {
              minWidth: "250px",
            },
            duration: 6000,
          });
        }

        return;
      }
    } else {
      const newUserSubAccountDB = {
        accountId: accountId,
        userId: userSubscriberId,
        subscriberId: userSubscriberId,
        state: state,
        ...userAccountData,
        ...resultUserData?.data,
      };

      // console.log(
      //   "New User Sub Account Data with two APIs -->",
      //   newUserSubAccountDB
      // );

      const resultAddSubAccountTwo = await fetch(
        `${LIVE_URL}/api/add-sub-account`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newUserSubAccountDB),
        }
      );

      const addSubAccountTwo = await resultAddSubAccountTwo.json();

      if (addSubAccountTwo?.status === 200) {
        console.log("Sub Account Data Two Added Successfully!!");
        toast.success("Server Connection Successfully !!", {
          style: {
            minWidth: "250px",
          },
          duration: 6000,
        });
      } else {
        // console.log("Sub Account Data Two Added Failed!!");
        toast.error("Server Connection Failed !!", {
          style: {
            minWidth: "250px",
          },
          duration: 6000,
        });
      }

      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!loginCode || !password || !name || !serverName) {
      setLoading(false);
      setError(true);
      setErrorMsg("All Fields Are Required.");
    } else {
      toast.loading(
        "Creating Account !!",

        {
          style: {
            minWidth: "250px",
          },
          duration: 4000,
        }
      );

      const formRequestBody = {
        userId: userSubscriberId,
        subscriberId: userSubscriberId,
        connectionStatus: "CONNECTING",
        email: email,
        login: loginCode,
        password: password,
        name: name,
        server: serverName,
        region: "new-york",
        platform: "mt5",
        type: "cloud-g2",
        magic: 0,
        copyFactoryRoles: ["SUBSCRIBER"],
      };

      // console.log("Form Request Body Single Subscriber---> ", formRequestBody);

      const response = await fetch(`${LIVE_URL}/api/create-sub-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBody),
      });

      if (response?.status === 200) {
        // Request was successful
        setLoading(false);
        setSuccess(true);
        const accountData = await response.json();

        const newUserAccountData = {
          password: formRequestBody.password,
          accountType: formRequestBody.type,
          region: formRequestBody.region,
        };

        // console.log(
        //   "Complete Data After Creating and Fetching Account with Copy Factory Role:",
        //   accountData
        // );

        toast.success("Account Created Sucessfully !!", {
          style: {
            minWidth: "250px",
          },
          duration: 4000,
        });

        delayFunction(
          accountData.accountId,
          accountData.state,
          newUserAccountData
        );
      } else {
        // Request failed
        console.error("Create Account request failed:", response.statusText);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setError(true);
      const toRef = setTimeout(() => {
        setError(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [error]);
  return (
    <div
      className={` bg-hero min-h-screen w-full flex items-center justify-center`}
    >
      <div className="flex w-full justify-center items-center mt-16 animate-slideInFromBottom transition duration-500 ease-in">
        {success === true ? (
          <div
            className={` backdrop-blur-md bg-white/10 rounded-lg w-[90%] md:w-[70%] lg:w-1/3 flex flex-col justify-center items-center px-4 md:px-12 py-6 md:py-16`}
          >
            <img src={ForexLogo} alt="Forex Logo" />{" "}
            <div className="text-xl text-[#07F2C7] font-Poopins mt-4 text-center">
              Your account has been successfully connected.
            </div>
            <Link
              to="/"
              className="bg-[#07F2C7] text-white mt-8 px-8 py-2 rounded-lg"
            >
              {" "}
              Go to Portfolio
            </Link>
          </div>
        ) : (
          <div
            className={` backdrop-blur-md bg-white/10 rounded-lg w-[90%] md:w-[70%] lg:w-1/3 flex flex-col justify-center items-center px-4 md:px-12 py-6 md:py-16`}
          >
            <img src={ForexLogo} alt="Forex Logo" />{" "}
            <div className="font-Montserrat font-bold text-[26px] text-white py-4">
              Connect Account
            </div>
            {error === true ? (
              <p className="text-[#FF0000] text-base font-Poppins">
                {errorMsg}
              </p>
            ) : null}
            <div
              className={"w-[100%] md:w-[100%]  text-left py-2 text-gray-100"}
            >
              <input
                type="text"
                className={
                  "w-[100%] py-3 text-left bg-white/10  text-gray-100  rounded-[6px] px-3 text-base "
                }
                placeholder="Login Code"
                value={loginCode}
                onChange={(e) => setLoginCode(e.target.value)}
              />
            </div>
            <div
              className={
                "w-[100%] md:w-[100%] mt-1  text-left py-2 text-gray-100"
              }
            >
              <input
                type="text"
                className={
                  "w-[100%] text-left  py-3 bg-white/10 text-gray-100 rounded-[6px] px-3  "
                }
                placeholder="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div
              className={
                "w-[100%] md:w-[100%] mt-1  text-left py-2 text-gray-100"
              }
            >
              <input
                type="text"
                className={
                  "w-[100%] text-left  py-3 bg-white/10 text-gray-100 rounded-[6px] px-3  "
                }
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div
              className={
                "w-[100%] md:w-[100%] mt-1  text-left py-2 text-gray-100"
              }
            >
              <input
                type="text"
                className={
                  "w-[100%] text-left  py-3 bg-white/10 text-gray-100 rounded-[6px] px-3  "
                }
                placeholder="Server"
                value={serverName}
                onChange={(e) => setServerName(e.target.value)}
              />
            </div>{" "}
            <button
              onClick={handleSubmit}
              className="mt-8 flex flex-row items-center justify-center py-2.5 w-full mb-3 rounded-[8px] bg-[#07F2C7] text-white font-bold hover:bg-[#07F2C7]/90 ease-linear duration-150"
            >
              <div className="ml-4 select-none text-black">
                {loading === true ? "Connecting......." : "Connect Account"}
              </div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleSubscriberForm;
