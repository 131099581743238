import React, { useState } from "react";
import { DEV_URL, LIVE_URL } from "../utils/data";
import { XIcon } from "lucide-react";

import Modal from "../components/modal";
import toast from "react-hot-toast";

export default function SubscribeStrategyCard({
  index,
  subscriberAccountId,
  subscriberUserId,
  adminUserId,
  strategyCreatorId,
  strategyId,
  strategyName,
  strategyDescription,
  strategySubscriberList
}) {
  const [subscriberName, setSubscriberName] = useState("");
  const [subscriberId, setSubscriberId] = useState(subscriberAccountId);
  const [showSubscriberModal, setShowSubscriberModal] = useState(false);

  const isUserSubscribedToAStrategy = strategySubscriberList?.includes(subscriberAccountId);
  
  const handleShowSubscriberModal = () => {
    setShowSubscriberModal(true);
  };
  const handleCloseSubscriberModal = () => {
    setShowSubscriberModal(false);
  };

  // console.log("SUbscriner ACC ID from Card ----",subscriberAccountId)

  const handleUpdateUserIdAfterStrategySubscription = async (
    strategyCreatorId,
    strategyId,
    subscriberUserId,
    subscriberAccountId
  ) => {
    const requestBody = {
      userId: strategyCreatorId,
      strategyId:strategyId,
      subscriberId: subscriberUserId,
      subscriberList:[subscriberAccountId]
    };
    const updateUserIdData = await fetch(`${LIVE_URL}/api/update-user-id`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const newUserData = await updateUserIdData.json();
    return newUserData;
  };

  const handleSubscribeStrategy = async (
    strategyId,
    strategyCreatorId,
    subscriberUserId,
    subscriberAccountId,
  ) => {
    const subId = subscriberId;

    // console.log("sub Id", subId);
    // console.log("Strategy  Id", strategyId);
    // console.log("Strategy Admin Id", adminUserId);
    // console.log("Strategy Subscriber Id", subscriberUserId);
    // console.log("subscriber AccountIdId", subscriberAccountId);

    const formRequestBody = {
      name: subscriberName,
      subscriptions: [
        {
          strategyId: strategyId,
          multiplier: 1,
          tradeSizeScaling: {
            mode: "none",
          },
        },
      ],
    };

    // console.log(formRequestBody);

    const res = await fetch(
      `${LIVE_URL}/api/subscribe-strategy/${subscriberId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formRequestBody),
      }
    );
    const data = await res.json();

    if (data?.status === 200) {
      console.log("Strategy Subscribed  Successfully!!!", data);

      const updatedUserId = await handleUpdateUserIdAfterStrategySubscription(
        strategyCreatorId,
        strategyId,
        subscriberUserId,
        subscriberAccountId
      );

      // console.log("after Subscribe Data",updatedUserId)

      if (updatedUserId?.status === 200) {
        toast.success("Strategy Subscribed Successfully !!", {
          style: {
            minWidth: "250px",
          },
          duration: 5000,
        });
      }
    } else {
      console.log("Strategy Subscribed  Failed!!!", data);
      toast.success("Strategy Subscription Failed !!", {
        style: {
          minWidth: "250px",
        },
        duration: 5000,
      });
    }

    setSubscriberId("");
    setSubscriberName("");
    handleCloseSubscriberModal();
  };

  return (
    <>
      {/* For Subscribe Strategy */}

      {showSubscriberModal && (
        <Modal>
          <div className="z-10 relative flex items-center justify-center bg-[#17181B] w-[620px] h-[380px] rounded-md  px-8">
            <button
              onClick={handleCloseSubscriberModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-10 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>
            <div className="flex flex-col justify-center  space-y-8 w-[510px] h-full">
              <div className="grid gap-6">
                <div className="grid grid-cols-1 gap-3">
                  <label
                    className="flex items-center label  text-white"
                    htmlFor="subscriberName"
                  >
                    Subscriber Name
                  </label>
                  <input
                    required
                    id="subscriberName"
                    name="subscriberName"
                    placeholder="Subscriber Name"
                    type="text"
                    value={subscriberName}
                    onChange={(e) => setSubscriberName(e.target.value)}
                    className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  />
                </div>
                <div className="grid grid-cols-1 gap-3">
                  <label
                    className="flex items-center label  text-white"
                    htmlFor="accountId"
                  >
                    Subscriber AccountId
                  </label>
                  <input
                    required
                    id="accountId"
                    name="accountId"
                    placeholder="Subscriber AccountId"
                    type="text"
                    value={subscriberId}
                    onChange={(e) => setSubscriberId(e.target.value)}
                    className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <button
                    disabled={!subscriberId}
                    onClick={() =>
                      handleSubscribeStrategy(
                        strategyId,
                        strategyCreatorId,
                        subscriberUserId,
                        subscriberAccountId
                      )
                    }
                    className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-semibold leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Subscribe Strategy
                  </button>

                  <div
                    onClick={handleCloseSubscriberModal}
                    className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div
        className={`cursor-pointer  relative bg-mesh bg-no-repeat group rounded-md  flex flex-col min-h-[220px]  justify-center py-4 lg:py-6   bg-[#0F0F0F] shadow-[0_3px_10px_rgb(0,0,0,0.2)]  hover:shadow-[0_3px_10px_rgb(7,242,199,0.3)] hover:-translate-y-3 animate-slideInFromBottom transition duration-100 ease-in `}
      >
        <div className={`flex flex-col flex-1  space-y-4 `}>
          <div className="flex items-center border-l-4 border-transparent group-hover:border-[#07F2C7] transition duration-300 ease-in">
            <p className="text-[22px] font-semibold capitalize text-transparent bg-clip-text bg-gradient-to-b from-white to-white/20 px-6">
              {strategyName}
            </p>
          </div>

          <div className="flex  ">
            <p className="text-md font-medium capitalize text-[#858E9D] px-8">
              {strategyDescription}
            </p>
          </div>
          </div>
          <div className="px-8">
            {isUserSubscribedToAStrategy ? (
              <button
                className={`mt-2 flex cursor-pointer justify-center rounded-md  px-6 py-2 text-lg font-semibold leading-6 text-black  group-hover:text-black shadow-sm  bg-[#07F2C7] group-hover:bg-[#07F2C7]/80 `}
              >
                Subscribed
              </button>
            ) : (
              <button
                onClick={handleShowSubscriberModal}
                className={`mt-2 flex cursor-pointer justify-center rounded-md  px-6 py-2 text-lg font-medium leading-6 text-[#07F2C7]/80 border-2 border-[#07F2C7]/80 group-hover:text-black shadow-sm  bg-transparent group-hover:bg-[#07F2C7] `}
              >
                Subscribe Now
              </button>
            )}
          </div>
        
      </div>
    </>
  );
}
