import React from "react";

import { RiLogoutCircleLine } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";
import { motion } from "framer-motion";

import { auth } from "../../Login/firebase";
import styles from "./dropdown.module.css";

function DropDown(props) {
  return (
    <div
      className={`flex-row ml-4 hidden lg:flex relative font-Poppins ${styles.dropdown}`}
    >
      <button
        className={`lg:flex hidden items-center mr-12 px-3 py-1 text-[#000] rounded-lg hover:text-[#000] hover:scale-110 active:scale-95 ease-linear duration-150`}
      >
        {props.user.displayName ? (
          <div className="w-10 h-10 rounded-full overflow-hidden ">
            <img src={props.user.photoURL} alt="" className="h-10" />
          </div>
        ) : (
          <div className="bg-[#0050A0] text-white  flex justify-center items-center w-10 h-10 rounded-full">
            M
          </div>
        )}

        <span className={`pl-2 ease-linear duration-150`}>
          <MdKeyboardArrowDown size={20} color="#ffffff" />
        </span>
      </button>
      <div
        className={`mt-1 rounded-lg bg-[#f7f7f7] shadow-lg   ${styles.dropdown_content}`}
      >
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, type: "spring" }}
          exit={{ y: -50, opacity: 0 }}
          className=" absolute right-14 top-0 bg-[#0F0F0F] flex flex-col rounded-lg border-[1px] border-[#000000]/20"
        >
          <div className="flex flex-row bg-[#f7f7f7] p-[12px] m-3 rounded-lg  select-none cursor-pointer ease-linear duration-150">
            {/*----------------Avatar--------------- */}
            {props.user.displayName ? (
              <div className="w-10 h-10 rounded-full overflow-hidden">
                <img src={props.user.photoURL} alt="" className="h-10" />
              </div>
            ) : (
              <div className="bg-[#0050A0] text-white flex justify-center items-center w-10 h-10 rounded-full overflow-hidden">
                M
              </div>
            )}

            {/*---------------User Info---------------- */}
            <div className="ml-2 flex flex-col justify-start">
              {/*----------------Name------------------ */}
              {props.user.displayName ? (
                <div className="text-base text-black">
                  {props.user.displayName}
                </div>
              ) : (
                <div className="text-base text-black">
                  {props.user.phoneNumber}
                </div>
              )}
              {!props.user ? (
                <div className="text-xs text-white/50"></div>
              ) : (
                <div className="text-xs text-black">{props.user.email}</div>
              )}
              {/*----------------Email------------------ */}
            </div>
          </div>

          <div
            onClick={() => {
              auth.signOut();
            }}
            className="flex items-center px-2 py-2 rounded-md  mx-3 mb-3 text-white   hover:bg-red-500/10 hover:text-red-500 hover:font-bold font-medium  ease-linear duration-150 cursor-pointer"
          >
            <div>
              <RiLogoutCircleLine size={20} />
            </div>
            <div className="pl-3  ">Logout</div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default DropDown;
