export default function FormatDateTime(date) {
  const originalTime = date;
  const parsedTime = new Date(originalTime);
  
  const options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit',
    hour12: false,  // Set to false to use 24-hour time format
  };
  
  const formattedTime = parsedTime.toLocaleString('en-IN', options);
  

  

  return formattedTime;
}
  