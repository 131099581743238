import React, { useState } from "react";
import { XIcon, CheckIcon } from "lucide-react";
import { filterLivePositions } from "../../utils/filterFunctions";

export default function FilterLivePositionsModal({
  showFilter,
  handleCloseFilter,
  currentTab,
  filteredUserLivePositionData,
  userOriginalLivePositions,
  setFilteredUserLivePositionData,
}) {
  const [livePositionsFilters, setLivePositionsFilters] = useState({
    account_name: "",
    strategy_name:"",
    symbol: "",
    position_type: "",
    volume: "",
    open_price: "",
    current_price: "",
    unrealized_profit: "",
    profit: "",
    reason: "",
    time: "",
  });

  const handleInputChange = (e, filterKey) => {
    setLivePositionsFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: e.target.value,
    }));
  };

  const handleApplyLivePositionsFilters = () => {
    const filtersToApply = {};

    for (const [key, value] of Object.entries(livePositionsFilters)) {
      if (value !== null && value !== "") {
        filtersToApply[key] = value;
      }
    }

    filterLivePositions(
      filtersToApply,
      currentTab,
      userOriginalLivePositions,
      setFilteredUserLivePositionData
    );
  };

  return (
    <tr
      className={` h-[70px]  bg-[#07F2C7]/20 border-gray-800 
         ${
           showFilter
             ? "animate-slideDown transition duration-500 ease-in"
             : "animate-slideUp transition duration-500 ease-in"
         } `}
    >
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Account Name"
          value={livePositionsFilters.account_name}
          className=" py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "account_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Strategy Name"
          value={livePositionsFilters.strategy_name}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "strategy_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Symbol"
          value={livePositionsFilters.symbol}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "symbol")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Position Type"
          value={livePositionsFilters.position_type}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "position_type")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Volume"
          value={livePositionsFilters.volume}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "volume")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Open Price"
          value={livePositionsFilters.open_price}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "open_price")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Current Price"
          value={livePositionsFilters.current_price}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "current_price")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Unrealized Profit"
          value={livePositionsFilters.unrealized_profit}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "unrealized_profit")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Profit"
          value={livePositionsFilters.order_id}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "profit")}
        />
      </th>
      {/* <th className=" px-1  ">
        <input
          type="text"
          placeholder="Reason"
          value={livePositionsFilters.reason}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "reason")}
        />
      </th> */}

      <th className=" px-1  ">
        <span className="flex items-center space-x-4">
          <input
            type="date"
            placeholder="Time"
            value={livePositionsFilters.time}
            className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
            onChange={(e) => handleInputChange(e, "time")}
          />
          <span
            onClick={handleApplyLivePositionsFilters}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
             <CheckIcon className="h-5 w-5 text-[#07F2C7] " />
          </span>
          <span
            onClick={handleCloseFilter}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
             <XIcon className="h-5 w-5 text-rose-500 " />
          </span>
        </span>
      </th>
    </tr>
  );
}
