import { isSameDay, parseISO } from "date-fns";

export const filterTradeHistory = (
  filters,
  currentTab,
  userOriginalTradeHistory,
  setFilteredUserTradeHistory
) => {
  if (currentTab === "trades-history") {
    const filteredTradeHistoryData = [...userOriginalTradeHistory].filter(
      (trade) => {
        // Check if the filters object is empty
        if (Object.keys(filters).length === 0) {
          return true; // No filters, include all trades
        }

        return Object.entries(filters).every(([key, value]) => {
          if (value === "") {
            return true; // If the filter value is empty, include the trade
          }

          if (key === "time") {
            // Check for date match using date-fns
            const filterDate = new Date(value);
            const tradeDate = parseISO(trade[key]);
            return isSameDay(filterDate, tradeDate);
          }

          // Case-insensitive string matching for other fields
          return String(trade[key])
            .toLowerCase()
            .includes(String(value).toLowerCase());
        });
      }
    );

    setFilteredUserTradeHistory(filteredTradeHistoryData);
  }
};

export const filterOrderHistory = (
  filters,
  currentTab,
  userOriginalOrdersHistory,
  setFilteredUserOrderHistory
) => {
  if (currentTab === "orders-history") {
    const filteredOrderHistoryData = [...userOriginalOrdersHistory].filter(
      (order) => {
        // Check if the filters object is empty
        if (Object.keys(filters).length === 0) {
          return userOriginalOrdersHistory; // No filters, include all orders
        }

        return Object.entries(filters).every(([key, value]) => {
          if (value === "") {
            return true; // If the filter value is empty, include the order
          }

          if (key === "time") {
            // Check for date match using date-fns
            const filterDate = new Date(value);
            const orderDate = parseISO(order[key]);
            return isSameDay(filterDate, orderDate);
          }

          // Case-insensitive string matching for other fields
          return String(order[key])
            .toLowerCase()
            .includes(String(value).toLowerCase());
        });
      }
    );

    setFilteredUserOrderHistory(filteredOrderHistoryData);
  }
};

export const filterLivePositions = (
  filters,
  currentTab,
  userOriginalLivePositions,
  setFilteredLivePositions
) => {
  if (currentTab === "positions") {
    const filteredLivePositionsData = [...userOriginalLivePositions].filter(
      (position) => {
        // Check if the filters object is empty
        if (Object.keys(filters).length === 0) {
          return userOriginalLivePositions; // No filters, include all live positions
        }

        return Object.entries(filters).every(([key, value]) => {
          if (value === "") {
            return true; // If the filter value is empty, include the live position
          }

          if (key === "time") {
            // Check for date match using date-fns
            const filterDate = new Date(value);
            const positionDate = parseISO(position[key]);
            return isSameDay(filterDate, positionDate);
          }

          // Case-insensitive string matching for other fields
          return String(position[key])
            .toLowerCase()
            .includes(String(value).toLowerCase());
        });
      }
    );

    setFilteredLivePositions(filteredLivePositionsData);
  }
};

export const filterOpenOrders = (
  filters,
  currentTab,
  userOriginalOpenOrders,
  setFilteredOpenOrders
) => {
  if (currentTab === "open-orders") {
    const filteredOpenOrdersData = [...userOriginalOpenOrders].filter(
      (order) => {
        // Check if the filters object is empty
        if (Object.keys(filters).length === 0) {
          return userOriginalOpenOrders; // No filters, include all open orders
        }

        return Object.entries(filters).every(([key, value]) => {
          if (value === "") {
            return true; // If the filter value is empty, include the open order
          }

          if (key === "time") {
            // Check for date match using date-fns
            const filterDate = new Date(value);
            const orderDate = parseISO(order[key]);
            return isSameDay(filterDate, orderDate);
          }

          // Case-insensitive string matching for other fields
          return String(order[key])
            .toLowerCase()
            .includes(String(value).toLowerCase());
        });
      }
    );

    setFilteredOpenOrders(filteredOpenOrdersData);
  }
};
