import React, { useState, useEffect } from "react";
import "./App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { connect, useDispatch } from "react-redux";

import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { auth } from "./Login/firebase";
import LoginPage from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import DashboardLive from "./Dashboard/Live/DashboardLive";
import DashboardHistory from "./Dashboard/History/DashboardHistory";
import Users from "./Dashboard/Users/Users";
import AddStrategy from "./Dashboard/AddStrategy/AddStrategy";
import OrderPlacement from "./Dashboard/OrderPlacement/OrderPlacement";
import AdminLogin from "./Login/adminLogin";

//user
import SingleSubscriber from "./Subscriber/SingleSubscriber";
import UserPortfolio from "./Subscriber/UserPortfolio/userPortfolio";
import LoadingSpinner from "./components/loading-spinner";
import ForexLandingPage from "./components/ForexLandingPage";
import io from "socket.io-client";

import {
  getAllUserLivePositions,
  getAllUserOpenOrders,
  getUserAccountByEmail,
} from "./utils/data";


const LIVE_URL = "https://forex.websocket.sypto.xyz";
const DEV_URL = "http://localhost:3001";

  


function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);
  const [user] = useAuthState(auth);

  const [loading, setLoading] = useState(true);


  const newUser = localStorage.getItem("userDetails");
  const userData = newUser && JSON.parse(newUser);
  const userDeatils = userData && userData.response;
  const email = userDeatils && userDeatils[0]["email"];

  const [userId, setUserId] = useState("");
  const [userLivePositionData, setUserLivePositionsData] = useState([]);
  const [userOpenOrdersData, setUserOpenOrdersData] = useState([]);
  const [filteredUserLivePositionData, setFilteredUserLivePositionData] =
    useState(userLivePositionData);
  const [filteredUserOpenOrdersData, setFilteredUserOpenOrdersData] =
    useState(userOpenOrdersData);
    const [isPositionsDataLoading,setIsPositionsDataLoading] = useState(false);

    
    useEffect(() => {
      setIsPositionsDataLoading(true);
      const fetchLiveData = async () => {
        
        const userAccountData = await getUserAccountByEmail(email);
  
        const id = userAccountData?.data[0]?.id
          ? userAccountData?.data[0]?.id
          : "";
  
        setUserId(id);
        // setUserOpenOrdersData(openOrdersData);
        // setFilteredUserOpenOrdersData(openOrdersData);
        // setUserLivePositionsData(livePositionsData);
        // setFilteredUserLivePositionData(livePositionsData);
  
        // const userLivePositions = await getAllUserLivePositions(id);
        // console.log("user Live Positions",userLivePositions?.data)
  
        // const userOpenOrders = await getAllUserOpenOrders(id);
        // console.log("user Open Orders",userOpenOrders?.data);
       
      };
  
      fetchLiveData();
      
      fecthLiveDataFromWebSocket();
      setIsPositionsDataLoading(false);
      
    }, [userId]);

    const fecthLiveDataFromWebSocket = () => {
    
      const socket = io(`${LIVE_URL}`);
  
      // Event listener for when the connection is established
      socket.on("connect", () => {
        console.log("Connected to WebSocket server");
  
        // Emit an event with user information (name and age)
        socket.emit("sendUserInfo", { userId });
      });
  
      // Event listener for Live Positions data is received from the server
      socket.on("fetchLivePositions", (positionsData) => {
        console.log("Received positionsData update:", positionsData);
        setUserLivePositionsData(positionsData?.reverse());
        setFilteredUserLivePositionData(positionsData?.reverse());
        setIsPositionsDataLoading(false);
      });
  
      // Event listener for Open Orders data is received from the server
      socket.on("fetchOpenOrders", (openOrdersData) => {
        console.log("Received Open Orders update:", openOrdersData);
        setUserOpenOrdersData(openOrdersData?.reverse());
        setFilteredUserOpenOrdersData(openOrdersData?.reverse());
      });
      
      // Event listener for Live Positions data is received from the server
      socket.on("fetchOrdersHistory", (ordersHistory) => {
        console.log("New ordersHistory update:", ordersHistory);
      });
  
      // Event listener for Open Orders data is received from the server
      socket.on("fetchTradesHistory", (tradesHistory) => {
        console.log("New tradesHistory update:", tradesHistory);
      });
      
    };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen bg-black   mx-auto w-[250px] ml-16 md:ml-28 lg:ml-0 md:w-[600px] lg:w-full  h-[200px] lg:h-[400px] items-center justify-center text-white text-lg ">
        <LoadingSpinner />
      </div>
    ); // You can replace this with a loading spinner or splash screen component
  }

  return (
    <div className="flex flex-col min-h-screen font-poppins text-[#40414f] w-full mx-auto ">
      <Router>
        <Switch>
        <Route path="/admin/test" exact>
            {props.isAuthenticated ? <ForexLandingPage /> : <AdminLogin />}
          </Route>
          <Route path="/admin/add-strategy" exact>
            {props.isAuthenticated ? <AddStrategy /> : <AdminLogin />}
          </Route>
          <Route path="/admin/order-placement" exact>
            {props.isAuthenticated ? <OrderPlacement /> : <AdminLogin />}
          </Route>
          <Route path="/admin/users" exact>
            {props.isAuthenticated ? <Users /> : <AdminLogin />}
          </Route>
          <Route path="/admin/history" exact>
            {props.isAuthenticated ? <DashboardHistory /> : <AdminLogin />}
          </Route>
          <Route path="/admin/live" exact>
            {props.isAuthenticated ? 
            <DashboardLive
            userId={userId} 
            userLivePositionData={userLivePositionData}
            userOpenOrdersData={userOpenOrdersData}
            filteredUserLivePositionData={filteredUserLivePositionData}
            setFilteredUserLivePositionData={setFilteredUserLivePositionData}
            filteredUserOpenOrdersData={filteredUserOpenOrdersData}
            setFilteredUserOpenOrdersData={setFilteredUserOpenOrdersData}
            isPositionsDataLoading={isPositionsDataLoading}
            
            /> : <AdminLogin />}
          </Route>
          <Route path="/admin" exact>
            {props.isAuthenticated ? <Dashboard userLivePositionData={userLivePositionData} /> : <AdminLogin />}
          </Route>

          <Route path="/strategy" exact>
            {user ? <SingleSubscriber /> : <LoginPage />}
          </Route>

          <Route path="/user">{user ? <UserPortfolio  
             /> : <LoginPage />}</Route>
          <Route path="/"><ForexLandingPage user={user}  /> </Route>
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);
