import React , {useState,useEffect} from "react";
import DashboardLayout from "../DashboardLayout";
import OrderPlacementForm from "./OrderPlacementForm";
import {
  getAllUserSubAccountByUserId,
  getUserAccountByEmail,
  
} from "../../utils/data";

export default function OrderPlacementPage() {

  const user = localStorage.getItem("userDetails");
  const userData = user && JSON.parse(user);
  const userDetails = userData && userData.response;
  const email = userDetails && userDetails[0]["email"];

  const [userId, setUserId] = useState("");
  const [providerAccountList, setProviderAccountList] = useState("");
  const [providerAccountId, setProviderAccountId] = useState("");

  useEffect(() => {
    const fetchUserData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";
      setUserId(id);

      const subAccounts = await getAllUserSubAccountByUserId(id);

      const providerAccountListData = subAccounts.data?.filter(
        (acc) => acc.copy_factory_roles[0] === "PROVIDER"
      );
      const providerAccId = providerAccountListData[0]?.account_id ? providerAccountListData[0]?.account_id : "";
      setProviderAccountId(providerAccId)
      setProviderAccountList(providerAccountListData);
    };

    fetchUserData();
  }, [userId]);
  return (
    <DashboardLayout>
      <div className="flex flex-col bg-[#17181B] lg:bg-black  lg:items-center lg:justify-center relative w-full min-h-full lg:min-h-full text-white lg:animate-slideInFromBottom transition duration-300 ease-in">
        <OrderPlacementForm providerAccountId={providerAccountId} providerAccountList={providerAccountList} />
      </div>
    </DashboardLayout>
  );
}
