import React, { useEffect, useState } from "react";
import DashboardLayout from "../DashboardLayout";
import CreateAccountForm from "./CreateAccountForm";
import UserTable from "./UserTable";

import {
  getAllUserSubAccountByUserId,
  getTradeStrategyByUserId,
  getUserAccountByEmail,
} from "../../utils/data";
import LoadingSpinner from "../../components/loading-spinner";

export default function UsersPage() {
  const user = localStorage.getItem("userDetails");
  const userData = user && JSON.parse(user);
  const userDeatils = userData && userData.response;
  const email = userDeatils && userDeatils[0]["email"];
  const [userId, setUserId] = useState("");
  const [userSubAccountsData, setUserSubAccountsData] = useState([]);
  const [tradeStrategyDataList, setTradeStrategyDataList] = useState([]);


  const [isUserAccountDataLoading, setIsUserAccountDataLoading] =
    useState(false);

  useEffect(() => {
    setIsUserAccountDataLoading(true);
    const fetchUserData = async () => {
      const userAccountData = await getUserAccountByEmail(email);

      const id = userAccountData?.data[0]?.id
        ? userAccountData?.data[0]?.id
        : "";
      setUserId(id);

      const subAccounts = await getAllUserSubAccountByUserId(id);

      // console.log("Sub Accounts Data =---->",subAccounts?.data)

      setUserSubAccountsData(subAccounts?.data);

      const tradeStrategyData = await getTradeStrategyByUserId(id);
      setTradeStrategyDataList(tradeStrategyData?.data);
      setIsUserAccountDataLoading(false);
    };

    fetchUserData();
  }, [userSubAccountsData]);

  return (
    <DashboardLayout>
      <div className="z-0 flex flex-col relative  w-full max-h-screen">
        <div className="flex ">
          <CreateAccountForm
            userId={userId}
            userSubAccounts={userSubAccountsData}
            tradeStrategyDataList={tradeStrategyDataList}
          />
        </div>

        {userSubAccountsData.length === 0 && !isUserAccountDataLoading ? (
          <div className="flex flex-col flex-1 items-center justify-center text-white">
            No Sub Accounts Found!!
          </div>
        ) : userSubAccountsData.length === 0 && isUserAccountDataLoading ? (
          <div className="flex flex-col flex-1 items-center justify-center text-white">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="flex flex-col flex-1 ">
            {userSubAccountsData.length > 0 && (
              <UserTable
                userId={userId}
                userSubAccounts={userSubAccountsData}
              />
            )}
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}
