import React from "react";

const style = {
  HeadingSubSmall: "text-xs md:text-xl font-bold leading-4 ml-2  mt-3 md:mt-4",
};

export default function ShowPnlDataComponent({ inputPnlValue, profitPercentage }) {
  return (
    <>
      {inputPnlValue && inputPnlValue > 0 ? (
        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
          <span className="text-3xl font-bold text-[#07F2C7]">
            + ${Number(inputPnlValue).toFixed(2)}
          </span>
          <span
            className={`${style.HeadingSubSmall}  text-3xl font-bold text-[#07F2C7] `}
          >
            {Number(profitPercentage).toFixed(2)}%
          </span>
        </div>
      ) : inputPnlValue && inputPnlValue < 0 ? (
        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
          <span className="text-3xl font-bold text-rose-500">
            - ${Math.abs(Number(inputPnlValue).toFixed(2))}
          </span>
          <span
            className={`${style.HeadingSubSmall}  text-3xl font-bold text-rose-500 `}
          >
            {Number(profitPercentage).toFixed(2)}%
          </span>
        </div>
      ) : (
        <div className="text-2xl lg:text-[34px]  font-bold font-Poppins">
          <span className="text-3xl font-bold text-white">0.00</span>
          <span
            className={`${style.HeadingSubSmall}  text-3xl font-bold text-white `}
          >
            0.00%
          </span>
        </div>
      )}
    </>
  );
}
