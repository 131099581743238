import React from "react";
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { logout } from "../store/actions/AuthActions";
import { connect, useDispatch } from "react-redux";
import { cn } from "../utils/utils";

import {
  LogOutIcon,
  PlusCircleIcon,
  UsersIcon,
  AlignHorizontalDistributeCenterIcon,
  CandlestickChartIcon,
  LayoutDashboardIcon,
  SettingsIcon,
  SheetIcon,
} from "lucide-react";

const Icons = {
  dashboard: <LayoutDashboardIcon className="mr-2 h-4 w-4" />,
  live: <AlignHorizontalDistributeCenterIcon className="mr-2 h-4 w-4" />,
  history: <SheetIcon className="mr-2 h-4 w-4" />,
  add: <PlusCircleIcon className="mr-2 h-4 w-4" />,
  settings: <SettingsIcon className="mr-2 h-4 w-4" />,
  orders: <SheetIcon className="mr-2 h-4 w-4" />,
  trade: <CandlestickChartIcon className="mr-2 h-4 w-4" />,
  users: <UsersIcon className="mr-2 h-4 w-4" />,
};

const sideBarNavItems = [
  {
    title: "Dashboard",
    href: "/admin",
    path: "/admin",
    icon: "dashboard",
  },
  {
    title: "Live",
    href: "/admin/live",
    path: "live",
    icon: "live",
  },
  {
    title: "History",
    href: "/admin/history",
    path: "history",
    icon: "history",
  },
  {
    title: "Add Strategy",
    href: "/admin/add-strategy",
    path: "add-strategy",
    icon: "add",
  },
  {
    title: "Users",
    href: "/admin/users",
    path: "users",
    icon: "users",
  },
  {
    title: "Order Placement",
    href: "/admin/order-placement",
    path: "order-placement",
    icon: "trade",
  },
];

function DashboardNav(props) {
  const dispatch = useDispatch();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  const path = window.location.pathname;

  const image =
    "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg";
  const email = "test77@gmail.com";

  const userName = "admin";

  if (!sideBarNavItems?.length) {
    return null;
  }

  return (
    <nav className="pt-6  bg-black grid items-start gap-3 ">
      {sideBarNavItems.map((item, index) => {
        const Icon = Icons[item.icon];

        return (
          item.href && (
            <div key={index}>
              <Link key={index} to={item.disabled ? "/admin" : item.href}>
                <div
                  className={cn(
                    "group flex items-center  px-3 py-3 text-sm border-l-4 text-gray-100 font-medium  hover:bg-[#07F2C7]/20 hover:text-gray-300",
                    path === item.href
                      ? "bg-[#07F2C7]/20 border-l-4 border-[#07F2C7]"
                      : "border-l-4 border-transparent bg-transparent",
                    item.disabled && "cursor-not-allowed opacity-80"
                  )}
                >
                  {Icon}
                  <span>{item.title}</span>
                </div>
              </Link>
            </div>
          )
        );
      })}

      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:justify-between md:px-2 md:pr-4 absolute bottom-4 md:w-full">
        <div className="flex flex-col space-y-1 items-center   md:space-y-0 md:flex-row md:space-x-2">
          <img
            src={
              image
                ? image
                : "https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg"
            }
            alt="user"
            className="h-8 w-8 rounded-md cursor-pointer  hover:opacity-50 hover:scale-[0.98]  transition duration-200 ease-in-out"
          />
          <div className="flex">
            <p className="text-xs md:text-sm  text-white font-bold font-inter">
              {userName || userName?.length > 0
                ? userName
                : email?.split("@")[0]}
            </p>
          </div>
        </div>
        <button
          onClick={onLogout}
          className="border-[1px]  border-gray-600  p-2 rounded-md  "
        >
          <LogOutIcon className="h-4 w-4 text-white" />
        </button>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(DashboardNav));
