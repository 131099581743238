import React, { useEffect, useRef, useState } from "react";
import ForexTradeHistoryDataTable from "../../Dashboard/History/ForexTradeHistory";
import ForexOrdersHistoryDataTable from "../../Dashboard/History/ForexOrderHistory";
import JsonToCSV from "../../components/JsonToCSV";
import { tradeHistoryHeader, orderHistoryHeader } from "../../utils/headers";
import {
  CalendarClockIcon,
  ChevronDownIcon,
  FilterIcon,
  XIcon,
  RefreshCcwIcon,
} from "lucide-react";
import Modal from "../../components/modal";
import FilterTradeHistoryMobileModal from "../../Dashboard/History/FilterTradeHistoryMobileModal";
import FilterOrderHistoryMobileModal from "../../Dashboard/History/FilterOrderHistoryMobileModal";
import FilterOpenOrdersMobileModal from "../../Dashboard/Live/FilterOpenOrdersMobileModal";
import FilterLivePositionsMobileModal from "../../Dashboard/Live/FilterLivePositionsMobileModal";
import LivePositionsTable from "../../Dashboard/Live/LivePositionsTable";
import OpenOrdersTable from "../../Dashboard/Live/OpenOrdersTable";

export default function ShowTradeHistoryData({
  userTradeHistory,
  userOrdersHistory,
  filteredUserTradeHistory,
  filteredUserOrdersHistory,
  handleFilterByDays,
  sortOrder,
  setSortOrder,
  setFilteredUserTradeHistory,
  setFilteredUserOrderHistory,
  handleResetFilterData,
  isHistoryDataLoading,
  isPositionsDataLoading,
  userLivePositionData,
  userOpenOrdersData,
  filteredUserLivePositionData,
  filteredUserOpenOrdersData,
  setFilteredUserLivePositionData,
  setFilteredUserOpenOrdersData,
}) {
  const [currentTab, setCurrentTab] = useState("positions");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showCustomDateModal, setShowCustomDateModal] = useState(false);
  const [filterByDaysHistory, setFilterByDaysHistory] = useState(false);
  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);

  const filterByDaysHistoryRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterByDaysHistoryRef.current &&
        !filterByDaysHistoryRef.current.contains(event.target) &&
        filterByDaysHistory
      ) {
        closeFilterByDaysModal();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [filterByDaysHistory]);

  const handleShowCustomDateModal = () => {
    setShowCustomDateModal(true);
  };
  const handleCloseCustomDateModal = () => {
    setShowCustomDateModal(false);
  };

  const handleShowMobileFilterModal = () => {
    setShowMobileFilterModal(true);
  };
  const handleCloseMobileFilterModal = () => {
    setShowMobileFilterModal(false);
  };

  const handleApplyFilter = () => {
    console.log("filter Applied");
    handleFilterByDays(currentTab, -1, startDate, endDate);
    handleCloseCustomDateModal();
    setStartDate("");
    setEndDate("");
  };

  const showFilterByDaysModal = () => {
    setFilterByDaysHistory(true);
  };

  const closeFilterByDaysModal = () => {
    setFilterByDaysHistory(false);
  };

  const handleChangeTabs = (tabName) => {
    setCurrentTab(tabName);
  };

  const handleRefreshData = () => {
    handleResetFilterData(currentTab);
  };

  return (
    <div className="z-0 relative w-full h-full  overflow-hidden  bg-black">
      <div className="flex justify-between  bg-black border-b-[1px] border-gray-800 h-[60px] md:h-[80px] lg:h-[80px] px-4 w-full">
        <div className="flex space-x-10">
          <div
            onClick={() => handleChangeTabs("positions")}
            className={` ${
              currentTab === "positions"
                ? "text-[#07F2C7]  border-[#07F2C7]"
                : "border-transparent"
            } flex items-center border-b-[4px] text-sm md:text-[20px] lg:text-[16px] font-semibold text-gray-400  cursor-pointer transition  duration-300 ease-in `}
          >
            Positions
          </div>
          <div
            onClick={() => handleChangeTabs("open-orders")}
            className={` ${
              currentTab === "open-orders"
                ? "text-[#07F2C7]  border-[#07F2C7]"
                : "border-transparent"
            } flex items-center border-b-[4px] text-sm md:text-[20px] lg:text-[16px] font-semibold text-gray-400  cursor-pointer transition  duration-300 ease-in `}
          >
            Open Orders
          </div>
          <div
            onClick={() => handleChangeTabs("trades-history")}
            className={` ${
              currentTab === "trades-history"
                ? "text-[#07F2C7]  border-[#07F2C7]"
                : "border-transparent"
            } flex items-center border-b-[4px] text-sm md:text-[20px] lg:text-[16px] font-semibold text-gray-400  cursor-pointer transition  duration-300 ease-in `}
          >
            Trades History
          </div>
          <div
            onClick={() => handleChangeTabs("orders-history")}
            className={` ${
              currentTab === "orders-history"
                ? "text-[#07F2C7]  border-[#07F2C7]"
                : "border-transparent"
            } flex items-center border-b-[4px] text-sm md:text-[20px] lg:text-[16px] font-semibold text-gray-400  cursor-pointer transition  duration-300 ease-in `}
          >
            Orders History
          </div>
        </div>

        <div className="relative hidden lg:flex items-center space-x-6 ">
          {currentTab === "orders-history" && (
            <JsonToCSV
              data={filteredUserTradeHistory}
              headers={orderHistoryHeader}
              filenamePrefix="Order_History"
              buttonTitle="Export Order History"
            />
          )}
          {currentTab === "trades-history" && (
            <JsonToCSV
              data={filteredUserTradeHistory}
              headers={tradeHistoryHeader}
              filenamePrefix="Trade_History"
              buttonTitle="Export Trade History"
            />
          )}

          <button
            ref={filterByDaysHistoryRef}
            onClick={showFilterByDaysModal}
            className="flex items-center space-x-2 bg-transparent border-[2px] border-[#07F2C7] px-6 py-2 rounded-md"
          >
            <CalendarClockIcon className="h-5 w-5 text-[#07F2C7]" />
            <span className="text-[#07F2C7] text-base">Last 30 days</span>
            <ChevronDownIcon className="h-5 w-5 text-[#07F2C7]" />
          </button>
        </div>
      </div>

      {/* For Mobile version (Calendar Filter + Filter & Refresh Icon) */}
      <div className="z-50 relative flex border-b-[1px] border-gray-800 md:border-none py-2 md:py-5 justify-between px-4  lg:hidden lg:items-center lg:space-x-6 ">
        <button
          ref={filterByDaysHistoryRef}
          onClick={showFilterByDaysModal}
          className="flex items-center space-x-2 bg-transparent border-[2px] border-[#07F2C7] py-2 px-2 md:px-6 md:py-2 rounded-md"
        >
          <CalendarClockIcon className="h-5 w-5 text-[#07F2C7]" />
          <span className="text-[#07F2C7] text-xs md:text-base">
            Last 30 days
          </span>
          <ChevronDownIcon className="h-5 w-5 text-[#07F2C7]" />
        </button>

        <div className="flex items-center space-x-2 lg:hidden">
          {currentTab === "orders-history" && (
            <JsonToCSV
              data={filteredUserOrdersHistory}
              headers={orderHistoryHeader}
              filenamePrefix="Order_History"
              buttonTitle="Export Order History"
            />
          )}
          {currentTab === "trades-history" && (
            <JsonToCSV
              data={filteredUserTradeHistory}
              headers={tradeHistoryHeader}
              filenamePrefix="Trade_History"
              buttonTitle="Export Trade History"
            />
          )}
          <span
            onClick={handleRefreshData}
            className="flex items-center cursor-pointer bg-[#07F2C7]/20 rounded-md  h-8 w-8"
          >
            <RefreshCcwIcon className="h-4 w-4 text-[#07F2C7] ml-2" />
          </span>
          <span
            onClick={handleShowMobileFilterModal}
            className="flex items-center cursor-pointer bg-[#07F2C7]/20 rounded-md  h-8 w-8"
          >
            <FilterIcon className="h-4 w-4 text-[#07F2C7] ml-2" />
          </span>
        </div>
      </div>

      {showCustomDateModal && (
        <Modal>
          <div className="z-0 relative flex items-center justify-center bg-[#17181B] w-[350px] h-[400px] md:w-[620px] md:h-[250px] rounded-md  px-8">
            <button
              onClick={handleCloseCustomDateModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-2 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>
            <div className="flex flex-col justify-center  space-y-8 w-full h-full">
              <div className="grid grid-cols-1 gap-y-3 md:grid-cols-2 md:gap-x-6">
                <div className="flex flex-col space-y-1 mb-4 w-full">
                  <label
                    className="flex items-center label  text-white"
                    htmlFor="startDate"
                  >
                    From
                  </label>
                  <input
                    required
                    name="startDate"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  />
                </div>

                <div className="flex flex-col space-y-1 mb-4 w-full">
                  <label
                    className="flex items-center label  text-white"
                    htmlFor="endDate"
                  >
                    To
                  </label>
                  <input
                    required
                    name="endDate"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="py-3 px-3 text-sm placeholder:text-gray-600   bg-transparent text-white/80 focus:outline-none rounded-[6px] border-[1px] border-white/20"
                  />
                </div>
              </div>
              <div className="flex justify-between space-x-4">
                <button
                  disabled={!startDate || !endDate}
                  onClick={() => handleApplyFilter()}
                  className={` flex w-full disabled:cursor-not-allowed cursor-pointer justify-center rounded-md bg-[#07F2C7] px-3 py-3 text-base font-medium leading-6 text-black shadow-sm hover:bg-[#07F2C7]/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                >
                  Filter
                </button>

                <div
                  onClick={handleCloseCustomDateModal}
                  className={` flex w-full  cursor-pointer justify-center rounded-md bg-transparent px-3 py-3 text-base font-medium leading-6 text-gray-100 shadow-sm border-[1px] border-white/20 hover:bg-black/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500 `}
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {filterByDaysHistory && (
        <div
          ref={filterByDaysHistoryRef}
          className="z-10 absolute  left-4 md:left-4 md:top-36 lg:hidden bg-[#17181B] w-[160px] md:w-[200px] lg:w-[210px]  rounded-sm animate-reveal transition duration-500 ease-in"
        >
          <div className="flex  flex-col  h-full divide-y divide-gray-700  ">
            <div
              onClick={() => handleFilterByDays(currentTab, 0)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              All
            </div>

            <div
              onClick={() => handleFilterByDays(currentTab, 1)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Yesterday
            </div>
            <div
              onClick={() => handleFilterByDays(currentTab, 7)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Last 7 Days
            </div>

            <div
              onClick={() => handleFilterByDays(currentTab, 15)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Last 15 days
            </div>

            <div
              onClick={() => handleFilterByDays(currentTab, 30)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Last 30 Days
            </div>

            <div
              onClick={handleShowCustomDateModal}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Custom
            </div>
          </div>
        </div>
      )}

      {filterByDaysHistory && (
        <div
          ref={filterByDaysHistoryRef}
          className="z-10 absolute hidden lg:inline lg:right-4 lg:top-20 bg-[#17181B] w-[160px] md:w-[200px] lg:w-[210px]  rounded-sm animate-reveal transition duration-500 ease-in"
        >
          <div className="flex  flex-col  h-full divide-y divide-gray-700  ">
            <div
              onClick={() => handleFilterByDays(currentTab, 0)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              All
            </div>

            <div
              onClick={() => handleFilterByDays(currentTab, 1)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Yesterday
            </div>
            <div
              onClick={() => handleFilterByDays(currentTab, 7)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Last 7 Days
            </div>

            <div
              onClick={() => handleFilterByDays(currentTab, 15)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Last 15 days
            </div>

            <div
              onClick={() => handleFilterByDays(currentTab, 30)}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Last 30 Days
            </div>

            <div
              onClick={handleShowCustomDateModal}
              className="text-white text-base cursor-pointer hover:bg-[#07F2C7]/20 pl-4 py-1.5 md:pl-6 md:py-3 "
            >
              Custom
            </div>
          </div>
        </div>
      )}

      {showMobileFilterModal && (
        <Modal>
          <div className="z-0 relative flex items-center justify-center bg-[#17181B]  w-full h-[440px] md:h-[620px]   rounded-md px-3 md:px-16 ">
            <button
              onClick={handleCloseMobileFilterModal}
              className="absolute top-4 right-2 p-1 md:top-6 md:right-2 rounded-md md:p-2"
            >
              <XIcon className="w-4 h-4 md:w-6 md:h-6 text-white" />
            </button>

            {currentTab === "open-orders" && (
              <FilterOpenOrdersMobileModal
                currentTab={currentTab}
                userOriginalOpenOrders={userOpenOrdersData}
                setFilteredUserOpenOrdersData={setFilteredUserOpenOrdersData}
                handleCloseMobileFilterModal={handleCloseMobileFilterModal}
              />
            )}

            {currentTab === "positions" && (
              <FilterLivePositionsMobileModal
                currentTab={currentTab}
                userOriginalLivePositions={userLivePositionData}
                setFilteredUserLivePositionData={
                  setFilteredUserLivePositionData
                }
                handleCloseMobileFilterModal={handleCloseMobileFilterModal}
              />
            )}
            {currentTab === "trades-history" && (
              <FilterTradeHistoryMobileModal
                currentTab={currentTab}
                userOriginalTradeHistory={userTradeHistory}
                setFilteredUserTradeHistory={setFilteredUserTradeHistory}
                handleCloseMobileFilterModal={handleCloseMobileFilterModal}
              />
            )}

            {currentTab === "orders-history" && (
              <FilterOrderHistoryMobileModal
                currentTab={currentTab}
                userOriginalOrdersHistory={userOrdersHistory}
                setFilteredUserOrderHistory={setFilteredUserOrderHistory}
                handleCloseMobileFilterModal={handleCloseMobileFilterModal}
              />
            )}
          </div>
        </Modal>
      )}
      {currentTab === "positions" && (
        <LivePositionsTable
          userOriginalLivePositions={userLivePositionData}
          filteredUserLivePositionData={filteredUserLivePositionData}
          currentTab={currentTab}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setFilteredUserLivePositionData={setFilteredUserLivePositionData}
          handleResetFilterData={handleResetFilterData}
          isPositionsDataLoading={isPositionsDataLoading}
        />
      )}

      {currentTab === "open-orders" && (
        <OpenOrdersTable
          userOriginalOpenOrders={userOpenOrdersData}
          filteredUserOpenOrdersData={filteredUserOpenOrdersData}
          currentTab={currentTab}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setFilteredUserOpenOrdersData={setFilteredUserOpenOrdersData}
          handleResetFilterData={handleResetFilterData}
          isPositionsDataLoading={isPositionsDataLoading}
        />
      )}
      {currentTab === "trades-history" && (
        <ForexTradeHistoryDataTable
          userOriginalTradeHistory={userTradeHistory}
          filteredUserTradeHistory={filteredUserTradeHistory}
          currentTab={currentTab}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setFilteredUserTradeHistory={setFilteredUserTradeHistory}
          handleResetFilterData={handleResetFilterData}
          isHistoryDataLoading={isHistoryDataLoading}
        />
      )}
      {currentTab === "orders-history" && (
        <ForexOrdersHistoryDataTable
          userOriginalOrdersHistory={userOrdersHistory}
          filteredUserOrdersHistory={filteredUserOrdersHistory}
          currentTab={currentTab}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          setFilteredUserOrderHistory={setFilteredUserOrderHistory}
          handleResetFilterData={handleResetFilterData}
          isHistoryDataLoading={isHistoryDataLoading}
        />
      )}
    </div>
  );
}
