export const handleSortTradeHistoryData = (
  userTradeHistory,
  setFilteredUserTradeHistory,
  setSortOrder,
  currentTab,
  order,
  field
) => {
  if (currentTab === "trades-history") {
    if (field === "time") {
      const sortedTradeHistoryData = [...userTradeHistory].sort((a, b) => {
        const timeA = new Date(a.time).getTime();
        const timeB = new Date(b.time).getTime();

        return order === "asc"
          ? timeA - timeB
          : order === "desc"
          ? timeB - timeA
          : timeA - timeB;
      });

      setFilteredUserTradeHistory(sortedTradeHistoryData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "account_name" ||
      field === "strategy_name" ||
      field === "symbol" ||
      field === "type"
    ) {
      const sortedTradeHistoryData = [...userTradeHistory].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });

      setFilteredUserTradeHistory(sortedTradeHistoryData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "volume" ||
      field === "price" ||
      field === "profit" ||
      field === "deal_id" ||
      field === "order_id"
    ) {
      const sortedTradeHistoryData = [...userTradeHistory].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA - valueB
          : order === "desc"
          ? valueB - valueA
          : valueA - valueB;
      });

      setFilteredUserTradeHistory(sortedTradeHistoryData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    }
  }
};

export const handleSortOrderHistoryData = (
  userOrdersHistory,
  setFilteredUserOrderHistory,
  setSortOrder,
  currentTab,
  order,
  field
) => {
  if (currentTab === "orders-history") {
    if (field === "time") {
      const sortedOrdersHistoryData = [...userOrdersHistory].sort((a, b) => {
        const timeA = new Date(a.time).getTime();
        const timeB = new Date(b.time).getTime();

        return order === "asc"
          ? timeA - timeB
          : order === "desc"
          ? timeB - timeA
          : timeA - timeB;
      });

      setFilteredUserOrderHistory(sortedOrdersHistoryData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "account_name" ||
      field === "strategy_name" ||
      field === "symbol" ||
      field === "type" ||
      field === "state"
      
    ) {
      const sortedOrdersHistoryData = [...userOrdersHistory].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });

      setFilteredUserOrderHistory(sortedOrdersHistoryData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "volume" ||
      field === "open_price" ||
      field === "order_id"
    ) {
      const sortedOrdersHistoryData = [...userOrdersHistory].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA - valueB
          : order === "desc"
          ? valueB - valueA
          : valueA - valueB;
      });

      setFilteredUserOrderHistory(sortedOrdersHistoryData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    }
  }
};

export const handleSortLivePositionsData = (
  userLivePositions,
  setFilteredUserLivePositionData,
  setSortOrder,
  currentTab,
  order,
  field
) => {
  if (currentTab === "positions") {
    if (field === "time") {
      const sortedLivePositionsData = [...userLivePositions].sort((a, b) => {
        const timeA = new Date(a.time).getTime();
        const timeB = new Date(b.time).getTime();

        return order === "asc"
          ? timeA - timeB
          : order === "desc"
          ? timeB - timeA
          : timeA - timeB;
      });

      setFilteredUserLivePositionData(sortedLivePositionsData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "account_name" ||
      field === "strategy_name" ||
      field === "symbol" ||
      field === "position_type" ||
      field === "reason"
    ) {
      const sortedLivePositionsData = [...userLivePositions].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });

      setFilteredUserLivePositionData(sortedLivePositionsData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "volume" ||
      field === "open_price" ||
      field === "current_price" ||
      field === "unrealized_profit" ||
      field === "profit"
    ) {
      const sortedLivePositionsData = [...userLivePositions].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA - valueB
          : order === "desc"
          ? valueB - valueA
          : valueA - valueB;
      });

      setFilteredUserLivePositionData(sortedLivePositionsData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    }
  }
};

export const handleSortOpenOrdersData = (
  userOpenOrders,
  setFilteredUserOpenOrdersData,
  setSortOrder,
  currentTab,
  order,
  field
) => {
  if (currentTab === "open-orders") {
    if (field === "time") {
      const sortedOpenOrdersData = [...userOpenOrders].sort((a, b) => {
        const timeA = new Date(a.time).getTime();
        const timeB = new Date(b.time).getTime();

        return order === "asc"
          ? timeA - timeB
          : order === "desc"
          ? timeB - timeA
          : timeA - timeB;
      });

      setFilteredUserOpenOrdersData(sortedOpenOrdersData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "account_name" ||
      field === "strategy_name" ||
      field === "symbol" ||
      field === "type" ||
      field === "state" ||
      field === "reason" 
    ) {
      const sortedOpenOrdersData = [...userOpenOrders].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      });

      setFilteredUserOpenOrdersData(sortedOpenOrdersData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    } else if (
      field === "volume" ||
      field === "current_volume" ||
      field === "open_price" ||
      field === "current_price" 
    ) {
      const sortedOpenOrdersData = [...userOpenOrders].sort((a, b) => {
        const valueA = a[field];
        const valueB = b[field];

        return order === "asc"
          ? valueA - valueB
          : order === "desc"
          ? valueB - valueA
          : valueA - valueB;
      });

      setFilteredUserOpenOrdersData(sortedOpenOrdersData);
      if (order === "asc") {
        setSortOrder("desc");
      } else {
        setSortOrder("asc");
      }
    }
  }
};
