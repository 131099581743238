import { Link } from "react-router-dom";
import React from "react";
import ForexLogo from "../assests/ForexLogo.svg";

export default function Logo() {
  return (
    <Link to="/admin" className="flex items-center   h-full md:px-0  space-x-2 ">
      <img
        src={ForexLogo}
        className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] lg:w-[35px] lg:h-[35px] "
        alt="Forex Logo"
      />
      {/* <span className=" font-medium text-md hidden text-lg md:flex text-white">
        Forex Investment
      </span> */}
      <div className="  flex flex-col h-[40px] justify-end ">
        <span className="font-medium text-sm md:text-[20px] lg:text-sm    text-white  leading-4 ">
          Forex
        </span>
        <span className="font-medium text-sm md:text-[20px] lg:text-sm    text-white leading-4 ">
          Investment
        </span>
      </div>
    </Link>
  );
}
