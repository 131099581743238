import React, { useState } from "react";
import { XIcon, CheckIcon } from "lucide-react";
import { filterTradeHistory } from "../../utils/filterFunctions";

export default function FilterTradeHistoryModal({
  showFilter,
  handleCloseFilter,
  currentTab,
  filteredUserTradeHistory,
  userOriginalTradeHistory,
  setFilteredUserTradeHistory,
}) {
  const [filters, setFilters] = useState({
    account_name: "",
    strategy_name:"",
    symbol: "",
    type: "",
    entry_type: "",
    volume: "",
    price: "",
    profit: "",
    deal_id: "",
    order_id: "",
    time: "",
  });

  const handleInputChange = (e, filterKey) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterKey]: e.target.value,
    }));
  };

  const handleApplyFilters = () => {
    const filtersToApply = {};

    for (const [key, value] of Object.entries(filters)) {
      if (value !== null && value !== "") {
        filtersToApply[key] = value;
      }
    }

    filterTradeHistory(
      filtersToApply,
      currentTab,
      userOriginalTradeHistory,
      setFilteredUserTradeHistory
    );
   
  };

  return (
    <tr
      className={` h-[70px]  bg-[#07F2C7]/20 border-gray-800 
         ${
           showFilter
             ? "animate-slideDown transition duration-500 ease-in"
             : "animate-slideUp transition duration-500 ease-in"
         } `}
    >
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Account Name"
          value={filters.account_name}
          className=" py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "account_name")}
        />
      </th>

      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Strategy Name"
          value={filters.strategy_name}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "strategy_name")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Symbol"
          value={filters.symbol}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "symbol")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="text"
          placeholder="Order Type"
          value={filters.type}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "type")}
        />
      </th>
      {/* <th className=" px-1  ">
        <input
          type="text"
          placeholder="Entry Type"
          value={filters.entry_type}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "entry_type")}
        />
      </th> */}
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Volume"
          value={filters.volume}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "volume")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Price"
          value={filters.price}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "price")}
        />
      </th>
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Profit"
          value={filters.profit}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "profit")}
        />
      </th>
      {/* <th className=" px-1  ">
        <input
          type="number"
          placeholder="Deal Id"
          value={filters.deal_id}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "deal_id")}
        />
      </th> */}
      <th className=" px-1  ">
        <input
          type="number"
          placeholder="Order Id"
          value={filters.order_id}
          className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40 text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
          onChange={(e) => handleInputChange(e, "order_id")}
        />
      </th>
      <th className=" px-1  ">
        <span className="flex items-center space-x-3 ">
          <input
            type="date"
            placeholder="Time"
            value={filters.time}
            className="py-2 px-1 w-full font-normal text-white/80 placeholder-white/40  text-sm focus:outline none border-[1px] border-white/10 bg-[#fff]/10 rounded-sm"
            onChange={(e) => handleInputChange(e, "time")}
          />
          <span
            onClick={handleApplyFilters}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
            <CheckIcon className="h-5 w-5 text-[#07F2C7] " />
          </span>
          <span
            onClick={handleCloseFilter}
            className="flex items-center justify-center cursor-pointer  rounded-md  h-8 w-8"
          >
            <XIcon className="h-5 w-5 text-rose-500 " />
          </span>
        </span>
      </th>
    </tr>
  );
}
